<!--  -->
<template>
  <div>
     <el-drawer
      title="Save report"
      :visible.sync="drawershowS"
      direction="rtl"
      size="45%"
      ref="elDrawer"
      :before-close="close"
    >
      <div class="drawerconter">
        <div class="drawbodyone" v-show="drawbodyshow">
          <p>
            This report will be saved to your reports. You can also add it to a dashboard.
          </p>
          <h3>Report name</h3>
          <input class="drapdiv"  v-model="bartitleS"/>
          <h3>Add to existing dashboard</h3>
          <el-radio v-model="radio" label="1">Don't add to dashboard</el-radio>
          <br />
          <el-radio v-model="radio" label="2"
            >Add to existing dashboard</el-radio
          >
          <br />
          <el-select
            v-model="value"
            clearable
            placeholder="Choose a dashboard"
            v-show="radio == '2'"
          >
            <el-input
              class="search-text"
              v-model="searchValue"
              placeholder="Search"
              suffix-icon="el-icon-search"
              style="width:98%;margin-left:1%;margin-top:5px;"
            ></el-input>
            <el-option
              v-for="item in options"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
          <el-radio v-model="radio" label="3">Add to new dashboard</el-radio>
        </div>
        <div class="drawbodysecond" v-show="!drawbodyshow">
          <div v-show="radio == '3'">
            <h1>Dashboard name</h1>
            <input type="text" />
          </div>
          <p>Who can access this report?</p>
          <el-radio-group v-model="radiore" @change="change">
            <el-radio label="1">Private to owner (me)</el-radio> <br />
            <el-radio label="2">Everyone</el-radio> <br /> </el-radio-group
          ><br />
          <el-radio-group
            v-model="radioreport"
            class="elradiogroup"
            :disabled="radiore == '1'"
          >
            <el-radio label="3">View and edit</el-radio><br />
            <el-radio label="4">View only</el-radio>
          </el-radio-group>
          <span>Your account has 0 custom reports and 2 dashboards.</span>
        </div>
      </div>
      <div class="drawerfoot">
        <div class="buttongroup1" v-show="drawbodyshow">
          <button @click="drawbodyshow = false" v-show="radio !== '2'">
            NEXT
          </button>
          <button class="buttwo" @click="save" v-show="radio == '2'">
            save
          </button>
        </div>
        <div class="buttongroup2" v-show="!drawbodyshow">
          <button
            @click="drawbodyshow = true"
            v-show="radio == '3'"
            class="buttone">
            <i class="el-icon-arrow-left"></i> Back
          </button>
          <button class="buttwo" @click="save">save</button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props : {
    drawershow:{
        type: Boolean,
        default() {}
    },
    bartitle:{
        type: String,
        default() {}   
    },
    },  
  data () { 
    return {
        drawershowS: this.drawershow,
        bartitleS: this.bartitle,
        radio: '1',
        drawbodyshow: true,
        radiore: "1",
        radioreport: '1',
        options: [{

            label: '黄金糕'
        }, {

            label: '双皮奶'
        }, {

            label: '蚵仔煎'
        }, {

            label: '龙须面'
        }, {

            label: '北京烤鸭'
        }],
        value: '',
        searchValue:"",//搜索内容
    }
  },
  methods: {
    close() {
      this.formshow = false;
      this.$emit('func', this.formshow)
    },
    change(item) {
      if (item == '1') {
        this.radioreport = '1';
      } else {
        this.radioreport = '3';
      }
    },
    save() {
      this.drawbodyshow = true;
      this.$refs.elDrawer.closeDrawer();
    },
    //弹框搜索
    // searchList(){
    // }
  }
}
</script>

<style lang='scss' scoped >
::v-deep .el-drawer {
  background: #ffffff;
  border: 1px solid #dedcda;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border-radius: 2px;
  .el-drawer__header {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #3e3c3c;
    letter-spacing: 0;
    font-weight: bolder;
  }
  .drawerconter {
    border-top: 1px solid #dedcda;
    border-bottom: 1px solid #dedcda;
    height: 87%;
    .drawbodyone {
      margin-left: 2.7%;
      width: 94.4%;
      .el-select {
        width: 100%;
        height: 50px;
        margin-top: 15px;
        // ::v-deep .el-input.search-text{
        //   width: 95%;
        //   height: 5.2%;
        //   margin-left: 2.5%;
        //   outline: none;
        // }
        .el-input__inner {
          width: 100%;
          height: 50px;
        }
      }
      p {
        width: 86%;
        font-family: MicrosoftYaHei;
        letter-spacing: 0;
        margin-top: 30px;
        font-size: 14px;
        color: #080707;
      }
      h3{
        font-size: 14px;
        color: #080707;
        font-weight: bolder;
        margin-top: 30px;
      }
      .drapdiv {
        width: 100%;
        height: 50px;
        margin-top: 12px;
        background: #ffffff;
        border: 1px solid #dedcda;
        border-radius: 3px;
        border-radius: 3px;
      }
      .el-radio {
        margin-top: 13px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #3e3c3c;
        letter-spacing: 0;
      }
    }
    .drawbodysecond {
      margin-left: 2.7%;
      margin-top: 9.5%;
      width: 94.4%;
      div {
        width: 100%;
        h1 {
          font-family: MicrosoftYaHei-Bold;
          font-size: 14px;
          color: #3e3c3c;
          letter-spacing: 0;
          font-weight: bolder;
        }
        input {
          width: 100%;
          outline: none;
          height: 50px;
        }
      }
      p {
        font-size: 14px;
        color: #3e3c3c;
        letter-spacing: 0;
        line-height: 60px;
      }
      .el-radio {
        font-size: 14px;
        color: #3e3c3c;
        letter-spacing: 0;
        line-height: 40px;
      }
      .elradiogroup {
        margin-left: 40px;
      }
    }
  }
}
.drawerfoot {
  width: 94.4%;
  margin-left: 2.7%;
  .buttongroup1 {
    width: 100%;
    height: 100%;
    button {
      border: none;
      float: right;
      background: #006dcc;
      border-radius: 3px;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      outline: none;
      width: 81px;
      height: 40px;
      margin-top: 24px;
    }
  }
  .buttongroup2 {
    width: 100%;
    height: 100%;
    outline: none;
    .buttone {
      float: left;
      background: #ffffff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      border-radius: 3px;
      width: 87px;
      height: 40px;
      margin-top: 24px;
      font-size: 14px;
      color: #006dcc;
      letter-spacing: 0;
      text-align: center;
      outline: none;
    }
    .buttwo {
      float: right;
      background: #006dcc;
      border-radius: 3px;
      border: none;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      outline: none;
      width: 81px;
      height: 40px;
      margin-top: 24px;
    }
  }
}
</style>
