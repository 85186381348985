<!--  -->
<template>
  <div class="topContent">
    <div class="sidebar">
      <ul>
        <li :class="activeName === 0 ? 'curli' : ''" @click="inboxclick(0)">
          <div
            class="roundBox"
            style="background-color: rgb(0, 189, 165)"
          ></div>
          {{ $t("c627") }} <span>{{ publishedNum }}</span>
        </li>
        <li :class="activeName === 1 ? 'curli' : ''" @click="inboxclick(1)">
          <div
            class="roundBox"
            style="background-color: rgb(245, 194, 107)"
          ></div>
          {{ $t("c628") }} <span>{{ scheduledNum }}</span>
        </li>
        <li :class="activeName === 3 ? 'curli' : ''" @click="inboxclick(3)">
          <div
            class="roundBox"
            style="background-color: rgb(242, 84, 91)"
          ></div>
          {{ $t("c629") }} <span>{{ failNum }}</span>
        </li>
        <li :class="activeName === 2 ? 'curli' : ''" @click="inboxclick(2)">
          <div
            class="roundBox"
            style="background-color: rgb(203, 214, 226)"
          ></div>
          {{ $t("label.knowledgebase.draft") }} <span>{{ draftsNum }}</span>
        </li>
      </ul>
    </div>
    <div class="content" v-loading="listLoading">
      <socialList
        ref="socialList"
        :powerType="powerType"
        :dataList="dataList"
        :tableAttr="tableAttr"
        :checked="true"
        :selectvalue="selectvalue"
        :buttonlist="buttonlist"
        @postAssgin="postAssgin"
        @postClone="postClone"
        @detailSoical="detailSoical"
        @details="detailSoical"
        @delete="deletePost"
        @makeDraft="makeDraft"
        @ApproveDraft="ApproveDraft"
        @makeDraftAll="makeDraftAll"
        @ApproveDraftAll="ApproveDraftAll"
        @deleteSelect="deleteSelect"
        @edit="edit"
        @clone="clone"
        @reschedule="reschedule"
        @checkMethod="checkMethod"
        @sortMethod="sortMethod"
        :sortDir="sortDir"
        :type="activeName"
      ></socialList>

      <div class="block">
        <!-- 分页数据 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[5, 10, 15, 20]"
          layout=" total,prev, pager, next,sizes,jumper"
          :page-size="pagesize"
          :total="currentPagetotle"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 选择市场活动弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('c701')"
      top="10%"
      width="40%"
      :close-on-click-modal="true"
      :before-close="handleClose"
    >
      <div class="campaign-mes">
        <!-- Campaign -->
        {{ $t("label.marketingactivity") }}
      </div>
      <div class="campaign-select">
        <el-select style="width: 100%" v-model="campaignSelect" filterable>
          <el-option
            style="max-width: 600px"
            v-for="(item, no) in campaignList"
            :key="no"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- drafts -->
          <!-- Cancel -->
          {{ $t("label.emailtocloudcc.button.back") }}
        </el-button>
        <el-button type="primary" size="mini" @click="changCampaign">
          <!-- changes -->
          <!-- Save -->
          {{ $t("label.chatter.save") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogCloneVisible"
      :title="'Clone'"
      top="15%"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="campaign-mes">Whether the copy?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCloneVisible = false" size="mini">
          <!-- drafts -->
          <!-- Cancel -->
          {{ $t("label.emailtocloudcc.button.back") }}
        </el-button>
        <el-button
          type="primary"
          size="mini"
          @click="dialogCloneVisible = false"
        >
          <!-- changes -->
          <!-- Save -->
          {{ $t("label.chatter.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as marketChannel from "./api";
import socialList from "@/views/social/components/socialList/index.vue";
// import { sendTianRunEmail } from "@/views/controlPanel/api";
export default {
  components: { socialList },
  created() {
    this.getPostInfo();
    this.$nextTick(() => {
      this.currentPagetotle = this.publishedNum;
    });
  },
  props: {
    userSelectId: {
      type: String,
      default: "",
    },
    selectCampaignId: {
      type: String,
      default: "",
    },
    selectUserDataId: {
      type: String,
      default: "",
    },
    enddate: {
      type: String,
      default: "",
    },
    startdate: {
      type: String,
      default: "",
    },
    selectAccountKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      powerType: "Published", // 传入子组件中的权限对象
      listLoading: false,
      ids: "",
      campaignList: [],
      publishedNum: 0,
      scheduledNum: 0,
      draftsNum: 0,
      failNum: 0,
      posttype: "sending",
      dialogCloneVisible: false,
      campaignSelect: "",
      dialogVisible: false,
      currentPage: 1,
      pagesize: 5,
      currentPagetotle: 0,
      activeName: 0,
      buttonlist: [
        { name: this.$i18n.t("label.report.details"), emit: "details" },
        { name: this.$i18n.t("label.clone"), emit: "clone" },
        { name: this.$i18n.t("label.import.index.operation"), emit: "" },
      ],
      selectvalue: [
        { name: this.$i18n.t("c630"), divided: false, emit: "viewLink" },
        {
          name: this.$i18n.t("label.department.user.delete"),
          divided: false,
          emit: "delete",
        },
      ],
      tableAttr: [
        {
          schemefieldName: "textcontent",
          // nameLabel: "POST",
          nameLabel: "c828",
          schemefieldType: "PO",
          colwidth: "",
          click: "detail",
          isfunctional: true,
        },
        {
          schemefieldName: "campaignname",
          // nameLabel: "CAMPAIGN",
          nameLabel: "c829",
          schemefieldType: "",
          colwidth: "130px",
          isfunctional: false,
        },
        {
          schemefieldName: "timingdate",
          // nameLabel: "PUBLISHED TIME",
          nameLabel: "c644",
          schemefieldType: "DE",
          colwidth: "230px",
          isfunctional: false,
        },
        {
          schemefieldName: "username",
          // nameLabel: "CREATE BY",
          nameLabel: "c830",
          schemefieldType: "",
          colwidth: "",
          isfunctional: false,
        },
      ],
      // arr: [],
      dataList: [],
      punlishedlist: [],
      scheduledlist: [],
      draftlist: [],
      sortDir: "desc", //排序
    };
  },
  watch: {
    activeName() {
      this.getdatalist();
    },
  },
  //方法集合
  methods: {
    sortMethod() {
      if (this.sortDir === "desc") {
        this.sortDir = "asc";
      } else {
        this.sortDir = "desc";
      }
      this.getPostInfo();
    },
    getdatalist() {
      // if (this.activeName == 0) {
      //   this.dataList = [...this.punlishedlist];
      // } else if (this.activeName == 1) {
      //   this.dataList = [...this.scheduledlist];
      // } else {
      //   this.dataList = [...this.draftlist];
      // }
      // this.currentPagetotle = this.dataList.length;
      // this.showpage();
    },
    // 实时获取选中的帖子ids字符
    checkMethod(ids) {
      this.ids = ids;
    },
    // 批量更改市场活动
    changCampaign() {
      marketChannel
        .updateBatchPostCampaign({
          orgid: this.$store.state.userInfoObj.orgId,
          postsids: this.ids,
          campaignid: this.campaignSelect,
        })
        .then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("vue_label_notice_modify_success"),
            });
            this.refreshList();
            this.campaignSelect = "";
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      this.dialogVisible = false;
    },
    refreshList() {
      this.getPostInfo();
    },
    // 编辑
    edit(row) {
      this.$emit("posterEdit", row, 0);
    },
    // 克隆
    clone(row) {
      this.$emit("posterEdit", row, 1);
    },
    // 从新发送
    reschedule(row) {
      this.$emit("posterEdit", row, 2);
    },
    // 单个预约发布转为草稿
    makeDraft(obj) {
      this.makeDraftPost(obj.id);
    },
    // 批量预约发布转为草稿
    makeDraftAll() {
      this.makeDraftPost();
    },
    // 预约转换草稿接口调用
    
    makeDraftPost(id) {
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
        postsids: id ? id : this.ids,
        poststype: "draft",
        timingdate: "",
      };
      marketChannel.editPoststype(obj).then((res) => {
        if (res.result) {
          this.$message({
            showClose: true,
            type: "success",
            message: "转化为草稿成功",
          });
          this.refreshList();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    // 单个批准草稿
    ApproveDraft(obj) {
      
      //  TODO: 确定批准草稿时第三个参数的值
      this.$emit("posterEdit", obj, 3);
    },
    // 批量批准草稿
    ApproveDraftAll(list) {
      
      //  TODO: 确定批准草稿时第三个参数的值
      this.$emit("posterEdit", list, 3);
    },
    deleteSelect() {
      marketChannel
        .delBatchPost({
          orgid: this.$store.state.userInfoObj.orgId,
          postsids: this.ids,
        })
        .then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: res.data,
            });
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      this.getPostInfo();
    },
    deletePost(row) {
      marketChannel
        .delPostInfo({
          orgid: this.$store.state.userInfoObj.orgId,
          postsid: row.id,
        })
        .then((res) => {
          if (res.result) {
            this.$message.success(this.$i18n.t("label.tabpage.delsuccessz"));
            this.getPostInfo();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    async getPostInfo() {
      this.listLoading = true;
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
        socialtype: this.selectAccountKey,
        createbyid: this.userSelectId,
        campaignid: this.selectCampaignId,
        socialmediaids: this.selectUserDataId,
        poststype: this.posttype,
        daysstartdate: this.startdate,
        daysenddate: this.enddate,
        pageNum: this.currentPage,
        pageSize: this.pagesize,
      };
      if (this.posttype === "sending") {
        obj.sortField = "senddate";
        obj.sortDir = this.sortDir;
      } else if (this.posttype === "timing") {
        obj.sortField = "timingdate";
        obj.sortDir = this.sortDir;
      } else if (this.posttype === "fail") {
        obj.sortField = "createdate";
        obj.sortDir = this.sortDir;
      } else if (this.posttype === "draft") {
        obj.sortField = "createdate";
        obj.sortDir = this.sortDir;
      }
      await marketChannel.getPostInfo(obj).then((res) => {
        if (res.result) {
          this.listLoading = false;
          if (res.data === null || res.data === []) {
            this.dataList = [];
            this.currentPagetotle = 0;
            this.punlishedlist = [];
            this.scheduledlist = [];
            this.draftlist = [];
          } else {
            this.dataList = res.data.sociallist;
            this.punlishedlist = [];
            this.scheduledlist = [];
            this.draftlist = [];
            res.data.sociallist.forEach((element) => {
              if (element.poststype == "sending") {
                this.punlishedlist.push(element);
              } else if (element.poststype == "timing") {
                this.scheduledlist.push(element);
              } else {
                this.draftlist.push(element);
              }
            });
            this.getdatalist();
          }
          this.getDataTotals();
          // this.showpage();
          this.$refs.socialList.changeTableAttr();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    getDataTotals() {
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
        socialtype: this.selectAccountKey,
        campaignid: this.selectCampaignId,
        socialmediaids: this.selectUserDataId,
        poststype: this.posttype,
        daysstartdate: this.startdate,
        daysenddate: this.enddate,
        createbyid: this.userSelectId,
      };
      marketChannel.getPostsTypeQuantity(obj).then((res) => {
        if (res.result) {
          this.publishedNum = res.data.sendingtotal;
          this.scheduledNum = res.data.timingtotal;
          this.draftsNum = res.data.drafttotal;
          this.failNum = res.data.failtotal;
          if (this.posttype === "sending") {
            this.currentPagetotle = this.publishedNum;
          } else if (this.posttype === "timing") {
            this.currentPagetotle = this.scheduledNum;
          } else if (this.posttype === "draft") {
            this.currentPagetotle = this.draftsNum;
          } else if (this.posttype === "fail") {
            this.currentPagetotle = this.failNum;
          }
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    //Inbox Streams 点击
    //切换类型
    inboxclick(type) {
      this.activeName = type;
      
      switch (type) {
        // Published
        case 0:
          this.powerType = "Published";
          this.posttype = "sending";
          this.buttonlist = [
            { name: "Details", emit: "details" },
            { name: this.$i18n.t("label.clone"), emit: "clone" },
            { name: this.$i18n.t("label.import.index.operation"), emit: "" },
          ];
          this.selectvalue = [
            { name: this.$i18n.t("c630"), divided: false, emit: "viewLink" },
            {
              name: this.$i18n.t("label.department.user.delete"),
              divided: false,
              emit: "delete",
            },
          ];
          this.tableAttr = [
            {
              schemefieldName: "textcontent",
              // nameLabel: "POST",
              nameLabel: "c828",
              schemefieldType: "PO",
              colwidth: "",
              click: "detail",
              isfunctional: true,
            },
            {
              schemefieldName: "campaignname",
              // nameLabel: "CAMPAIGN",
              nameLabel: "c829",
              schemefieldType: "",
              colwidth: "120px",
              isfunctional: false,
            },
            {
              schemefieldName: "senddate",
              // nameLabel: "PUBLISHED TIME",
              nameLabel: "c644", //发布时间
              schemefieldType: "DE",
              colwidth: "230px",
              isfunctional: false,
            },
            {
              schemefieldName: "username",
              // nameLabel: "CREATE BY",
              nameLabel: "c830",
              schemefieldType: "",
              colwidth: "150px",
              isfunctional: false,
            },
          ];
          this.currentPagetotle = this.publishedNum;
          this.sortDir = "desc";
          break;
        // Scheduled
        case 1:
          this.powerType = "Scheduled";
          this.posttype = "timing";
          this.buttonlist = [
            {
              name: this.$i18n.t("pagecreator.page.button.edit"),
              divided: false,
              emit: "edit",
            },
            { name: this.$i18n.t("label.clone"), emit: "clone" },
            { name: this.$i18n.t("label.import.index.operation"), emit: "" },
          ];
          this.selectvalue = [
            {
              name: this.$i18n.t("label.department.user.delete"),
              divided: false,
              emit: "delete",
            },
            { name: this.$i18n.t("c702"), divided: false, emit: "makeDraft" },
          ];
          this.currentPagetotle = this.scheduledNum;
          this.tableAttr = [
            {
              schemefieldName: "textcontent",
              // nameLabel: "POST",
              nameLabel: "c828",
              schemefieldType: "PO",
              colwidth: "",
              click: "detail",
              isfunctional: true,
            },
            {
              schemefieldName: "campaignname",
              // nameLabel: "CAMPAIGN",
              nameLabel: "c829",
              schemefieldType: "",
              colwidth: "120px",
              isfunctional: false,
            },
            {
              schemefieldName: "timingdate",
              // nameLabel: "SCHEDULED TIME",
              nameLabel: "c987",//预约发布时间
              schemefieldType: "DE",
              colwidth: "230px",
              isfunctional: false,
            },
            {
              schemefieldName: "username",
              // nameLabel: "CREATE BY",
              nameLabel: "c830",
              schemefieldType: "",
              colwidth: "150px",
              isfunctional: false,
            },
          ];
          this.sortDir = "asc";
          break;
        // Draft
        case 2:
          this.powerType = "Draft";
          this.posttype = "draft";
          this.buttonlist = [
            {
              name: this.$i18n.t("pagecreator.page.button.edit"),
              divided: false,
              emit: "edit",
            },
            { name: this.$i18n.t("label.clone"), emit: "clone" },
            { name: this.$i18n.t("label.import.index.operation"), emit: "" },
          ];
          this.selectvalue = [
            {
              name: this.$i18n.t("label.department.user.delete"),
              divided: false,
              emit: "delete",
            },
            {
              name: this.$i18n.t("c645"), // "Approve draft"
              divided: false,
              emit: "ApproveDraft",
            },
          ];
          this.currentPagetotle = this.draftsNum;
          this.tableAttr = [
            {
              schemefieldName: "textcontent",
              // nameLabel: "POST",
              nameLabel: "c828",
              schemefieldType: "PO",
              colwidth: "",
              click: "detail",
              isfunctional: true,
            },
            {
              schemefieldName: "campaignname",
              // nameLabel: "CAMPAIGN",
              nameLabel: "c829",
              schemefieldType: "",
              colwidth: "120px",
              isfunctional: false,
            },
            {
              schemefieldName: "createdate",
              // nameLabel: 'LAST UPDATED TIME',
              nameLabel: "c700",
              schemefieldType: "DE",
              colwidth: "230px",
              isfunctional: false,
            },
            {
              schemefieldName: "username",
              // nameLabel: "CREATE BY",
              nameLabel: "c830",
              schemefieldType: "",
              colwidth: "150px",
              isfunctional: false,
            },
          ];
          this.sortDir = "desc";
          break;
        // Unsuccessful
        case 3:
          this.powerType = this.$i18n.t("c629");
          this.posttype = "fail";
          this.buttonlist = [
            { name: "Details", divided: false, emit: "details" },
            {
              name: this.$i18n.t("label.appointmentconfirmail.wizard.btn1"),
              emit: "reschedule",
            },
            { name: this.$i18n.t("label.import.index.operation"), emit: "" },
          ];
          this.selectvalue = [
            {
              name: this.$i18n.t("label.department.user.delete"),
              divided: false,
              emit: "delete",
            },
          ];
          this.currentPagetotle = this.failNum;
          this.tableAttr = [
            {
              schemefieldName: "textcontent",
              // nameLabel: "POST",
              nameLabel: "c828",
              schemefieldType: "PO",
              colwidth: "",
              click: "detail",
              isfunctional: true,
            },
            {
              schemefieldName: "campaignname",
              // nameLabel: "CAMPAIGN",
              nameLabel: "c829",
              schemefieldType: "",
              colwidth: "120px",
              isfunctional: false,
            },
            {
              schemefieldName: "createdate",
              // nameLabel: 'LAST UPDATED TIME',
              nameLabel: "c700",
              schemefieldType: "DE",
              colwidth: "230px",
              isfunctional: false,
            },
            {
              schemefieldName: "username",
              // nameLabel: "CREATE BY",
              nameLabel: "c830",
              schemefieldType: "",
              colwidth: "150px",
              isfunctional: false,
            },
          ];
          this.sortDir = "desc";
          break;
      }
      this.clearFilter();
      this.$nextTick(() => {
        this.getPostInfo();
      });
    },
    // 清空筛选条件
    
    clearFilter() {
      this.$emit("clearFilter");
    },
    detailSoical(id) {
      this.$emit("detailSoical", id);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    postAssgin() {
      this.dialogVisible = true;
      marketChannel
        .getScoialMarketingActivity({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            this.campaignList = res.data;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    postClone(row) {
      this.$emit("posterEdit", row, 1);
    },
    //分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.getPostInfo();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPostInfo();
    },
    // showpage() {
    //   if (this.dataList === null && this.dataList === []) {
    //     this.currentPagetotle = 0;
    //   } else {
    //     let arrList = [];
    //     let num = this.currentPage * this.pagesize;
    //     if (num >= this.dataList.length) {
    //       num = this.dataList.length;
    //     }
    //     for (let i = (this.currentPage - 1) * this.pagesize; i < num; i++) {
    //       arrList.push(this.dataList[i]);
    //     }
    //     this.arr = arrList;
    //   }
    // },
  },
};
</script>
<style lang='scss' scoped>
.roundBox {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  float: left;
  margin: 10px;
}
.topContent {
  // width: 100%;
  display: flex;
  // justify-content: space-between;
}
.icon {
  display: inline-block;
}
::v-deep .el-dialog__body {
  padding: 30px 36px 50px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  line-height: 24px;
  .campaign-mes {
    height: 60px;
    line-height: 70px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
  }
  .campaign-remind {
    background: #f8fcff;
    border: 1px solid #006dcc;
    border-radius: 2px;
    border-radius: 2px;
    padding: 20px 50px 20px 15px;
    margin-top: 25px;
  }
}
.block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
}
.share {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #006dcc;
  letter-spacing: 0;
  text-align: right;
  height: 40px;
  line-height: 40px;
}

.sidebar {
  // width: 18%;
  margin-right: 20px;
  ul {
    li {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      width: 210px;
      height: 35px;
      line-height: 33px;
      cursor: pointer;
      position: relative;
      span {
        display: inline-block;
        position: absolute;
        right: 20px;
      }
    }
  }
  li:hover {
    background-color: #dedede;
  }
  .curli {
    background-color: #dedede;
  }
}
.content {
  width: 85%;
  position: relative;
}
</style>