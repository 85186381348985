<!--  -->
<template>
  <div class="bardiv">
    <div class="bartitl">
      <div class="barleft">
        <p class="barleftp">
          {{ bartitle }}
          <popover
            v-if="popshow"
            :poptitle="bartitle"
            :popcont="popcont"
            @func="getshow"
            :popif="popif"
          >
          </popover>
          <el-tooltip
            class="item"
            effect="dark"
            :content="popcont"
             placement="bottom-start"
            popper-class="atooltip"
          >
            <div slot="content">{{ popcont }}</div>
            <svg class="icon" aria-hidden="true">
              <use href="#icon-prompt"></use>
            </svg>
          </el-tooltip>
        </p>
        <!-- <span>Activity between {{ bardate }}</span> -->
      </div>
      <!-- 目前没有需求 屏蔽 -->
      <!-- <button class="barright" @click="drawershow = true">Save report</button> -->
    </div>
    <echartbar
      :echartStyle="echartStyle"
      :opinion="opinion"
      :opinionData="opinionData"
      :x="x"
    >
    </echartbar>
    <drawer :drawershow="drawershow" @func="func" :bartitle="bartitle">
    </drawer>
  </div>
</template>

<script>
import echartbar from '@/views/social/analyze/components/barechart.vue'
import drawer from '@/views/social/analyze/components/drawer.vue'
import popover from '@/views/social/analyze/components/popover.vue'
export default {
  components: {
    echartbar,
    drawer,
    popover
  },
  props: {
    // Audienceshow: {
    //   type: Boolean,
    //   default() { }
    // },
    bartitle: {
      type: String,
      default() { }
    },
    // bardate: {
    //   type: String,
    //   default() { }
    // },
    echartStyle: {  // 样式 - 1
      type: Object,
      default() { }
    },
    opinion: {    //区域名称 - 1
      type: Array,
      default() { return [] }
    },
    opinionData: {   // 区域数据 - 1
      type: Array,
      default() { return [] }
    },
    x: {  // x 轴
      type: Array,
      default() { return [] }
    },
    popshow: {
      type: Boolean,
      default() { }
    },
    popif: {
      type: Boolean,
      default() { }
    },
    popcont: {
      type: String,
      default() { }
    },
  },
  data() {
    return {
      drawershow: false,
    }
  },
  methods: {
    func(key) {
      this.drawershow = key;
    },
    getshow(show, item) {

      this.$emit('getshow', show, item)
    },
  }
}
</script>
<style lang='scss'>
.el-tooltip__popper.atooltip {
  width: 220px;
}
</style>
<style lang='scss' scoped >
.bardiv {
  width: 49.7%;
  margin-top: 11px;
  height: 378px;
  background: #ffffff;
  border: 1px solid #dddbda;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  float: left;
  .bartitl {
    height: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .barleft {
      margin-left: 2.2%;
      .barleftp {
        font-size: 16px;
        color: #080707;
        letter-spacing: 0;
        font-weight: bolder;
        margin-top: 0.6%;
        margin-bottom: 0.5%;
        position: relative;
        .el-tooltip__popper {
          width: 100px;
        }
        .icon {
          cursor: pointer;
          margin-left: 10px;
          width: 15px;
          height: 15px;
        }
      }
      span {
        font-size: 12px;
        color: #888888;
      }
    }
    .barright {
      margin-right: 1.5%;
      background: #006dcc;
      border-radius: 3px;
      outline: none;
      font-size: 12px;
      color: #ffffff;
      width: 92px;
      height: 30px;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
