<template>
  <div class="home" v-loading="isLoading">
    <div
      class="tip-dialog"
      @mouseleave="mouseLeave"
      @mouseenter="mouseEnter"
      id="tip-dialog"
      v-show="showDialog"
    >
      <div class="event-detail-wrap" id="dialog-hov">
        <div class="detail-header">
          <div class="detail-title">
            <div class="detail-title-icon">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-cloudtab6_norm"></use>
              </svg>
            </div>
            <div @click="goDetail('S', 'name')" class="detail-title-text">
              {{ showDialogEvent.title }}
            </div>
          </div>
        </div>

        <div class="detail-info-list" v-loading="miniShow">
          <div class="post-content" v-html="showDialogEvent.textcontent"></div>
        </div>

        <div class="time-group">{{ showDialogEvent.startString }}</div>
      </div>
    </div>
    <FullCalendar
      ref="fullCalendar"
      style="width: 100%"
      :options="calendarOptions"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import * as marketChannel from "../api";
import { getEditPermissions } from "../../api.js";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import * as CommonObjApi from "../api";
// import * as Time from "@/utils/time";

export default {
  name: "Month",
  components: {
    FullCalendar,
  },
  data() {
    return {
      // 数据
      dataList: [],
      miniShow: false,
      tipContent: [], // 悬浮窗内容
      tipData: {}, // 悬浮窗数据
      isLoading: false,
      // showDialog:false,
      activeDate: {
        // 当前年月
        year: "",
        month: "",
      },
      timer: null, // 定时器
      timer2: null, // 定时器2
      isInDialog: false, // 鼠标是否在弹窗里面
      showDialogEvent: {},
      calendarApi: null,
      calendarOptions: {
        firstDay: "0", // 以星期日为第一天
        locale:
          this.$store.state.userInfoObj.language === "jp"
            ? "ja"
            : this.$store.state.userInfoObj.language,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dayMaxEvents: true, // 日最多显示
        // moreLinkContent: function (arg) {
        //   const text = this.$i18n.t('label.more')
        //   return '+' + arg.num + ' ' + text
        // },
        slotEventOverlap: false, // 设置视图中的事件显示是否可以重叠覆盖
        eventDurationEditable: false, // 是否可以改变事件size
        // height:'600px',
        editable: true,
        selectable: true,
        events: [],
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventMouseLeave: this.handleEventLeave,
        eventMouseEnter: this.handleEventEnter,
        eventDrop: this.handleEventDrop,
        eventDisplay: "block", // 事件显示样式（短时间的事件去除点显示）
        eventColor: "#378006", // 事件默认颜色
      },
      // dateFormat: Time.dateFormat($cookies.get("countryCode")),
      // datetimeFormat: Time.datetimeFormat($cookies.get("countryCode")),
      // timeFormat: Time.timeFormat($cookies.get("countryCode")),
      // countryCode: $cookies.get("countryCode"),
    };
  },
  props: ["showDialog"],
  methods: {
    // 判断字段是否可跳转

    isJump(fieldtype, fieldname) {
      if (
        (fieldtype === "S" && fieldname === "name") ||
        fieldtype === "Y" ||
        fieldtype === "M"
      ) {
        // 字段类型为S,Y,M，或者属于name字段即可跳转
        return true;
      } else {
        return false;
      }
    },
    goDetail() {
      // if (this.isJump(type, name)) {
      //   if (name === "name") {
      //     this.$router.push({
      //       path: `/commonObjects/detail/${this.tipData.id}/DETAIL`,
      //     });
      //   } else {
      //     this.$router.push({
      //       path: `/commonObjects/detail/${this.tipData[name]}/DETAIL`,
      //     });
      //   }
      // }
      this.$emit("detailSoical", "123");
    },

    closeDialog() {
      // this.showDialog = false
      this.$emit("updateDialogStatus", "0");
    },
    formateDate(datetime) {
      if (datetime === "" || datetime === undefined || datetime === null)
        return "";
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      var d = new Date(datetime);
      let formateDatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate()) +
        " " +
        addDateZero(d.getHours()) +
        ":" +
        addDateZero(d.getMinutes()) +
        ":" +
        addDateZero(d.getSeconds());
      return formateDatetime;
    },
    // 获取悬浮窗内容
    getTipContent() {
      // getTipContent(eventId) {
      this.miniShow = false;
      // let option = {
      //   id: eventId,
      //   operation: "MINI"
      // }
      // getFromDetail(option).then(res => {
      //   this.tipContent = res.data.fieldList
      //   this.tipData = res.data.data
      //   this.titleIcon = ICONIMAGE[res.data.tabStyle]
      //   if (this.tipData.isremider) {
      //     this.tipData.isremider = this.tipData.isremider === 'true' ? '是' : '否'
      //   }

      // })
    },
    handleEventEnter(info) {
      //
      this.miniShow = true;
      this.tipContent = [];
      this.tipData = {};
      if (this.timer) clearTimeout(this.timer);
      if (this.timer2) clearTimeout(this.timer2);
      var dialogEl = document.getElementById("tip-dialog");
      // 处理弹窗位置
      let divoffwidth = info.jsEvent.clientX - info.jsEvent.offsetX - 5;

      if (
        info.el.offsetParent.offsetWidth == 190 ||
        info.el.offsetParent.offsetWidth == 200
      ) {
        var positwidth = document.body.clientWidth - divoffwidth - 220;
      } else {
        // eslint-disable-next-line no-redeclare
        var positwidth =
          document.body.clientWidth -
          divoffwidth -
          info.el.offsetParent.offsetWidth;
      }
      let diawidth;
      if (positwidth > 270) {
        if (
          info.el.offsetParent.offsetWidth == 190 ||
          info.el.offsetParent.offsetWidth == 200
        ) {
          diawidth = divoffwidth + 220;
        } else {
          diawidth = divoffwidth + info.el.offsetParent.offsetWidth;
        }

        dialogEl.style.left = diawidth + "px";
        dialogEl.style.top = info.jsEvent.clientY - 2 + "px";
      } else {
        if (
          info.el.offsetParent.offsetWidth == 190 ||
          info.el.offsetParent.offsetWidth == 200
        ) {
          diawidth = divoffwidth - 290;
        } else {
          diawidth = divoffwidth - 270;
        }
        dialogEl.style.left = diawidth + "px";
        dialogEl.style.top = info.jsEvent.clientY - 2 + "px";
      }
      // if (document.body.clientWidth - info.jsEvent.clientX < 270) {
      //   let newPixel = info.jsEvent.clientX - 270
      //   dialogEl.style.left = newPixel + 'px'
      //   if (document.body.clientHeight - info.jsEvent.clientY < 270) {
      //     let newPixel = info.jsEvent.clientY - 240
      //     dialogEl.style.top = newPixel + 'px'
      //     // dialogEl.style.top =
      //   } else {
      //     dialogEl.style.top = info.jsEvent.clientY + 'px'
      //   }
      // } else {
      //   dialogEl.style.left = info.jsEvent.clientX + 'px'
      //   if (document.body.clientHeight - info.jsEvent.clientY < 270) {
      //     let newPixel = info.jsEvent.clientY - 240
      //     dialogEl.style.top = newPixel + 'px'
      //   } else {
      //     dialogEl.style.top = info.jsEvent.clientY + 'px'
      //   }
      // }
      this.timer2 = setTimeout(() => {
        this.$emit("updateDialogStatus", "1");
        this.getTipContent(info.event.id);
      }, 500);
      if (!this.isInDialog) {
        this.timer = setTimeout(() => {
          this.$emit("updateDialogStatus", "0");
        }, 10000);
      }
      // this.showDialog = true
      this.showDialogEvent = info.event;
      this.showDialogEvent.textcontent =
        this.showDialogEvent.extendedProps.textcontent;
      this.showDialogEvent.startString = this.formateDate(
        this.showDialogEvent.start
      );
      this.showDialogEvent.endString = this.formateDate(
        this.showDialogEvent.end
      );
    },
    handleEventLeave() {
      this.$emit("updateDialogStatus", "0");
    },
    // 拖拽事件的回调
    async handleEventDrop(info) {
      let res = await getEditPermissions({ id: info.event.id });
      let hasEdit = res.data.modify;
      if (hasEdit) {
        let dateStart = new Date(info.event.start);
        let updatedStartDate = this.formateDate(dateStart);
        let updatedEndDate = null;
        if (info.event.end) {
          let dateEnd = new Date(info.event.end);
          updatedEndDate = this.formateDate(dateEnd);
        }

        let option = { objectApi: "", data: "" };
        option.objectApi = info.event.extendedProps.CCObjectAPI;
        if (info.event.extendedProps.CCObjectAPI === "Event") {
          let eventStr = {
            id: info.event.id,
            begintime: updatedStartDate,
            endtime: updatedEndDate || updatedStartDate,
          };
          option.data = "[" + JSON.stringify(eventStr) + "]";
        } else {
          if (
            info.event.extendedProps.createdate ||
            info.event.extendedProps.lastmodifydate
          ) {
            info.revert();
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_calendar_notice_timecantmove"),
              type: "warning",
            });
            return;
          } else {
            let objStr = {
              id: info.event.id,
            };
            objStr[info.event.extendedProps.beginfield] = updatedStartDate;
            option.data = "[" + JSON.stringify(objStr) + "]";
          }
        }
        this.editEvent(option);
      } else {
        info.revert();
      }
    },
    // 点击日期新建事件
    // 新建事件
    handleDateClick(arg) {
      let date = this.formateDate(arg.date);
      this.$emit("updateDialogStatus", "0");
      this.$emit("openNewEvent", date);
    },
    // 点击事件操作
    handleEventClick(info) {
      let obj = {
        id: info.event.id,
        objectApi: info.event.extendedProps.CCObjectAPI,
        objId: "event",
        type: "event",
      };
      this.$emit("updateDialogStatus", "0");
      this.$emit("goItemDetail", obj);
    },
    // 父组件点击前进、后退、今天的事件执行

    parentHandleClick(flag) {
      if (flag == -1) {
        this.calendarApi.prev();
      } else if (flag == 1) {
        this.calendarApi.next();
      } else if (flag == 0) {
        this.calendarApi.today();
      }
      this.getHeaderDate(
        this.calendarApi.currentData.viewTitle,
        this.calendarApi.currentData.currentDate
      );
    },
    // 父组件传递的刷新视图

    parentRefresh() {
      this.getHeaderDate(
        this.calendarApi.currentData.viewTitle,
        this.calendarApi.currentData.currentDate
      );
    },
    // 父组件点击小日历跳转

    parentGoDate(date) {
      this.calendarApi.gotoDate(date);
      this.getHeaderDate(
        this.calendarApi.currentData.viewTitle,
        this.calendarApi.currentData.currentDate
      );
    },
    // 侧边栏展开或收起，日历的自适应

    parentHandleResize() {
      this.calendarApi.incrementDate("00:00:01");
    },
    // 获取真实时间
    getRealDate(dateData) {
      let date = new Date(dateData);
      let realDate = new Date(date.getTime() - 8 * 60 * 60 * 1000);
      let y = realDate.getFullYear();
      let m =
        realDate.getMonth() + 1 < 10
          ? "0" + (realDate.getMonth() + 1)
          : realDate.getMonth() + 1;
      let d =
        realDate.getDate() < 10 ? "0" + realDate.getDate() : realDate.getDate();
      // let hh = realDate.getHours() < 10 ? "0" + date.getHours() : realDate.getHours();
      // let mm = realDate.getMinutes()
      // let ss = realDate.getSeconds()
      return y + "-" + m + "-" + d;
    },
    // 获取左上角日期并回传给父组件
    getHeaderDate(date, day) {
      this.$emit("headerDate", date, this.getRealDate(day));
      // 获取月份的起始
      let dateObj = { showtype: "month" };
      this.activeDate = this.getCurMon(day);
      dateObj.startdate =
        this.activeDate.year + "-" + this.activeDate.month + "-01";
      dateObj.enddate =
        this.activeDate.year + "-" + this.activeDate.month + "-";
      // if (date.length === 8) {
      //   this.activeDate = {year: date.slice(0,4), month: date.slice(5,7)}
      //   dateObj.startdate = this.activeDate.year+'-'+this.activeDate.month+'-01'
      //   dateObj.enddate = this.activeDate.year+'-'+this.activeDate.month+'-'
      // } else if(date.length === 7){
      //   this.activeDate = {year: date.slice(0,4), month: date.slice(5,6)}
      //   dateObj.startdate = this.activeDate.year+'-0'+this.activeDate.month+'-01'
      //   dateObj.enddate = this.activeDate.year+'-0'+this.activeDate.month+'-'
      // }
      let days = this.getMonthDays(this.activeDate.year, this.activeDate.month);
      dateObj.enddate = dateObj.enddate + days;
      this.getAllItem(dateObj);
    },
    // 获取选择的年月
    getCurMon(timestamp) {
      const dd = new Date(timestamp);
      const year = dd.getFullYear();
      const month =
        dd.getMonth() < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      return {
        year,
        month,
      };
    },
    // 获取某月有多少天
    getMonthDays(year, month) {
      let dayCount;
      let now = new Date(year, month, 0);
      dayCount = now.getDate();
      return dayCount;
    },
    mouseLeave() {
      this.isInDialog = false;
      this.$emit("updateDialogStatus", "0");
    },
    mouseEnter() {
      this.isInDialog = true;
      clearTimeout(this.timer);
    },
    // 拖拽后的编辑
    async editEvent(data) {
      let res = await CommonObjApi.save(data);
      if (res.data && res.data[0] && res.data[0].isSaveSuccess === "true") {
        this.$message.success(this.$i18n.t("savesuccess"));
      } else {
        // 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
        if(res?.data && res.data[0]?.errormessage.includes('illegal_field_apiname')){
          // 值不存在或与筛选条件不匹配
          this.$message.error(this.$i18n.t("c2420"));
        }else{
          this.$message.error(
            res.data[0].errormessage || this.$i18n.t("chatter_save_f")
          );
        }
      }
    },

    timeFn(d1, d2) {
      //di作为一个变量传进来
      if (!d1 || !d2) {
        return 0;
      }
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      let dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
      let dateEnd;
      if (d2) {
        dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
      } else {
        dateEnd = dateBegin;
      }
      let dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      //计算出相差天数
      return Math.floor(dateDiff / (24 * 3600 * 1000));
    },
    async getAllItem() {
      this.isLoading = true;
      await marketChannel
        .getPostInfo({ orgid: this.$store.state.userInfoObj.orgId })
        .then((res) => {
          if (res.result) {
            this.dataList = res.data.sociallist;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      this.dataList.map((item) => {
        item.title = item.socialmedianame;
        item.start = item.senddate;
        if (item.posttype === "sending") {
          item.color = "#19CAAD";
        } else if (item.posttype === "timing") {
          item.color = "#A0EEE1";
        } else if (item.posttype === "draft") {
          item.color = "#D6D5B7";
        }
      });
      this.calendarOptions.events = this.dataList;

      this.isLoading = false;
    },
  },
  mounted() {
    // 访问Calendar原始数据和方法的基础对象
    if (this.$store.state.userInfoObj.language === "jp") {
      this.calendarOptions.locale = "ja";
    } else {
      this.calendarOptions.locale = this.$store.state.userInfoObj.language;
    }
    this.calendarApi = this.$refs.fullCalendar.getApi();

    this.getHeaderDate(
      this.calendarApi.currentData.viewTitle,
      this.calendarApi.currentData.currentDate
    );
  },
};
</script>
<style lang="scss" scoped>
@import "./calendarDialogCss/calendarDialog.scss";
.icon {
  display: inline-block;
}
.time-group {
  box-sizing: border-box;
  border-top: 1px solid #e0e0e0;
  margin-top: 8px;
  padding: 0 12px;
  text-align: right;
  height: 42px;
  background: #f4f6f9;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.post-content {
  width: 100%;
  word-wrap: break-word;
}
::v-deep .lightColor {
  opacity: 0.5;
}
::v-deep .fc .fc-daygrid-more-link {
  color: #999999;
}
::v-deep .fc-theme-standard th {
  height: 34px;
  vertical-align: inherit;
}
::v-deep .fc .fc-daygrid-day-number {
  color: #999999;
}
::v-deep .fc .fc-col-header-cell-cushion {
  color: #999999;
}
::v-deep .fc-h-event .fc-event-time {
  overflow: visible;
}
::v-deep .fc-h-event .fc-event-main:hover {
  color: dodgerblue;
}
::v-deep .fc-h-event .fc-event-title {
  text-overflow: ellipsis;
}
::v-deep .fc .fc-toolbar.fc-header-toolbar {
  display: none;
}
::v-deep .fc-h-event .fc-event-main-frame {
  display: flex;
  height: 25px;
  align-items: center;
  padding: 10px;
}
::v-deep .fc-daygrid-event {
  border-radius: 15px;
}
::v-deep .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 5px;
}
::v-deep .fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 5px;
}
::v-deep .fc .fc-more-popover .fc-popover-body {
  overflow: auto;
  max-height: 300px;
}
</style>
