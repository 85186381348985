/*
 * @Author: your name
 * @Date: 2022-03-30 17:38:45
 * @LastEditTime: 2022-03-31 19:23:49
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\social\manage\api.js
 */
import axios from '@/config/httpConfig'

//保存社交媒体用户和授权token
export function storeCacheAccessToken(data) {
    return axios.post('/social/storeCacheAccessToken', data)
}
//获取领英授权url
export function authorizationUrl(data) {
    return axios.post('/social/authorizationUrl', data)
}
// 查询Social用户信息
export function getScoialUserinfo(data) {
    return axios.post('/social/querySocialUser', data)
}
// 查询市场活动信息
export function getScoialMarketingActivity(data) {
    return axios.post('/social/queryCampaign', data)
}
// 批量修改市场活动
export function updateBatchPostCampaign(data) {
    return axios.post('/social/updateBatchPostsCampaign', data)
}
// 批量删除帖子
export function delBatchPost(data) {
    return axios.post('/social/delBatchPosts', data)
}
//删除Social帖子信息
export function delPostInfo(data) {
    return axios.post('/social/delPostsInfo', data)
}
//查询Social帖子列表
export function getPostInfo(data) {
    return axios.post('/social/getPostsInfo', data)
}
// 查询Social帖子类型数量
export function getPostsTypeQuantity(data) {
    return axios.post('/social/getPostsTypeQuantity', data)
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}

//修改帖子类型（定时和草稿转换）
export function editPoststype(data) {
    return axios.post('/social/editPoststype', data)
}