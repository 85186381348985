<template>
  <div class="filterBox">
    <!-- 筛选cell -->
    <div class="filterCell">
      <span>
        <!-- Accounts: -->
        {{ $t("label.biaoqingfaxian.username") }}:
      </span>
      <el-dropdown
        trigger="click"
        :hide-on-click="false"
        placement="bottom-start"
      >
        <span class="el-dropdown-link">
          <span style="cursor: pointer; color: #006dcc">{{ accountsName }}</span
          ><i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div style="max-height: 360px; overflow: auto">
            <el-dropdown-item v-for="(item, num) in selectoptions" :key="num">
              <el-checkbox
                v-model="item.checked"
                @change="partfromSelect"
                v-show="item.accountsList.length > 0"
              >
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="width: 20px; height: 15px"
                >
                  <use :href="'#icon-' + item.img"></use>
                </svg>
                <span style="color: #080707">{{ $t(item.value) }}</span>
              </el-checkbox>
              <div class="el-checkbox-group" style="margin-left: 25px">
                <!-- 账号 -->
                <el-checkbox
                  v-for="(acc, accIndex) in item.accountsList"
                  :key="accIndex"
                  v-model="acc.checked"
                  @change="accSelect"
                  ><span style="color: #080707">{{
                    acc.socialmedianame
                  }}</span></el-checkbox
                >
              </div>
            </el-dropdown-item>
            <p class="connect" @click="connectAc">
              <!-- Connect account -->
              {{ $t("c43") }}
              <svg class="icon" aria-hidden="true">
                <use href="#icon-share"></use>
              </svg>
            </p>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 日期筛选器 -->
    <div class="filterCell" v-if="isShowUser">
      <span>{{ $t("label.chatter.dateRange") }}: </span>
      <el-date-picker
        size="mini"
        @change="dateChange"
        v-model="dateFilter"
        type="daterange"
        :picker-options="pickerOptions"
        :range-separator="'to'"
        align="right"
        start-placeholder="YYYY-MM-DD"
        end-placeholder="YYYY-MM-DD"
      >
      </el-date-picker>
    </div>
    <!-- 普通筛选器 -->
    <div class="filterCell">
      <span>{{ $t("label.marketingactivity") }}: </span>
      <!-- <el-dropdown trigger="click" @command="changeCampaign">
        <span class="el-dropdown-link">
          <span style="cursor: pointer">{{ campaignName }}</span
          ><i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div class="postingInput">
            <el-input  size="mini" v-model="searchCampaign">
              <i
                class="el-icon-search el-input__icon"
                style="cursor: pointer;"
                slot="suffix"
                @click="searchCampateMethod"
              >
              </i>
            </el-input>
          </div>
          <div style="max-height: 300px; overflow: auto">
            <el-dropdown-item
              v-for="(item, num) in campaignListS"
              :key="num"
              :command="item"
              style="max-width: 300px"
            >
              <span>{{ item.name }}</span>
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-popover placement="bottom" width="300" v-model="visible" @hide="hide">
        <div class="postingInput">
          <el-input
            size="mini"
            v-model="searchCampaign"
            @keyup.enter.native="searchCampateMethod"
            @input="searchCampateMethod"
          >
            <i
              class="el-icon-search el-input__icon"
              style="cursor: pointer"
              slot="suffix"
              @click="searchCampateMethod"
            >
            </i>
          </el-input>
        </div>
        <div style="max-height: 300px; overflow: auto">
          <div
            v-for="(item, num) in campaignListS"
            :key="num"
            :command="item"
            style="max-width: 300px"
          >
            <div
              @click="changeCampaign(item, num)"
              :class="[
                'campaign-name',
                {
                  'campaign-name-active': campaignActive == num,
                },
              ]"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <span class="el-dropdown-link" slot="reference">
          <span style="cursor: pointer; color: #006dcc">{{
            $t(campaignName)
          }}</span
          ><i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </el-popover>
    </div>
    <!-- 普通筛选器 -->
    <div class="filterCell" v-if="isShowUser">
      <span>
        <!-- Created by: -->
        {{ $t("label.tag.manage.createdby") }}:
      </span>
      <el-popover
        placement="bottom"
        width="300"
        v-model="uservisible"
        @hide="userhide"
      >
        <div class="postingInput">
          <!-- <el-input
            size="mini"
            v-model="searchCampaign"
            @keyup.enter.native="searchCampateMethod"
          >
            <i
              class="el-icon-search el-input__icon"
              style="cursor: pointer"
              slot="suffix"
              @click="searchCampateMethod"
            >
            </i>
          </el-input> -->
          <el-input
            size="mini"
            v-model="searchUser"
            @keyup.enter.native="searchUserMethod"
            @input="searchUserMethod"
          >
            <i
              class="el-icon-search el-input__icon"
              style="cursor: pointer"
              slot="suffix"
              @click="searchUserMethod"
            >
            </i>
          </el-input>
        </div>
        <div style="max-height: 300px; overflow: auto">
          <div
            v-for="(item, num) in userListProp"
            :key="num"
            :command="item"
            style="max-width: 300px"
          >
            <div
              @click="changeUser(item, num)"
              :class="[
                'user-name',
                {
                  'user-name-active': userActive == num,
                },
              ]"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <span class="el-dropdown-link" slot="reference">
          <span style="cursor: pointer; color: #006dcc">{{ userName }}</span
          ><i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </el-popover>
    </div>
  </div>
</template>

<script>
import * as socialApi from "./api";
export default {
  data() {
    return {
      userListProp: this.userList,
      cloneCampaignList: [],
      cloneUserList: [],
      searchCampaign: "",
      searchUser: "",
      dateFilter: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: this.$i18n.t("today"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("label.brief.thisweek"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("label.notification.last30days"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t("this.year"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      accountsName: this.$i18n.t("c624"), //账号选择名称
      campaignName: this.$i18n.t("c625"),
      userName: this.$i18n.t("c626"),
      visible: false,
      uservisible: false,
      campaignActive: "-1",
      userActive: "-1",
      campaignListS: this.campaignList,
    };
  },
  created() {
    this.cloneUserList = JSON.parse(JSON.stringify(this.userListProp));
    this.cloneCampaignList = JSON.parse(JSON.stringify(this.campaignListS));
  },
  watch: {
    dateFilter() {
      this.$emit("dateChange", null);
    },
  },
  //方法集合
  methods: {
    clearFilter() {
      this.cloneCampaignList = [];
      this.cloneUserList = [];
      this.searchCampaign = "";
      this.searchUser = "";
      this.dateFilter = "";
      (this.accountsName = this.$i18n.t("c624")), // "All Account"; //账号选择名称
        (this.campaignName = "c625"); // "All Campagin";
      (this.userName = this.$i18n.t("c626")), //"All User";
        (this.campaignActive = "-1");
      this.userActive = "-1";
    },
    getScoialMarketingActivity() {
      socialApi
        .getScoialMarketingActivity({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          this.campaignListS = res.data;
          let obj = {
            id: "",
            name: this.$i18n.t("c625"),
          };
          this.campaignListS.unshift(obj);
        });
    },
    getUserList() {
      socialApi.getUserList().then((res) => {
        this.userListProp = res.data;
        let obj = {
          id: "",
          name: this.$i18n.t("c626"), //"All User",
        };
        this.userListProp.unshift(obj);
      });
    },
    hide() {
      this.campaignActive = "-1";
      this.searchCampaign = "";
      this.searchCampateMethod();
    },
    userhide() {
      this.userActive = "-1";
      this.searchUser = "";
      this.searchUserMethod();
    },
    //模糊搜索
    searchCampateMethod() {
      if (this.searchCampaign) {
        let userList = [];
        this.cloneCampaignList = this.campaignListS;
        this.campaignListS.forEach((el) => {
          if (el.name.indexOf(this.searchCampaign) !== -1) {
            userList.push(el);
          }
        });
        this.campaignListS = userList;
      } else {
        this.getScoialMarketingActivity();
      }
    },
    //模糊搜索
    searchUserMethod() {
      if (this.searchUser) {
        let userListin = [];
        this.cloneUserList = this.userListProp;
        this.userListProp.forEach((el) => {
          if (el.name.indexOf(this.searchUser) !== -1) {
            userListin.push(el);
          }
        });
        this.userListProp = userListin;
      } else {
        this.getUserList();
      }
    },
    connectAc() {
      this.$router.push({
        path: "/systemSettings/setuppage/marketSocialAccount",
      });
    },
    partfromSelect() {
      let partfrom = "";
      let accounts = "";
      let acciuntsNum = 0;
      // 累加选择平台
      this.selectoptions.forEach((item) => {
        if (item.checked) {
          partfrom += item.key + ",";
          // 累加选择账号
          item.accountsList.forEach((acc) => {
            acc.checked = true;
          });
        } else {
          item.accountsList.forEach((acc) => {
            acc.checked = false;
          });
        }
        item.accountsList.forEach((acc) => {
          if (acc.checked) {
            accounts += acc.socialmediaid + ",";
            acciuntsNum++;
          }
        });
      });
      // 去除逗号
      if (accounts.length > 0) {
        accounts = accounts.substr(0, accounts.length - 1);
      }
      if (partfrom.length > 0) {
        partfrom = partfrom.substr(0, partfrom.length - 1);
      }
      if (acciuntsNum > 0) {
        this.accountsName = "select " + acciuntsNum + " account";
      } else {
        this.accountsName = "select account";
      }
      this.$emit("partfromSelect", partfrom, accounts);
    },
    accSelect() {
      let partfrom = "";
      let accounts = "";
      let acciuntsNum = 0;
      // 累加选择平台
      this.selectoptions.forEach((item) => {
        let partAccIndex = 0;
        if (item.checked) {
          partfrom += item.key + ",";
          // 累加选择账号
        }
        item.accountsList.forEach((acc) => {
          if (acc.checked) {
            accounts += acc.socialmediaid + ",";
            acciuntsNum++;
            partAccIndex++;
            item.isIndeterminate = true;
          }
        });
        if (partAccIndex === item.accountsList.length) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      // 去除逗号
      if (accounts.length > 0) {
        accounts = accounts.substr(0, accounts.length - 1);
      }
      if (partfrom.length > 0) {
        partfrom = partfrom.substr(0, partfrom.length - 1);
      }
      if (acciuntsNum > 0) {
        this.accountsName = "select " + acciuntsNum + " account";
      } else {
        this.accountsName = "select account";
      }
      this.$emit("partfromSelect", partfrom, accounts);
    },
    dateChange() {
      this.$emit("dateChange", this.dateFilter);
    },
    changeCampaign(item, index) {
      this.visible = false;
      this.campaignActive = index;
      this.campaignName = item.name;
      this.searchCampaign = "";
      this.$emit("changeCampaign", item.id);
    },
    changeUser(item, index) {
      this.userActive = index;
      this.uservisible = false;
      this.userName = item.name;
      this.searchUser = "";
      this.$emit("changeUser", item.id);
    },
  },
  props: {
    isShowUser: {
      type: Boolean,
      default: true,
    },
    userList: {
      type: [Array, String],
      default: () => [],
    },
    campaignList: {
      type: Array,
      default: () => [],
    },
    selectoptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border-style: none;
  display: none;
}

.el-popper {
  border: 0px;
}
.postingInput {
  margin: 10px 0;
}
::v-deep .el-dropdown-menu__item {
  line-height: 24px;
}
::v-deep .el-popper {
  margin-top: 12px;
  margin-right: 10px;
  border: solid 1px #aaaaaa;
  max-height: 320px;
  overflow: auto;
}
.filterBox {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .filterCell {
  }
}
.vaild {
  width: 96%;
  margin-left: 2%;
  margin-top: 30px;
  margin-bottom: 20px;
  .el-date-editor {
    width: 400px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
.el-checkbox-group {
  font-size: 0;
  display: flex;
  flex-direction: column;
}
.connect {
  color: #006dcc;
  padding-left: 17px;
  cursor: pointer;
  line-height: 34px;
}
.campaign-name {
  &:hover {
    background: #ecf5ff;
    color: #66b1ff;
  }
}
.campaign-name-active {
  background: #ecf5ff;
  color: #66b1ff;
}
.user-name {
  &:hover {
    background: #ecf5ff;
    color: #66b1ff;
  }
}
.user-name-active {
  background: #ecf5ff;
  color: #66b1ff;
}
</style>
