<template>
  <div class="home" v-loading="isLoading">
    <div
      class="tip-dialog"
      @mouseleave="mouseLeave"
      @mouseenter="mouseEnter"
      id="tip-dialog"
      v-show="showDialog"
    >
      <div class="event-detail-wrap" id="dialog-hov">
        <div class="detail-header">
          <div class="detail-title">
            <div class="detail-title-icon">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-cloudtab6_norm"></use>
              </svg>
            </div>
            <div @click="goDetail('S', 'name')" class="detail-title-text">
              {{ showDialogEvent.title }}
            </div>
          </div>
        </div>

        <div class="detail-info-list" v-loading="miniShow">
          <div v-for="item in tipContent" class="detail-item" :key="item">
            <div class="detail-item-k">{{ item.labelname }}</div>
            <div
              @click="goDetail(item.fieldtype, item.fieldname)"
              :class="
                isJump(item.fieldtype, item.fieldname)
                  ? 'detail-item-v detail-item-v-active'
                  : 'detail-item-v'
              "
              v-if="
                item.fieldtype === 'Y' ||
                item.fieldname === 'relateid' ||
                item.fieldname === 'whoid'
              "
            >
              <!-- 日期时间国际化转换 CN为- USA为/ {{tipData[`${item.fieldname}ccname`]}} -->
              <span v-if="item.fieldtype == 'F'">{{
                tipData[item.fieldname + "ccname"] | datetimeFormat(countryCode)
              }}</span>
              <span v-else-if="item.fieldtype == 'D'">{{
                tipData[item.fieldname + "ccname"] | dateFormat(countryCode)
              }}</span>
              <span v-else>{{ tipData[item.fieldname + "ccname"] }}</span>
            </div>
            <div
              @click="goDetail(item.fieldtype, item.fieldname)"
              :class="
                isJump(item.fieldtype, item.fieldname)
                  ? 'detail-item-v detail-item-v-active'
                  : 'detail-item-v'
              "
              v-else
            >
              <!-- 日期时间国际化转换 CN为- USA为/ {{tipData[item.fieldname]}} -->
              <span v-if="item.fieldtype == 'F'">{{
                tipData[item.fieldname] | datetimeFormat(countryCode)
              }}</span>
              <span v-else-if="item.fieldtype == 'D'">{{
                tipData[item.fieldname] | dateFormat(countryCode)
              }}</span>
              <span v-else>{{ tipData[item.fieldname] }}</span>
            </div>
          </div>
        </div>

        <div class="action-group" v-show="false">
          <div class="btn-left">
            <!-- 更多详细信息 -->
            <el-button size="mini" type="info" plain>{{$t('c873')}}</el-button>
          </div>
          <div class="btn-right">
            <!-- 删除 -->
            <el-button size="mini">{{$t('component.chatter.button.delete')}}</el-button>
            <!-- 编辑 -->
            <el-button size="mini">{{$t('label.department.user.edit')}}</el-button>
          </div>
        </div>
      </div>
    </div>
    <FullCalendar
      ref="fullCalendar"
      style="width: 100%; height: 100%"
      :options="calendarOptions"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { getCalendarList, getAllItems, getFromDetail } from "../../api.js";
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import * as Time from '@/utils/time'

export default {
  name: 'Available',
  components: {
    FullCalendar
  },
  props: ['showDialog'],
  data() {
    return {
      miniShow: false,
      tipContent: [], // 悬浮窗内容
      tipData: {}, // 悬浮窗数据
      isLoading: false,
      timer: null, // 定时器
      timer2: null, // 定时器2
      isInDialog: false, // 鼠标是否在弹窗里面
      showDialogEvent: {},
      calendarApi: null,
      calendarOptions: {
        locale: this.$store.state.userInfoObj.language === 'jp' ? 'ja' : this.$store.state.userInfoObj.language,
        allDayText: '', // 全天事件的文本
        plugins: [resourceTimeGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'resourceTimeGridDay',
        resourceAreaWidth: 100,
        resources: [
          // { id: 'a', title: 'Room A' },
          // { id: 'b', title: 'Room B'},
        ],
        events: [
          // {"resourceId":"a","title":"event 1","start":"2020-09-10","end":"2020-09-11", color:'red',allDay: true},
          // {"resourceId":"b","title":"event 3","start":"2020-09-09T12:00:00+00:00","end":"2020-09-10T06:00:00+00:00", color:'blue'},
        ],
        slotEventOverlap: false,
        eventDurationEditable: false, // 是否可以改变事件size
        editable: false,
        selectable: true,
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventMouseLeave: this.handleEventLeave,
        eventMouseEnter: this.handleEventEnter,
        eventDisplay: 'block', // 事件显示样式（短时间的事件去除点显示）
        eventColor: '#378006' // 事件默认颜色
      },
      dateFormat: Time.dateFormat(this.$cookies.get('countryCode')),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get('countryCode')),
      // timeFormat: Time.timeFormat(this.$cookies.get('countryCode')),
      countryCode: this.$cookies.get('countryCode'),
    }
  },
  methods: {
    // 判断字段是否可跳转
    isJump(fieldtype, fieldname) {
      if (
        (fieldtype === "S" && fieldname === "name") ||
        fieldtype === "Y" ||
        fieldtype === "M"
      ) {
        // 字段类型为S,Y,M，或者属于name字段即可跳转
        return true;
      } else {
        return false;
      }
    },
    goDetail(type, name) {
      if (this.isJump(type, name)) {
        if (name === "name") {
          this.$router.push({
            path: `/commonObjects/detail/${this.tipData.id}/DETAIL`,
          });
        } else {
          this.$router.push({
            path: `/commonObjects/detail/${this.tipData[name]}/DETAIL`,
          });
        }
      }
    },
    mouseLeave() {
      this.isInDialog = false
      this.$emit('updateDialogStatus', '0')
    },
    mouseEnter() {
      this.isInDialog = true
      clearTimeout(this.timer)
    },
    
    closeDialog() {
      this.$emit('updateDialogStatus', '0')
    },
    formateDate(datetime) {
      if (datetime === '' || datetime === undefined || datetime === null) return ''
      function addDateZero(num) {
        return (num < 10 ? "0" + num : num);
      }
      var d = new Date(datetime);
      let formateDatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate()) + ' ' + addDateZero(d.getHours()) + ':' + addDateZero(d.getMinutes()) + ':' + addDateZero(d.getSeconds());
      return formateDatetime;
    },
    // 获取悬浮窗内容
    getTipContent(eventId) {
      let option = {
        id: eventId,
        operation: "MINI"
      }
      getFromDetail(option).then(res => {
        this.tipContent = res.data.fieldList
        this.tipData = res.data.data
        if (this.tipData.isremider) {
          this.tipData.isremider = this.tipData.isremider === 'true' ? '是' : '否'
        }
        this.miniShow = false
      })
    },
    handleEventEnter(info) {
      this.miniShow = true
      this.tipContent = []
      this.tipData = {}
      if (this.timer) clearTimeout(this.timer)
      if (this.timer2) clearTimeout(this.timer2)
      var dialogEl = document.getElementById('tip-dialog')
      // 处理弹窗位置
      if (document.body.clientWidth - info.jsEvent.clientX < 270) {
        let newPixel = info.jsEvent.clientX - 270
        dialogEl.style.left = newPixel + 'px'
        if (document.body.clientHeight - info.jsEvent.clientY < 270) {
          let newPixel = info.jsEvent.clientY - 240
          dialogEl.style.top = newPixel + 'px'
          // dialogEl.style.top =
        } else {
          dialogEl.style.top = info.jsEvent.clientY + 'px'
        }
      } else {
        dialogEl.style.left = info.jsEvent.clientX + 'px'
        if (document.body.clientHeight - info.jsEvent.clientY < 270) {
          let newPixel = info.jsEvent.clientY - 240
          dialogEl.style.top = newPixel + 'px'
        } else {
          dialogEl.style.top = info.jsEvent.clientY + 'px'
        }
      }
      this.timer2 = setTimeout(() => {
        this.$emit('updateDialogStatus', '1')
        this.getTipContent(info.event.id)
      }, 500)
      if (!this.isInDialog) {
        this.timer = setTimeout(() => {
          this.$emit('updateDialogStatus', '0')
        }, 10000)
      }
      this.showDialogEvent = info.event
      this.showDialogEvent.startString = this.formateDate(this.showDialogEvent.start)
      this.showDialogEvent.endString = this.formateDate(this.showDialogEvent.end)
    },
    handleEventLeave() {
      // this.showDialog = false
    },
    handleDateClick(arg) {
      if (arg.resource.extendedProps.relationid) {
        let obj = {
          userName: arg.resource.extendedProps.name,
          userId: arg.resource.extendedProps.relationid
        }
        let date = this.formateDate(arg.date)
        this.$emit('updateDialogStatus', '0')
        this.$emit('openNewEvent', date, obj)
      } else {
        let date = this.formateDate(arg.date)
        this.$emit('updateDialogStatus', '0')
        this.$emit('openNewEvent', date)
      }
    },
    handleEventClick(info) {
      let obj = {
        id: info.event.id,
        objectApi: info.event.extendedProps.CCObjectAPI,
        objId: 'event',
        type: 'event'
      }
      this.$emit('updateDialogStatus', '0')
      this.$emit('goItemDetail', obj)
    },
    
    parentHandleClick(flag) {
      if (flag == -1) {
        this.calendarApi.prev()
      } else if (flag == 1) {
        this.calendarApi.next()
      } else if (flag == 0) {
        this.calendarApi.today()
      }
      this.getHeaderDate(this.calendarApi.currentData.viewTitle, this.calendarApi.currentData.currentDate)
    },
    // 父组件传递的刷新视图
    
    parentRefresh() {
      this.getHeaderDate(this.calendarApi.currentData.viewTitle, this.calendarApi.currentData.currentDate)
      this.getHeaderItem()
    },
    // 父组件点击小日历跳转
    
    parentGoDate(date) {
      this.calendarApi.gotoDate(date)
      this.getHeaderDate(this.calendarApi.currentData.viewTitle, this.calendarApi.currentData.currentDate)
    },
    
    parentHandleResize() {
      this.calendarApi.incrementDate('00:00:01')
    },
    // 获取真实时间
    getRealDate(dateData) {
      let date = new Date(dateData)
      let realDate = new Date(date.getTime() - 8 * 60 * 60 * 1000)
      let y = realDate.getFullYear();
      let m = realDate.getMonth() + 1 < 10 ? "0" + (realDate.getMonth() + 1) : realDate.getMonth() + 1;
      let d = realDate.getDate() < 10 ? "0" + realDate.getDate() : realDate.getDate();
      // let hh = realDate.getHours() < 10 ? "0" + date.getHours() : realDate.getHours();
      // let mm = realDate.getMinutes()
      // let ss = realDate.getSeconds()
      return y + '-' + m + '-' + d
    },
    // 获取左上角日期并回传给父组件
    getHeaderDate(date, day) {
      this.$emit('headerDate', date, this.getRealDate(day))
      // let d = new Date(day)
      // function addDateZero(num) {
      //   return (num < 10 ? "0" + num : num);
      // }
      // let oneDay = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate());
      let oneDay = this.getRealDate(day)
      this.getAllItem({
        showtype: 'day',
        startdate: oneDay,
        enddate: oneDay
      })
    },
    async getHeaderItem() {
      let obj = await getCalendarList()
      let res = obj.data.mycalendar.concat(obj.data.other)
      let newArr = res.filter(item => item.state === '1')
      newArr.map(item => {
        if (item.type === 'myevent') {
          item.name = this.$i18n.t("label.myevent")
        }
        item.title = item.name
      })
      if (newArr.length > 10) { newArr.length = 10 }
      this.calendarOptions.resources = newArr
    },
    timeFn(d1, d2) {
      //di作为一个变量传进来
      if (!d1 || !d2) {
        return 0
      }
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      let dateBegin = new Date(d1.replace(/-/g, "/"));//将-转化为/，使用new Date
      let dateEnd
      if (d2) {
        dateEnd = new Date(d2.replace(/-/g, "/"));//将-转化为/，使用new Date
      } else {
        dateEnd = dateBegin
      }
      let dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数
      //计算出相差天数
      return Math.floor(dateDiff / (24 * 3600 * 1000))
    },
    async getAllItem(option) {
      this.isLoading = true
      let res = await getAllItems(option);
      let list = res.data.list
      let array = []
      list.map(item => {
        // if (item.type === 'myevent') {
        //   item.color = '#1fb3f3'
        // } else if (item.type === 'object') {
        //   item.color = '#A094ED'
        // }
        if (item.data) {
          item.data.map(itemBase => {
            // itemBase.color = item.color
            if (item.type === 'myevent') {
              itemBase.color = 'rgb(88, 22, 139)'
              if (this.timeFn(itemBase.begintime, itemBase.endtime) >= 1) {
                itemBase.allDay = true
              }
            }
            if (itemBase.beginfield) {
              itemBase.baseTime = itemBase[itemBase.beginfield]
            }
            if (itemBase.showfield) {
              itemBase.baseTitle = itemBase[itemBase.showfield]
            }
          })
          array.push(...item.data)
        }
      })
      array.map(item => {
        item.resourceId = item.calendardid
        item.title = item.subject || item.baseTitle
        item.start = item.begintime || item.baseTime
        item.end = item.endtime || item.baseTime
        if (item.lightColor === '1') {
          item.className = 'lightColor'
        }
      })
      this.calendarOptions.events = array
      this.isLoading = false
    }
  },
  mounted() {
    if (this.$store.state.userInfoObj.language === 'jp') {
      this.calendarOptions.locale = 'ja'
    } else {
      this.calendarOptions.locale = this.$store.state.userInfoObj.language
    }
    this.calendarApi = this.$refs.fullCalendar.getApi()
    this.getHeaderDate(this.calendarApi.currentData.viewTitle, this.calendarApi.currentData.currentDate)
    this.getHeaderItem()
  }
}
</script>
<style lang="scss" scoped>
@import "./calendarDialogCss/calendarDialog.scss";
.icon {
  display: inline-block;
}
::v-deep .lightColor {
  opacity: 0.5;
}
::v-deep .fc-theme-standard th {
  height: 34px;
  vertical-align: inherit;
}
::v-deep .fc-h-event .fc-event-main-frame {
  display: flex;
  height: 25px;
  align-items: center;
}
::v-deep .fc .fc-toolbar.fc-header-toolbar {
  display: none;
}
::v-deep .fc-v-event .fc-event-main:hover {
  color: dodgerblue;
}
::v-deep .fc-event-title-container:hover {
  color: dodgerblue;
}
::v-deep .fc .fc-scroller {
  max-height: 120px;
}
::v-deep .fc .fc-scroller-liquid-absolute {
  max-height: none;
}
::v-deep .fc .fc-scrollgrid-section,
.fc .fc-scrollgrid-section > td,
.fc .fc-scrollgrid-section table {
  height: null;
}
::v-deep .fc-media-screen .fc-timegrid-cols > table {
  height: 100%;
}
//隐藏滚动条
::v-deep .fc-scrollgrid-section-header td .fc-scroller-harness .fc-scroller {
  overflow: hidden !important;
}
::v-deep .fc-scrollgrid-section-body td .fc-scroller-harness .fc-scroller {
  overflow: hidden !important;
}
::v-deep 
  .fc-scrollgrid-section-body
  td
  .fc-scroller-harness-liquid
  .fc-scroller-liquid-absolute {
  overflow: hidden scroll !important;
}
</style>
