<!-- social的Analyze -->
<template>
  <div class="socialAnalyze">
    <div class="SAhead">
      <div class="SAheadfirst">
        <!-- 筛选器 -->
        <socialFilter
          class="filter_head"
          :selectoptions="selectoptions"
          :campaignList="campaignList"
          :isShowUser="false"
          @partfromSelect="partfromSelect"
          @changeCampaign="changeCampaign"
        ></socialFilter>
      </div>
      <div class="filterCell">
        <span>{{$t('label.chatter.dateRange')}}: </span>
          <el-date-picker
            size="mini"
            @change="getExData"
            v-model="dateFilter"
            type="daterange"
            :picker-options="pickerOptions"
            :range-separator="'to'"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
      </div>
    </div>
    <div class="barechartdivs">
      <echartbar
        v-loading="exLoding"
        v-if="!exLoding"
        :echartStyle="style"
        :opinion="barName"
        :opinionData="audienceInfo"
        :x="barX"
        :bartitle="bartitle"
        :bardate="bardate"
        :Audienceshow="Audienceshow"
        :popshow="networkshow"
        @getshow="getshow"
        :popif="popshow"
        :popcont="popcont1"
      ></echartbar>
      <echartbar
        v-loading="exLoding"
        v-if="!exLoding"
        :echartStyle="style"
        :opinion="barName"
        :opinionData="publishPostsInfo"
        :x="barX"
        :bartitle="bartitle1"
        :bardate="bardate"
        :Audienceshow="Audienceshow"
        :popshow="publishedshow"
        @getshow="getshow"
        :popcont="popcont2"
      ></echartbar>
    </div>
    <div class="barechartdivs" style="margin-bottom: 10px">
      <echartbar
        v-loading="exLoding"
        v-if="!exLoding"
        :echartStyle="style"
        :opinion="barName"
        :opinionData="interactionsInfo"
        :x="barX"
        :bartitle="bartitle2"
        :bardate="bardate"
        :Audienceshow="Audienceshow"
        :popshow="audienceshow"
        @getshow="getshow"
        :popcont="popcont3"
      ></echartbar>
    </div>
  </div>
</template>

<script>
import echartbar from "@/views/social/analyze/components/echartbar.vue";
import * as marketChannel from "./api";
import socialFilter from "@/views/social/components/socialFilter.vue";
export default {
  components: {
    echartbar,
    socialFilter,
  },
  data() {
    return {
      exLoding: false,
      selectUserDataId: "",
      selectCampaignId: "",
      popshow: false,
      datepickervalue: [], //默认时间今天
      style: { height: "300px", width: "100%" },
      barName: ["Previous period", this.$i18n.t('c106')],
      barX: ["Twitter", "Facebook", "Linkedln"],
      bartitle: this.$i18n.t('c704'),
      bardate: "3/26/2021-4/24/2021",
      Audienceshow: false,
      bartitle1: this.$i18n.t('c705'),
      bartitle2:  this.$i18n.t('c106'),
      networkshow: false,
      publishedshow: false,
      audienceshow: false,
      clickshow: false,
      shareshow: false,
      impressionshow: false,
      contshow: false,
      popcont1: this.$i18n.t('c850'),
      popcont2: this.$i18n.t('c851'),
      popcont3: this.$i18n.t('c853'),
      selectoptions: [
        {
          img: "in",
          // value: "Linkedln accounts",
          value: 'c848',
          key: "linkedIn",
          checked: true,
          accountsList: [],
        },
        {
          img: "facebook_blue",
          // value: "Facebook accounts",
           value: 'c624',
          key: "facebook",
          checked: true,
          accountsList: [],
        },
        {
          img: "twitter_blue",
          // value: "Twitter accounts",
           value: 'c849',
          key: "twitter",
          checked: true,
          accountsList: [],
        },
      ],
      campaignList: [],
      audience: [],
      publishPosts: [],
      interactions: [],
      audienceTitle: [],
      publishPostsTitle: [],
      interactionsTitle: [],
      publishPostsInfo: [],
      audienceInfo: [],
      interactionsInfo: [],
      dateFilter: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: this.$i18n.t('today'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$i18n.t('label.brief.thisweek'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text:    this.$i18n.t('label.notification.last30days'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text:  this.$i18n.t('this.year'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    getExData() {
      this.exLoding = true;
      marketChannel
        .getAnalyze({
          orgid: this.$store.state.userInfoObj.orgId,
          socialmediaids: this.selectUserDataId,
          campaignid: this.selectCampaignId,
          daysstartdate: this.dateFilter ? this.dateFilter[0] : "",
          daysenddate: this.dateFilter ? this.dateFilter[1] : "",
        })
        .then((res) => {
          if (res.result) {
            this.publishPosts[0] = res.data.publishPosts.twitter;
            this.publishPosts[1] = res.data.publishPosts.facebook;
            this.publishPosts[2] = res.data.publishPosts.linkedIn;
            this.audience[0] = res.data.audience.twitter;
            this.audience[1] = res.data.audience.facebook;
            this.audience[2] = res.data.audience.linkedIn;
            this.interactions[0] = res.data.interactions.twitter;
            this.interactions[1] = res.data.interactions.facebook;
            this.interactions[2] = res.data.interactions.linkedIn;
            this.publishPostsTitle = Object.keys(res.data.publishPosts);
            this.audienceTitle = Object.keys(res.data.audience);
            this.interactionsTitle = Object.keys(res.data.interactions);
            this.publishPostsInfo = [
              {
                name: "Published Posts",
                type: "bar",
                barWidth: 34,
                itemStyle: {
                  normal: {
                    color: "#FFA643",
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "black",
                        fontSize: 12,
                      },
                    },
                  },
                },
                data: this.publishPosts,
              },
            ];
            this.audienceInfo = [
              {
                name: "Audience size",
                type: "bar",
                barWidth: 34,
                itemStyle: {
                  normal: {
                    color: "#FFA643",
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "black",
                        fontSize: 12,
                      },
                    },
                  },
                },
                data: this.audience,
              },
            ];
            this.interactionsInfo = [
              {
                name: "Interactions",
                type: "bar",
                barWidth: 34,
                itemStyle: {
                  normal: {
                    color: "#FFA643",
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "black",
                        fontSize: 12,
                      },
                    },
                  },
                },
                data: this.interactions,
              },
            ];
            this.exLoding = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    changeCampaign(id) {
      this.selectCampaignId = id;
      this.getExData();
    },

    // 选取账号
    partfromSelect(partfrom, accounts) {
      this.selectUserDataId = accounts;
      this.getExData();
    },
    init() {
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
        socialtype: "",
      };
      marketChannel.getScoialUserinfo(obj).then((res) => {
        if (res.result) {
          let array = [];
          let linkedInList = [];
          let facebookList = [];
          let twitterList = [];
          res.data.list.forEach((el) => {
            if (el.isauthorization !== "false") {
              array.push(el);
            }
          });
          array.forEach((item) => {
            item.checked = true;
            if (item.socialtype === "linkedIn") {
              linkedInList.push(item);
            } else if (item.socialtype === "facebook") {
              facebookList.push(item);
            } else {
              twitterList.push(item);
            }
          });
          this.selectoptions[0].accountsList = linkedInList;
          this.selectoptions[1].accountsList = facebookList;
          this.selectoptions[2].accountsList = twitterList;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
      marketChannel.getScoialMarketingActivity(obj).then((res) => {
        if (res.result) {
          this.campaignList = res.data;
          this.campaignList.unshift({ name: this.$i18n.t('c625'), id: "" });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    getshow(show, command) {
      if (command === "Networks and accounts") {
        this.popshow = show;
      }
      if (command === this.$i18n.t('704')) {
        this.networkshow = show;
      }
      if (command === this.$i18n.t('705')) {
        this.publishedshow = show;
      }
      if (command === this.$i18n.t('106')) {
        this.audienceshow = show;
      }
      if (command === "Clicks") {
        this.clickshow = show;
      }
      if (command === "Shares") {
        this.shareshow = show;
      }
      if (command === "Impressions") {
        this.impressionshow = show;
      }
      if (command === "leads") {
        this.contshow = show;
      }
    },
  },
  created() {
    this.init();
    let day = new Date().setMonth(new Date().getMonth() - 1); //日期是30天前的对应时间戳
    day = new Date(day); //转换成标准时间
    let y = day.getFullYear(); //年
    let m = day.getMonth() + 1; //月
    let d = day.getDate(); //日
    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }
    day = y + "-" + m + "-" + d;
    let endDay = new Date()
    let ny = endDay.getFullYear(); //年
    let nm = endDay.getMonth() + 1; //月
    let nd = endDay.getDate(); //日
    if (nm < 10) {
      nm = "0" + nm;
    }
    if (nd < 10) {
      nd = "0" + nd;
    }
    endDay = ny + "-" + nm + "-" + nd;
    this.dateFilter[0] = day
    this.dateFilter[1] = endDay
    this.getExData();
    let ndate = new Date();
    this.datepickervalue = [ndate, ndate];
  },
};
</script>
<style lang="scss" scoped>
.el-popover.marketAnalyzePopover {
  background: #ffffff;
  border: 1px solid #dddbda;
  box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
}
</style>
<style lang="scss" scoped>
.socialAnalyze {
  width: 98.5%;
  margin-left: 0.7%;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  letter-spacing: 0;
}
.SAhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72.5px;
  .SAheadfirst {
    display: flex;
    align-items: center;
    width: 30%;
    .select {
      position: relative;
      float: left;
      .elselect {
        position: relative;
        height: 30px;
        .slectp {
          height: 30px;
          background: #ffffff;
          line-height: 30px;
          font-size: 14px;
          color: #080707;
          vertical-align: middle;
          cursor: pointer;
          span {
            padding-left: 5px;
          }
          i {
            padding-right: 5px;
          }
        }
        ul {
          background: #ffffff;
          border: 1px solid #dddbda;
          box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
          position: absolute;
          left: 0;
          top: 40px;
          z-index: 9;
          .triangle {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 15px solid #f3f2f2;
            position: absolute;
            top: -7px;
            left: 15px;
            border-style: solid;
            border-width: 8px;
            border-color: #ffffff #ffffff transparent transparent;
            transform: rotate(-45deg);
            box-shadow: 2px -2px 2px #ccc;
          }
          li {
            width: 189px;
            padding-left: 17px;
            height: 34px;
            margin-top: 2px;
            line-height: 34px;
            vertical-align: middle;
            .icon {
              padding-right: 5px;
            }
          }
          li:hover {
            background: #ececec;
            color: #006dcc;
          }
          .pli {
            color: #006dcc;
            padding-left: 17px;
            cursor: pointer;
            line-height: 34px;
          }
        }
      }
    }
    ::v-deep .allink {
      margin-left: 15px;
      color: #006dcc;
      float: left;
      height: 20px;
      .anlypop {
        width: 160px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
      }
      .el-popover__reference {
        cursor: pointer;
        .icon {
          margin-right: 5px;
        }
        i {
          padding-right: 5px;
        }
      }
    }
    .datepicker {
      float: left;
      margin-left: 10px;
      ::v-deep {
        .el-range-editor.el-input__inner {
          padding: 0;
          cursor: pointer;
          width: 220px;
        }
        .el-input__inner {
          border: 0;
          height: 20px;
          line-height: 20px;
        }
        .el-range-input {
          cursor: pointer;
          color: #006dcc;
        }
        .el-date-editor .el-range__icon {
          line-height: 22px;
          margin: 0;
          width: 20px;
        }
        .el-date-editor .el-range-separator {
          line-height: 20px;
          padding: 0;
        }
        .el-date-editor .el-range-input {
          width: 86px;
        }
        .el-date-editor .el-range__close-icon {
          line-height: 22px;
          width: 20px;
        }
      }
    }
    .checkboxarea {
      display: flex;
      align-items: center;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      letter-spacing: 0;
      color: #000000;
      input {
        margin-right: 10px;
        margin-left: 10px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .filterCell {
    flex: 1;
    padding-left: 80px;
  }
  .SAheadsecond {
    .el-dropdown {
      color: #006dcc;
      outline: none;
    }
  }
}
.Daterange {
  float: left;
  margin-left: 16px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  letter-spacing: 0;
  color: #000000;
  outline: none;
  width: 260px;
  .el-dropdown-link {
    margin-left: 10px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
    outline: none;
  }
}
.barechartdivs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 350px;
}
.newtacts {
  margin-top: 11px;
  height: 560px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dddbda;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  .newtacthead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .newtactleft {
      margin-left: 19px;
      h3 {
        font-size: 16px;
        color: #080707;
        font-weight: bolder;
        margin-top: 18px;
        margin-bottom: 15px;
        position: relative;
      }
      span {
        font-size: 12px;
        color: #888888;
        letter-spacing: 0;
        display: inline-block;
        margin-bottom: 14px;
      }
    }
    .newtactright {
      margin-right: 68px;
      padding-top: 49px;
      font-size: 16px;
      color: #080707;
      font-weight: bolder;
    }
  }
  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #333333;
    font-weight: bolder;
    margin-top: 14px;
  }
}
.topost {
  width: 100%;
  margin-top: 11px;
  background: #ffffff;
  border: 1px solid #dddbda;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 11px;
  .toposthead {
    width: 97%;
    height: 56px;
    margin-left: 1.5%;
    display: flex;
    align-items: center;
    .toppostp {
      font-size: 16px;
      color: #080707;
      font-weight: bolder;
      width: 100px;
      float: left;
      margin-top: 16px;
    }
  }
  .topostconent {
    min-height: 146px;
    width: 97%;
    margin-left: 1.5%;
    background: #ffffff;
    border: 1px solid #dedcda;
    margin-bottom: 11px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .thfirst {
      width: 104px;
      height: 100%;
      display: flex;
      justify-content: center;
      .icon {
        width: 54px;
        height: 35px;
      }
    }
    .thsecond {
      height: 100%;
      width: 329px;
      p {
        font-size: 14px;
        color: #3e3e3c;
        letter-spacing: 0;
      }
      span {
        font-size: 14px;
        color: #888888;
        padding-top: 14px;
      }
    }
    .ththird {
      height: 100%;
      width: 197px;
      dl {
        text-align: center;
        dt {
          .icon {
            width: 63px;
            height: 27px;
          }
        }
        dd {
          font-size: 12px;
          color: #3e3e3c;
          vertical-align: middle;
          .icon {
            width: 12.5px;
            height: 11px;
          }
        }
      }
    }
    .thfourth .thfifth .thsixth .thseventh {
      dl {
        dt {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
