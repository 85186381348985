/*
 * @Author: your name
 * @Date: 2022-02-24 11:42:12
 * @LastEditTime: 2022-03-30 14:53:44
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\social\api.js
 */
import axios from '@/config/httpConfig'
//获取用户列表
export function getUserList() {
    return axios.post('approval/getUserList')
}
// 获取记录的编辑权限
export function getEditPermissions(data) {
    return axios.post('/object/getEditPermissions', data)
}
//获取侧边栏日历列表
export function getCalendarList() {
    return axios.post('/calendar/getCalendarList', {}, {

    })
}
// 获取所有的日历的条目
export function getAllItems(args) {
    return axios.post('/calendar/getAllItems', args)
}
// 获取表单信息
export function getFromDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}
// 修改展示状态
export function getRecType(data) {
    return axios.post('/salesFunnel/getRecordTypes', data)
}
// 查询Social用户信息
export function getScoialUserinfo(data) {
    return axios.post('/social/querySocialUser', data)
}

// 获取第三方信息
export function queryCommentsLikes(data) {
    return axios.post('/social/queryCommentsLikes', data)
}