import axios from '@/config/httpConfig'
// 获取社交媒体Analyze数据
export function getAnalyze(data) {
    return axios.post('/social/getAnalyze', data)
}
// 查询Social用户信息
export function getScoialUserinfo(data) {
    return axios.post('/social/querySocialUser', data)
}
// 查询市场活动信息
export function getScoialMarketingActivity(data) {
    return axios.post('/social/queryCampaign', data)
}  