<!--  -->
<template>
  <div class="top">
    <div class="header">
      <el-button-group style="with: 210px">
        <el-button
          size="mini"
          :type="tapFlg ? 'primary' : ''"
          @click="swicth"
          style="width: 50%"
          ><svg class="icon" aria-hidden="true" v-show="!tapFlg">
            <use href="#icon-zhaiyao"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-show="tapFlg">
            <use href="#icon-liebiao1"></use>
          </svg>
          {{$t('label.chatter.list')}}</el-button
        >
        <el-button
          size="mini"
          @click="swicth"
          :type="tapFlg ? '' : 'primary'"
          style="width: 50%"
        >
          <svg class="icon" aria-hidden="true" v-show="tapFlg">
            <use href="#icon-calendar"></use>
          </svg>
           <svg class="icon" aria-hidden="true" v-show="!tapFlg">
            <use href="#icon-a-rilibai"></use>
          </svg>
          {{$t('label.mobile.quickcreate.default.activities')}}
        </el-button>
      </el-button-group>
      <!-- 筛选器 -->
      <socialFilter
        class="filter_head"
              ref="socialFilter"
        :selectoptions="selectoptions"
        :campaignList="campaignList"
        :userList="userList"
        :isShowUser="!tapFlg?false:true"
        @partfromSelect="partfromSelect"
        @changeCampaign="changeCampaign"
        @changeUser="changeUser"
        @dateChange="dateChange"
      >
      </socialFilter>
    </div>

    <!-- 列表 -->
    <mangeList
      ref="mangeList"
      v-if="tapFlg"
      @detailSoical="detailSoical"
            @clearFilter="clearFilter"
      :selectCampaignId="selectCampaignId"
      :selectUserDataId="selectUserDataId"
      :selectAccountKey="selectAccountKey"
      :userSelectId="userSelectId"
      :enddate="endDate"
      :startdate="startDate"
      @posterEdit="posterEdit"
    ></mangeList>
    <!-- 日历 -->
    <mangeCalen
      v-else
      @detailSoical="detailSoical"
      @newSocial="newSocial"
    ></mangeCalen>
    <!-- 导出提示 -->
    <el-dialog
      :visible.sync="dialogExportVisible"
      :title="'Export all published posts'"
      top="15%"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleCloseDialog"
    >
      <div class="addressesMes">
        You`re get a spreadsheet of all your poulished posts at the email
        addresses of your choice.
      </div>
      <div class="noteMes">
        Please note: This spreadsheet won`t reflect any of the filters in your
        table.
      </div>
      <div>Email</div>
      <el-input size="mini"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExportVisible = false" size="mini">
          <!-- drafts -->
          <!-- Cancel -->
          {{$t('label.emailtocloudcc.button.back')}}
        </el-button>
        <el-button
          type="primary"
          size="mini"
          @click="dialogExportVisible = false"
        >
          <!-- changes -->
          Export
        </el-button>
      </span>
    </el-dialog>
    <!-- 链接网络 -->
    <!-- <el-dialog
      :visible.sync="dialogVisible"
      :title="'Select the network to link to'"
      top="15%"
      width="40%"
      :modal="false"
      :close-on-click-modal="true"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="createPage">
        <div class="linkTitle">
          You haven't authorized any social meida account. Please Authorize your
          social media account on the right
        </div>
        <div class="pisc">
          <div class="imgBox" @click="linkAcount('linkedIn')">
            <svg
              class=""
              aria-hidden="true"
              style="width: 30px; height: 30px; margin-left: 10px"
            ><use href="#icon-in"></use></svg>
            <h4 class="ml-10">LinkedIn</h4>
          </div>
          <div class="imgBox" @click="linkAcount('facebook')">
            <svg
              class=""
              aria-hidden="true"
              style="width: 30px; height: 30px; margin-left: 10px"
            ><use href="#icon-facebook_white"></use></svg>
            <h4 class="ml-10">Facebook</h4>
          </div>
          <div class="imgBox" @click="linkAcount('twitter')">
            <svg
              class=""
              aria-hidden="true"
              style="width: 30px; height: 30px; margin-left: 10px"
            ><use href="#icon-twitter_white"></use></svg>
            <h4 class="ml-10">Twitter</h4>
          </div>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import mangeList from "@/views/social/manage/mangeList.vue";
import mangeCalen from "@/views/social/manage/mangeCalen.vue";
import * as marketChannel from "./api";
import socialFilter from "@/views/social/components/socialFilter.vue";
import {getUserList} from "../api.js";
export default {
  components: {
    mangeList,
    mangeCalen,
    socialFilter,
  },
  data() {
    return {
      accountOpen: {},
      dialogVisible: false,
      userSelectId: "", //cloudccuser 选取id
      userList: "", //cloudccUser 用户
      selectAccountKey: "",
      // 时间
      endDate: "",
      startDate: "",
      Daterange: "Last 30 days",
      selectCampaignId: "",
      selectUserDataId: "",
      campaignList: [],
      
      all: this.$i18n.t('c626'),// "All users",
      
      datepickervalue: [], //默认时间今天
      selectoptions: [
        {
          img: "in",
          // value: "Linkedln accounts",
          value: 'c848',
          key: "linkedIn",
          checked: true,
          accountsList: [],
        },
        {
          img: "facebook_blue",
          // value: "Facebook accounts",
           value: 'c624',
          key: "facebook",
          checked: true,
          accountsList: [],
        },
        {
          img: "twitter_blue",
          // value: "Twitter accounts",
           value: 'c849',
          key: "twitter",
          checked: true,
          accountsList: [],
        },
      ],
      ulshow: false,
      // checkAll: true,
      // checkedCities: ["Gon CloudBB", "CloudCC Inc."],
      // cities: [
      //   {
      //     img: "",
      //     value: "Gon CloudBB",
      //   },
      //   {
      //     img: "",
      //     value: "CloudCC Inc.",
      //   },
      // ],
      // isIndeterminate: false,
      // allnum: 2,
      // alllinked: true,
      // allconten: {},
      //筛选器数据
      dialogExportVisible: false,
      tapFlg: true,
      // checkbox: true,
    };
  },
  //方法集合
  methods: {
        //清空筛选器
    clearFilter(){
      this.selectUserDataId = "", //cloudccuser 选取id
      this.selectCampaignId= "", //cloudccUser 用户
      this.selectAccountKey = "",
      this.userSelectId = ""
      // 时间
      this.endDate = "",
      this.startDate = ""
      this.$refs.socialFilter.clearFilter()
    },
    handleCloseDialog(){
    },
    // 接收第三方数据授权
    receiveMessage(event) {
      if (this.accountOpen !== {}) {
        this.accountOpen.close&&this.accountOpen.close();
        let socialDataList = [];
        event.data.accountList.forEach((event) => {
          if (event.issocialauth) {
            socialDataList.push(event);
          }
        });
        marketChannel
          .storeCacheAccessToken({
            orgid: this.$store.state.userInfoObj.orgId,
            socialtype: event.data.socialName,
            socialdata: socialDataList,
          })
          .then((res) => {
            if (res.result) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t('c46'),
              });
              this.dialogVisible = false;
              this.initTableDota();
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
        this.init()
        this.$emit('changUser')
      }
    },
    
    linkAcount(type) {
      marketChannel
        .authorizationUrl({
          orgid: this.$store.state.userInfoObj.orgId,
          socialtype: type,
        })
        .then((res) => {
          if (res.result) {
            this.accountOpen = window.open(
              res.data,
              "登录",
              "status=no,toolbar=no,menubar=no,location=no,resizable=no,titlebar=no"
            );
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    dateChange(dateList) {
      if (dateList) {
        let yearStart = dateList[0].getFullYear();
        let monthStart = dateList[0].getMonth() + 1;
        let dayStart = dateList[0].getDate();
        let yearEnd = dateList[1].getFullYear();
        let monthEnd = dateList[1].getMonth() + 1;
        let dayEnd = dateList[1].getDate();
        this.startDate =
          yearStart +
          "-" +
          (monthStart < 10 ? "0" + monthStart : monthStart) +
          "-" +
          (dayStart < 10 ? "0" + dayStart : dayStart);
        this.endDate =
          yearEnd +
          "-" +
          (monthEnd < 10 ? "0" + monthEnd : monthEnd) +
          "-" +
          (dayEnd < 10 ? "0" + dayEnd : dayEnd);
      } else {
        this.startDate = "";
        this.enddate = "";
      }
      this.$nextTick(() => {
        this.refreshList();
      });
    },
    changeUser(id) {
      this.userSelectId = id;
      this.$nextTick(() => {
        this.refreshList();
      });
    },
    changeCampaign(id) {
      this.selectCampaignId = id;
      this.$nextTick(() => {
        this.refreshList();
      });
    },
    partfromSelect(partfrom, accounts) {
      this.selectUserDataId = accounts;
      this.$nextTick(() => {
        this.refreshList();
      });
    },
    // 刷新列表
    
    refreshList() {
      this.$refs.mangeList.refreshList();
    },
    posterEdit(row, flg) {
      //flg 为0 时编辑状态。为1 克隆状态
      
      if(flg === 0){
        this.$emit("openPost",'edit',row);
      }else if(flg ===1){
        this.$emit('openPost','clone',row)
      }else if(flg ===2){
        this.$emit('openPost','reschedule',row)
      }else if(flg === 3){
        this.$emit('openPost','draft',row)
      }
    },
    init() {
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
        socialtype: this.selectAccountKey,
      };
      marketChannel.getScoialUserinfo(obj).then((res) => {
        if (res.result) {
          if (res.data.list && res.data.list.length !== 0) {
            let linkedInList = [];
            let facebookList = [];
            let twitterList = [];
            let array = [];
            res.data.list.forEach((element) => {
              if (element.isauthorization !== "false") {
                array.push(element);
              }
            });
            if(array.length === 0) {
              this.dialogVisible = true;
            }else{
              array.forEach((item) => {
              item.checked = true;
              if (item.socialtype === "linkedIn") {
                linkedInList.push(item);
              } else if (item.socialtype === "facebook") {
                facebookList.push(item);
              } else {
                twitterList.push(item);
              }
            });
            }
            this.selectoptions[0].accountsList = linkedInList;
            this.selectoptions[1].accountsList = facebookList;
            this.selectoptions[2].accountsList = twitterList;
            this.$emit('changoptions',this.selectoptions )
          } else {
            // this.dialogVisible = true;
          }
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
      marketChannel.getScoialMarketingActivity(obj).then((res) => {
        if (res.result) {
          this.campaignList = res.data;
          this.campaignList.unshift({ name: "All Campagin", id: "" });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
     getUserList().then((res) => {
        if (res.result) {
          this.userList = res.data;
          this.userList.unshift({ name: "All User", id: "" });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    
    handleCommand(command) {
      this.Daterange = command;
      this.getformdates(command);
      if (command !== "Custom time period") {
        this.$nextTick(() => {
          this.$refs.mangeList.getPostInfo();
        });
      } else {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var nowDate =
          year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day);
        this.datepickervalue = [nowDate, nowDate];
      }
    },
    //获取时间范围
    getformdates(time) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var nowDate =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);
      if (time == "Today") {
        this.startdate = nowDate;
        this.enddate =
          year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day);
      }
      let lastDate = "";
      if (time == "This week") {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 7);
      }
      if (time == "Last 30 days") {
        lastDate = new Date(date - 1000 * 60 * 60 * 24 * 30);
      }
      if (time == "This year") {
        this.startdate = year + "-01-01";
        this.enddate = nowDate;
      }
      if (time == "All time") {
        this.startdate = "";
        this.enddate = "";
      }
      if (lastDate) {
        var lastY = lastDate.getFullYear();
        var lastM = lastDate.getMonth() + 1;
        var lastD = lastDate.getDate();
        var lastdate =
          lastY +
          "-" +
          (lastM < 10 ? "0" + lastM : lastM) +
          "-" +
          (lastD < 10 ? "0" + lastD : lastD);
        this.startdate = lastdate;
        this.enddate = nowDate;
      }
    },
    // 选择筛选
    // handleCheckedCitiesChange(value) {
    //   let checkedCount = value.length;
    //   if (checkedCount == 1) {
    //     this.alllinked = false;
    //     for (let i = 0; i < this.cities.length; i++) {
    //       if (this.cities[i].value == value[0]) {
    //         this.allconten = this.cities[i];
    //       }
    //     }
    //   } else {
    //     this.alllinked = true;
    //     this.allnum = checkedCount;
    //   }
    //   this.allnum = checkedCount;
    //   this.checkAll = checkedCount === this.cities.length;
    //   this.isIndeterminate =
    //     checkedCount > 0 && checkedCount < this.cities.length;
    // },
    // 下拉切换
    // handleCheckAllChange(val) {
    //   this.checkedCities = [];
    //   if (val) {
    //     for (let i = 0; i < this.cities.length; i++) {
    //       this.checkedCities.push(this.cities[i].value);
    //     }
    //   }
    //   this.isIndeterminate = false;
    //   this.allnum = this.checkedCities.length;
    // },
    
    handlecomm(command) {
      this.type = command.name;
      this.selectCampaignId = command.id;
      this.$nextTick(() => {
        this.$refs.mangeList.getPostInfo();
      });
    },
    
    handleallcomm(command) {
      this.all = command.socialmedianame;
      this.selectUserDataId = command.socialmediaid;
      this.$nextTick(() => {
        this.$refs.mangeList.getPostInfo();
      });
    },
    // liclick(item) {
    //   this.selectvalue.img = item.img;
    //   this.selectvalue.value = item.value;
    //   // 选择平台
    //   this.selectAccountKey = item.key;
    //   this.init();
    //   this.ulshow = false;
    //   this.isIndeterminate = false;
    //   this.checkAll = true;
    //   this.checkedCities = ["Gon CloudBB", "CloudCC Inc."];
    //   (this.alllinked = true), (this.allnum = this.checkedCities.length);
    //   this.$nextTick(() => {
    //     this.$refs.mangeList.getPostInfo();
    //   });
    // },
    // 选择筛选
    newSocial(op) {
      this.$emit("newSocial", op);
    },
    detailSoical(id) {
      this.$emit("detailSoical", id);
    },
    // handleClose() {
    //   this.dialogExportVisible = false;
    // },
    //选择初始发布平台
    //切换
    swicth() {
      this.tapFlg = !this.tapFlg;
      // 修改浏览器标题
      document.title =this.$i18n.t('UG.Lead.003')
    },
    //分页
    
    handleSizeChange(val) {
      this.pagesize = val;
      this.showpage();
    },
    
    handleCurrentChange(val) {
      this.currentPage = val;
      this.showpage();
    },
    
    createSocial() {
      this.$refs.newSocial.newSocial();
    },
    clickFn(e) {
      // 记得在.select-box那边加上ref="selectBox"
      let select = this.$refs.select;
      // 重点来了：selectBox里是否包含点击的元素，不包含点击的元素就隐藏面板
      if (select) {
        if (!select.contains(e.target)) {
          this.ulshow = false;
        }
      }
    },
  },
  mounted() {
    this.init()
    //跨域消息
    window.addEventListener("message", this.receiveMessage, false);
    document.addEventListener("click", this.clickFn);
  },
  created() {
    this.init();
    this.getformdates(this.Daterange);
  },
  beforeDestroy(){
    window.removeEventListener('message',this.receiveMessage, false)
    document.removeEventListener("click", this.clickFn);
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__wrapper {
 width: 100%;
 left: 0px;
 top: 95px;
 bottom:0px;
 right:0px;
 background:rgba(0, 0, 0, 0.4)
}
.el-dialog {
 margin:0;
 width:500px;
}
::v-deep .el-button-group {
  width: 210px;
}
.filter_head {
  width: 82%;
  margin-left: 20px;
}
.icon {
  display: inline-block;
}
::v-deep .el-dropdown-menu {
  overflow: auto;
  max-height: 200px;
}
.Daterange {
  float: left;
  margin-left: 16px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  letter-spacing: 0;
  color: #000000;
  outline: none;

  .el-dropdown-link {
    margin-left: 10px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
  }
}
.SAhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72.5px;
  width: 82%;
  border-radius: 4px;
  .inputcheckbox {
    margin-left: 10px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    input {
      margin-right: 10px;
    }
  }
  .SAheadfirst {
    display: flex;
    align-items: center;
    .select {
      position: relative;
      float: left;
      .elselect {
        position: relative;
        height: 30px;
        .slectp {
          border-radius: 3px;
          height: 30px;
          background: #ffffff;
          line-height: 30px;
          font-size: 14px;
          color: #080707;
          vertical-align: middle;
          cursor: pointer;
          .icon {
            margin-left: 5px;
          }
          span {
            padding-left: 5px;
          }
          i {
            padding-right: 5px;
          }
        }
        ul {
          background: #ffffff;
          border: 1px solid #dddbda;
          box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
          position: absolute;
          left: 0;
          top: 40px;
          z-index: 9;
          .triangle {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 15px solid #f3f2f2;
            position: absolute;
            top: -7px;
            left: 15px;
            border-style: solid;
            border-width: 8px;
            border-color: #ffffff #ffffff transparent transparent;
            transform: rotate(-45deg);
            box-shadow: 2px -2px 2px #ccc;
          }
          li {
            width: 189px;
            padding-left: 17px;
            height: 34px;
            margin-top: 2px;
            line-height: 34px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            .icon {
              padding-right: 5px;
              width: 20px;
              height: 20px;
            }
          }
          li:hover {
            background: #ececec;
            color: #006dcc;
          }
          .pli {
            color: #006dcc;
            padding-left: 17px;
            cursor: pointer;
            line-height: 34px;
          }
        }
      }
    }
    ::v-deep .allink {
      margin-left: 15px;
      color: #006dcc;
      float: left;
      height: 20px;
      .anlypop {
        width: 160px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
      }
      .el-popover__reference {
        cursor: pointer;
        img {
          margin-right: 5px;
        }
        i {
          padding-right: 5px;
        }
      }
    }
    .datepicker {
      float: left;
      margin-left: 10px;
      ::v-deep {
        .el-range-editor.el-input__inner {
          padding: 0;
          cursor: pointer;
          width: 220px;
        }
        .el-input__inner {
          border: 0;
          height: 20px;
          line-height: 20px;
        }
        .el-range-input {
          cursor: pointer;
          color: #006dcc;
        }
        .el-date-editor .el-range__icon {
          line-height: 22px;
          margin: 0;
          width: 20px;
        }
        .el-date-editor .el-range-separator {
          line-height: 20px;
          padding: 0;
        }
        .el-date-editor .el-range-input {
          width: 86px;
        }
        .el-date-editor .el-range__close-icon {
          line-height: 22px;
          width: 20px;
        }
      }
    }
  }
  .SAheadsecond {
    .el-dropdown {
      color: #006dcc;
      outline: none;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  height: 75px;
  .el-button {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
    img {
      width: 13px;
      height: 10px;
      margin: 1px 5px 3px 0px;
    }
  }
}
.top {
  width: 98.5%;
  margin: 0px auto;
}
::v-deep .el-dialog__body {
  padding: 30px 36px 50px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  line-height: 24px;
  .addressesMes {
    width: 70%;
  }
  .noteMes {
    height: 70px;
    line-height: 70px;
  }
}
.createPage {
  display: flex;
  justify-content: space-around;
  .linkTitle {
    width: 490px;
    font-weight: bold;
    font-size: 16px;
  }
}
.pisc {
  overflow: auto;
  height: 200px;
  width: 420px;
  .imgBox {
    width: 50%;
    height: 50px;
    border: 1px solid #dedede;
    display: flex;
    cursor: pointer;
    justify-content: left;
    align-items: center;
    margin-bottom: 10px;
    .img {
      margin-left: 10px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
