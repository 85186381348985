<template lang="html">
  <div class="editor">
    <!-- <div ref="editor" class="text" v-model="contentsProp"></div> -->
    <div ref="editor" class="text"></div>
    <div class="editBox"> 
        <div ref="toolbar" class="toolbar" ></div>
        <div class="audience"> </div>    
    </div>  
  </div>
</template>

<script>
export default {
  name: "editoritem",
  data() {
    return {
      isFocus: false, // 富文本获取焦点
      editorContent: "",
      editor: {},
      E: window.wangEditor,
      contentsProp: this.contents,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    contents: {
      type: String,
    },
  },
  mounted() {
    this.seteditor();
    this.editor.txt.html(this.value);
  },
  methods: {
    // 模板插入
    
    inserthtml(val) {
      if (this.isFocus) {
        // 富文本获取了焦点
        // 在光标位置插入文字
        this.editor.cmd.do("insertHTML", val);
      } else {
        let valstr = val + this.editor.txt.html();
        this.clearvalue();
        this.editor.txt && this.editor.txt.append(valstr);
        this.editorContent = this.editor.txt.html();
        this.isFocus = true;
      }
    },
    seteditor() {
      let that = this;
      this.editor = new this.E(this.$refs.toolbar, this.$refs.editor);
      this.editor.config.onchange = () => {
        this.editorContent = this.editor.txt.html();
      };
      // 富文本获取焦点
      this.editor.config.onfocus = function () {
        that.isFocus = true;
      };
      this.editor.config.uploadImgShowBase64 = true; // base 64 存储图片
      // this.editor.config.uploadImgServer = fileCKUploadImg // 配置服务器端地址
      // this.editor.config.uploadFileName = 'file'
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      this.editor.config.zIndex = 1;
      // 取消自动 focus
      this.editor.config.focus = false
      // 配置菜单
      this.editor.config.menus = [
        // 'image', // 插入图片
        // 'video', // 插入视频
        "link", // 插入链接
        "emoticon", // 表情
      ];
      //       this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      //       
      //     // resultFiles 是 input 中选中的文件列表
      //     // insertImgFn 是获取图片 url 后，插入到编辑器的方法

      //     // 上传图片，返回结果，将图片插入到编辑器中
      //     insertImgFn(imgUrl)
      // }
      // this.editor.config.uploadImgHooks = {
      //   fail: (xhr, editor, result) => {
      //     // 插入图片失败回调
      //   },
      //   success: (xhr, editor, result) => {
      //     // 图片上传成功回调
      //   },
      //   timeout: (xhr, editor) => {
      //     // 网络超时的回调
      //   },
      //   error: (xhr, editor) => {
      //     // 图片上传错误的回调
      //   },
      //   customInsert: (insertImg, result, editor) => {
      //     // 图片上传成功，插入图片的回调
      //     //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
      //     //
      //     //insertImg()为插入图片的函数
      //     //循环插入图片
      //     // for (let i = 0; i < 1; i++) {
      //     //
      //     // let url = "http://otp.cdinfotech.top" + result.url;
      //     // insertImg(url);
      //     // }
      //   },
      // }

      // 创建富文本编辑器
      this.editor.create();
    },

    //清空副文本编辑器的值
    
    clearvalue() {
      this.editor.txt.clear();
    },
    //是否为回复邮件
    
    beforeEditorEdit(params) {
      let value = this.editor.txt.html();
      let isreply = value.indexOf(
        "<p>------------------------原文内容-------------------------</p>"
      );
      if (isreply != -1) {
        // 不是回复邮件
        this.clearvalue();
        this.signContent(params + value);
      } else {
        this.signContent(params);
      }
    },
    
    signContent(val, flag) {
      // flag:true   表示使用模板
      let div = `<div>${val}</div>`;
      if (flag) {
        this.inserthtml(div);
      } else {
        this.editor.txt && this.editor.txt.append(div);
      }
    },
    setNewValue(val) {
      this.$nextTick(() => {
        this.editor.txt.html(val);
      });
    },
    
    wangEditorese(val) {
      // 要求原文内容可以编辑
      let div = `
       <div>
        <div style="width: 100%;min-height:150px;"></div>
        <div
          contenteditable="true"
          style="width: 100%;text-align:left;padding-left: 15px;"
        >
          <p>------------------------原文内容-------------------------</p>
          <p>${this.$i18n.t("label.senduser")}:${val.detail.fromaddress}</p>
          <p>${this.$i18n.t("component.sendmail.label.touser")}:${
        val.detail.toaddress
      }</p>
          <p ${val.detail.ccaddress === "" ? false : true}>${this.$i18n.t(
        "label.mobile.phone.cc.people"
      )}:${val.detail.ccaddress}</p>
          <p>密送人:${
            val.detail.bccaddress === "" || val.detail.bccaddress === undefined
              ? ""
              : val.detail.bccaddress
          }</p>
          <div style="letter-spacing: 0px;
    text-align: justify;
    white-space: pre-line">${val.detail.htmlbody}</div>
        </div>
      </div>`;
      this.editor.txt && this.editor.txt.append(div);
    },
  },
  watch: {
    // isClear(val) {
    //   // 触发清除文本域内容
    //   if (val) {
    //     this.editor.txt.clear();
    //     this.info_ = null;
    //   }
    // },
    // value: function (value) {
    //   if (value !== this.editor.txt.html()) {
    //     this.editor.txt.html(this.value);
    //   }
    // },
    //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
    content(val) {
      this.editor.txt.html(val);
    },
    editorContent(val) {
      if (typeof val == "string") {
        this.$emit("editorContent", val);
      }
      if(val == ""){
        this.editor.txt.html(`<span></span>`);
      }
    },
    contentsProp: {
      handler(newVal) {
        this.$nextTick(() => {
          this.setNewValue(newVal);
          this.$emit("editorContent", newVal);
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.editBox {
  display: flex;
  justify-content: space-between;
  .audience {
    // width: 550px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #888888;
    letter-spacing: 0;
    float: right;
    padding-top: 6px;
    .previewBt {
      width: 100px;
      height: 20px;
      border-radius: 3px;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #006dcc;
      letter-spacing: 0;
      text-align: center;
      margin-right: 10px;
    }
  }
}

.editor {
  width: 100%;
  margin: 0 auto;
  // position: relative;
}
::v-deep .toolbar {
  position: relative;
  flex-wrap: wrap;
  height: 40px;
  display: flex;
  align-items: center;
  width: 150px;
  .w-e-menu {
    font-size: 12px !important;
    padding: 0px 6px !important;
    z-index: auto !important;
  }
}
::v-deep .text {
  min-height: 20px !important;
  overflow: auto;
  .w-e-text {
    height: 95% !important;
    overflow: visible !important;
  }
}
</style>
<style scoped>
.editor >>> .w-e-panel-container {
  z-index: 3;
  position: absolute;
  left: 150px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #ccc;
  color: #333;
  background-color: #fff;
}
.editor >>> .w-e-text-container {
  z-index: 1 !important;
  position: static;
}
.editor >>> .w-e-toolbar {
  flex-wrap: wrap;
}
.editor >>> .w-e-menu {
  z-index: auto !important;
}
.editor >>> .w-e-droplist {
  z-index: 3 !important;
}
</style>
