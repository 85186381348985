<!--  -->
<template>
  <div :class="popif&&poptitle=='Audience'  ? 'popabso' :'popover'">
    <div class="triangle"></div>
    <div class="pophead">
        <span class="span">{{poptitle}}</span>
        <span class="el-icon-close"  @click="handclose"></span>
    </div>
    <p class="popp">{{popcont}}</p>
  </div>
</template>

<script>
export default {
   props: { 
    poptitle: {   //标题文本
      type: String,
      default: ''
    },
    popcont: {   //标题文本
      type: String,
      default: ''
    },
    popif:{
        type: Boolean,
        default() {}
    }
  },
  data () {
    return {
    }
  },
  methods:{
    handclose(){
      this.$emit('func',false,this.poptitle)
    }
  }
}
</script>

<style lang='scss' scoped >
.popabso{
   width: 378px;
  font-family: SourceHanSansCN-Bold;
  font-size: 14px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #DDDBDA;
  box-shadow: 0 8px 13px 3px rgba(0,0,0,0.20);
  border-radius: 3px;
  left: -20px;
  top: 130px;
  z-index: 99;
}
.popover{
  width: 378px;
  font-family: SourceHanSansCN-Bold;
  font-size: 14px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #DDDBDA;
  box-shadow: 0 8px 13px 3px rgba(0,0,0,0.20);
  border-radius: 3px;
  left: 0;
  top: 40px;
  z-index: 99;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent ;
  border-bottom: 15px solid #F3F2F2;
  position: absolute;
  top: -7px;
  left: 20px;
  border-style: solid;
  border-width: 8px;
  border-color: #F3F2F2 #F3F2F2 transparent transparent;
  transform: rotate(-45deg);
  box-shadow: 2px -2px 2px #ccc;
}
.pophead{
  width: 100%;
  height: 50px;
  background: #F3F2F2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .span{
    color: #3E3C3C;
    display: inline-block;
    margin-left: 36px;
    font-weight: bold;
  }
  span{
    font-size: 14px;
    display: inline-block;
    margin-right: 18px;
    cursor: pointer;
  }
}
.popp{
  width: 306px;
  margin-left: 36px;
  margin-top: 30px;
  margin-bottom: 31px;
  color: #080707;
  font-weight: normal;
  font-family: MicrosoftYaHei;
}

</style>
