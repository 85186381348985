<!--  -->
<template>
  <div class="creatname">
      <div class="creatleft">
        <h6>{{twitterid}}</h6>
        <p>Stream name</p>
        <input type="text" placeholder="Example:CloudCC Contacts">
        <p>Show me tweets from</p>
        <el-select v-model="twwetfrom">
            <el-option
            v-for="item in twwetfroms"
            :key="item"
            :label="item"
            :value="item">
            </el-option>
        </el-select>
        <p>Twitter Account</p>
        <el-select v-model="twwetaccount">
            <el-option
            v-for="item in twwetaccounts"
            :key="item"
            :label="item"
            :value="item">
            </el-option>
        </el-select>
        <div class="twetlist"  v-show="twwetfrom=='A Twitter List'">
            <p>Twitter List</p>
            <el-select v-model="twitterlist">
                <el-option
                v-for="item in twitterlists"
                :key="item"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
        </div>
        <div v-show="twwetfrom=='A CloudCC conent List'">
            <p>CloudCC Conent list</p>
            <el-select v-model="conentlist">
                <el-option
                v-for="item in conentlists"
                :key="item"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
        </div>
        <p>That contain</p>
        <input type="text" v-model="thatconyain" placeholder="Use commas or tabs to separate words">
        <p>Notify me of matches by</p>
        <div class="checkdiv">
            <input type="checkbox" v-model="checkbox"  @click="check" ><span>Email</span> 
        </div>
        <div class="emaildiv" v-show="checkbox">
            <p>Notification frequency</p>
            <el-select v-model="frequency">
                <el-option
                v-for="item in frequencies"
                :key="item"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
            <p>Notify users</p>
            <el-select v-model="user">
                <el-option
                v-for="item in users"
                :key="item"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
        </div>
        <p class="adoption"  @click="advancedclick" ref="advanc"><i :class="!advancefshow ? 'el-icon-arrow-right' : 'el-icon-arrow-down'" ></i> Advanced options</p>
        <div class="advanoption" v-show="advancefshow">
            <p>Required keywords</p>
            <input type="text" placeholder="Use commas or tabs to separate words"  v-model="keywords">
            <p>Excluded keywords</p>
            <input type="text" placeholder="Use commas or tabs to separate words" v-model="excluded"> 
            <p>Hide tweets from these accounts</p>
            <input type="text" placeholder="Use commas or tabs to separate words" v-model="hidetweet">
            <p>Written in</p>
            <input type="text" placeholder="Select a language" v-model="writtenin">
            <div class="checkdiv  lgnore">
                <input type="checkbox" v-model="checkboxlgnore"  ><span>lgnore Retweets</span> 
            </div>
        </div>
        <div class="foot">
            <el-button disabled>Cancel</el-button>
            <el-button  type="primary"  @click="save">Save</el-button>
        </div>
      </div>
      <div class="creatright" >
          <p>Stream preview</p>
          <div class="input">
              
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: { 
    twitterid: {   //twitter流id
        type: String,
        default: ''
    },
    // activeNum: {   //收件箱流类型的数量
    //   type: Number,
    //   default:''
    // },
    },
 data () {
    return {
        twwetfrom:"All of Twitter",//twwet from 
        twwetfroms:['All of Twitter','A Twitter List','A CloudCC conent List'],//twwet from 方式
        twitterlist:"Select a list",//A Twitter List 下值
        twitterlists:["aa","bb","cc"],//A Twitter List 下选项
        conentlist:"Select a list",//A CloudCC conent List的值
        conentlists:["qq",'www','eee'],//A CloudCC conent List的选项
        twwetaccount:"@Cloudcc_CRM",//Twitter Account 的值
        twwetaccounts:["@Cloudcc_CRM","@Clouddd_CRM"],//Twitter Account选项
        thatconyain:"",
        checkbox:false,//email 多选框
        checkboxlgnore:false,//lgnore 多选框
        advancefshow:false,//Advanced options 显示
        keywords:"",//Advanced options下keywords
        excluded:"",//Advanced options下excluded
        hidetweet:"",//Advanced options下hidetweet
        writtenin:"",//Advanced options下writtenin
        frequency:'am',//email 下Notification frequency值
        frequencies:['am','bm','em'],//email 下Notification frequency选项
        user:'Search',//email 下Notify users的值
        users:['afdevg','awsdfd','poksqm'],//email 下Notify users的选项
    }
  },
  methods:{
      check(){
          
      },
      advancedclick(){
        this.advancefshow=!this.advancefshow;
        let targetbox= this.$refs.advanc.offsetTop;  
        document.body.scrollTop = targetbox;
      },
      save(){
          this.$emit("func",false)
      }
  }
}
</script>

<style lang='scss' scoped >
.creatname{
    width: 98.5%;
    margin-left: 0.7%;
    font-family: SourceHanSansCN-Bold;
    letter-spacing: 0;
    display: flex;
    justify-content: space-between;
    input{
      outline: none;
      border: none;
      border: 1px solid #DDDBDA;
    }
    .creatleft{
        width: 31.7%;
        margin-bottom: 50px;
        h6{
            font-size: 16px;
            color: #333333;
            font-weight: bolder;
            margin-top: 23px;
        }
        p{
            font-size: 14px;
            color: #333333;
            margin-top: 20px;
            margin-bottom: 10px;
            .el-icon-arrow-right {
                color: #005FB2;
                padding-right: 7px;
                font-weight: bolder;
            }
            .el-icon-arrow-down {
                color: #005FB2;
                padding-right: 7px;
                font-weight: bolder;
            }
        }
        .el-select{
            width: 100%;
            height: 40px;
        }
        input{
           height: 40px;
           width: 100%;
           padding-left: 10px;
        }
        .checkdiv{
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;
            input{
                width: 19px;
                height: 19px;
                margin-right: 5px;
            }
        }
        .lgnore{
            margin-top: 16px;
           
        }
        .adoption{
            cursor: pointer;
        }
        .foot{
             margin-top: 45px;
        }
    }
    .creatright{
        width: 64.6%;
        p{
            font-size: 14px;
            color: #333333;
            margin-top: 59px;
            margin-bottom: 10px;
        }
        .input{
            width: 100%;
            height: 631px;
            background: #FFFFFF;
            border-radius: 2px;
        }
    }
}
</style>
