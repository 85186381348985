<template>
  <div class="">
    <div class="header">
      <div class="weeksType">
        <el-button-group>
          <el-button
            size="mini"
            @click="handleChangeView(3)"
            :style="activeViewIndex === 3 ? 'background: #ecf5ff' : ''"
            style="width: 70px"
            >{{$t('label.login.access.month')}}</el-button
          >
          <el-button
            size="mini"
            @click="handleChangeView(2)"
            :style="activeViewIndex === 2 ? 'background: #ecf5ff' : ''"
            style="width: 70px"
            >{{$t('label.week')}}
          </el-button>
          <el-button
            size="mini"
            @click="handleChangeView(1)"
            :style="activeViewIndex === 1 ? 'background: #ecf5ff' : ''"
            style="width: 70px"
            >Day
          </el-button>
        </el-button-group>
        <el-button size="mini" class="module" @click="changeDate(0)"
          >{{$t('today')}}</el-button
        >
        <el-checkbox
          class="module"
          v-show="activeViewIndex === 2"
        ></el-checkbox>
        <span class="module" v-show="activeViewIndex === 2">
          Hide weekends</span
        >
      </div>
      <div class="time-show">{{ showDateText }}</div>
      <div class="nextBt">
        <el-button
          size="mini"
          icon="el-icon-arrow-left"
          @click="changeDate(-1)"
        ></el-button>
        <el-button
          size="mini"
          icon="el-icon-arrow-right"
          @click="changeDate(1)"
        ></el-button>
      </div>
    </div>

    <div class="calendar-box">
      <div class="calendar-content">
        <div
          class="calendar-view-box"
          @click="handleClick"
          :style="{ width: '100%' }"
        >
          <daily
            ref="dateView"
            @goItemDetail="toItemDetail"
            @headerDate="changeHeaderDate"
            v-if="activeViewIndex == 1"
            :showDialog.sync="dialogShow"
            @openNewEvent="getEventDate"
            @detailSoical="detailSoical"
            @updateDialogStatus="updateDialogStatus"
          ></daily>
          <week
            ref="dateView"
            @goItemDetail="toItemDetail"
            @headerDate="changeHeaderDate"
            v-if="activeViewIndex == 2"
            :showDialog.sync="dialogShow"
            @openNewEvent="getEventDate"
            @detailSoical="detailSoical"
            @updateDialogStatus="updateDialogStatus"
          ></week>
          <month
            ref="dateView"
            @goItemDetail="toItemDetail"
            @headerDate="changeHeaderDate"
            v-if="activeViewIndex == 3"
            :showDialog.sync="dialogShow"
            @openNewEvent="getEventDate"
            @detailSoical="detailSoical"
            @updateDialogStatus="updateDialogStatus"
          ></month>
          <available
            ref="dateView"
            @goItemDetail="toItemDetail"
            @headerDate="changeHeaderDate"
            v-if="activeViewIndex == 0"
            :showDialog.sync="dialogShow"
            @openNewEvent="getEventDate"
            @updateDialogStatus="updateDialogStatus"
          ></available>
        </div>

        <div class="calendarmin-box calendar-view-aside">
          <calendarmin
            @gotoDate="goDate"
            @refreshView="freshView"
            @getOnlyCalendar="getOnlyCalendar"
            @giveTip="giveTips"
            :fromViewDate="toAcalendarDate"
            :class="[this.eventboxshow ? 'calendarmin' : 'calendarmin1']"
          ></calendarmin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import daily from "./CalendarView/Daily";
import week from "./CalendarView/Week";
import month from "./CalendarView/Month";
import available from "./CalendarView/Available";
// import { getRecType } from "../api.js";
import * as types from "@/store/mutations-types";
export default {
  data() {
    return {
      // firstDate: "Apr 18, 2021",
      // lastDate: "Apr 24, 2021",
      onlyCalendar: {},
      routeData: {},
      // dialogNewAttr: {
      //   // 新建编辑
      //   title: this.$i18n.t("label.new"),
      //   type: "NEW",
      //   isShow: false,
      //   dialogWidth: "85%",
      //   id: "",
      // },
      // relatedArr: {},
      // 切换视图相关
      toDay: "", // 今天的日期
      activeDay: "", // 跳转的日期
      toMon: "", // 跳转的月
      activeMon: "", // 跳转的月
      dialogShow: false, // 试图组件弹窗显示
      toAcalendarDate: "", // 传递给小日历的日期
      activeViewIndex: 3,
      todayBtn: true,
      todayIndex: 0,
      showDateText: "",
      eventboxshow: true,
      // dialogVisible: false,
      // value: "",
      // value1: "",
      // options1: [],
      // options: [],
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() > Date.now();
      //   },
      // },
      // value2: new Date(2016, 9, 10, 18, 40),
      availableStatus: false,
    };
  },
  created() {
    this.getToday();
  },
  methods: {
    detailSoical(id) {
      this.$emit("detailSoical", id);
    },
    giveTips(flag) {
      if (flag < 1) {
        this.availableStatus = true;
      } else {
        this.availableStatus = false;
      }
    },
    handleClick() {
      if (this.activeViewIndex == 0 && this.availableStatus) {
        this.$message.warning(
          this.$i18n.t("label.calendar.addevent.notice.opencalendarfirst")
        );
      }
    },
    getOnlyCalendar(data) {
      this.onlyCalendar = data;
    },
    // 新增/修改保存
    // save(id) {
    //   this.$refs.dateView.parentRefresh();
    // },
    //新建编辑 关闭弹窗
    // newIsShow(n) {
    //   this.dialogNewAttr.isShow = false;
    // },
    // 获取当前系统时间的下一个整点以及整点之后一个小时的时间
    // getHour() {
    //   let date = new Date();
    //   date.setHours(date.getHours() + 1);
    //   let y = date.getFullYear();
    //   let m =
    //     date.getMonth() + 1 < 10
    //       ? "0" + (date.getMonth() + 1)
    //       : date.getMonth() + 1;
    //   let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    //   let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    //   let startTime = y + "-" + m + "-" + d + " " + h + ":00:00";
    //   function getNextHour(intPoint) {
    //     let dd = new Date(intPoint);
    //     let Y = dd.getFullYear();
    //     let M =
    //       dd.getMonth() + 1 < 10
    //         ? "0" + (dd.getMonth() + 1)
    //         : dd.getMonth() + 1;
    //     let D = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    //     let H =
    //       dd.getHours() + 1 < 10
    //         ? "0" + (dd.getHours() + 1)
    //         : dd.getHours() + 1;
    //     return Y + "-" + M + "-" + D + " " + H + ":00:00";
    //   }
    //   return { start: startTime, end: getNextHour(startTime) };
    // },
    // 调用通用组件新建
    // openCommonDialog(dateObj, user, isHeader) {
      // 编辑的条件
      // this.dialogNewAttr.title = "编辑";
      // this.dialogNewAttr.type = "EDIT";
      // this.dialogNewAttr.id = this.$route.params.id;
      // this.dialogNewAttr.isShow = true;
      // this.$refs.newRelated.getRecordType('bef');
      // this.$refs.newRelated.todoSth(dateObj);
      // this.relatedArr.objectName = 'Event';
      // this.relatedArr.objName = 'Event';
      // this.$refs.newRelated.getFormAttr();
      // let op = {
      //   type: 'event',
      //   timeObj: dateObj,
      // };
      // if (this.activeViewIndex == 0) {
      //   // 可用性视图被分配人永远是表头用户（对象日历除外)
      //   if (user) {
      //     op.userInfo = user;
      //   }
      // } else {
      //   // 只打开一个其他日历，并且不是在右上角新建，更改被分配人
      //   if (this.onlyCalendar.relationid && !isHeader) {
      //     op.userInfo = {
      //       userName: this.onlyCalendar.name,
      //       userId: this.onlyCalendar.relationid,
      //     };
      //   }
      // }
      // if (this.routeData.id) {
      //   op.config = this.routeData;
      // }
    // },
    // 关闭悬浮窗
    updateDialogStatus(flag) {
      if (flag < 1) {
        this.dialogShow = false;
      } else {
        this.dialogShow = true;
      }
    },
    // 打开关闭侧边栏
    // openAside() {
    //   this.dialogShow = false;
    //   this.eventboxshow = !this.eventboxshow;
    //   setTimeout(() => {
    //     this.$refs.dateView.parentHandleResize();
    //   }, 500);
    // },
    // 切换视图
    handleChangeView(e) {
      this.todayBtn = true;
      this.dialogShow = false;
      this.todayIndex = 0;
      this.activeViewIndex = e;
      // if (e === '4') {
      //   this.$router.push({
      //     path: '/commonObjects/views/table/Event/event/bef',
      //   });
      // }
    },
    // 前一个，后一个，今天
    changeDate(index) {
      this.dialogShow = false;
      this.$refs.dateView.parentHandleClick(index);
    },
    // 前往详情页
    toItemDetail(option) {
      this.$emit("detailSoical", option);
    },
    // 根据子组件回传的日期，更新数据
    changeHeaderDate(date, indexDay) {
      this.showDateText = date;
      if (indexDay) {
        let dd = new Date(indexDay);
        let y = dd.getFullYear();
        let m =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        this.activeDay = y + "-" + m + "-" + d;
        this.activeMon = y + "-" + m;
      }
      if (this.activeViewIndex == 3) {
        if (this.activeMon == this.toMon) {
          let dd = new Date();
          let y = dd.getFullYear();
          let m =
            dd.getMonth() + 1 < 10
              ? "0" + (dd.getMonth() + 1)
              : dd.getMonth() + 1;
          let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
          this.activeDay = y + "-" + m + "-" + d;
          this.todayBtn = true;
        } else {
          this.todayBtn = false;
        }
        this.toAcalendarDate = this.activeDay;
      } else {
        if (this.activeDay == this.toDay) {
          this.todayBtn = true;
        } else {
          this.todayBtn = false;
        }
        this.toAcalendarDate = this.activeDay;
      }
    },
    // 刷新视图
    freshView() {
      this.$refs.dateView.parentRefresh();
    },
    // 跳转日期
    goDate(date) {
      this.$refs.dateView.parentGoDate(date);
    },
    // 拿到今天的日期
    getToday() {
      let dd = new Date();
      let y = dd.getFullYear();
      let m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      this.toDay = y + "-" + m + "-" + d;
      this.toMon = y + "-" + m;
    },
    // 获取后一小时
    // getNextTime(date, flag, isInBox) {
    //   var dd = new Date(date);
    //   if (isInBox) {
    //     if (this.activeViewIndex === 3 || this.activeViewIndex === "3") {
    //       var y = dd.getFullYear();
    //       var m =
    //         dd.getMonth() + 1 < 10
    //           ? "0" + (dd.getMonth() + 1)
    //           : dd.getMonth() + 1;
    //       var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    //       return y + "-" + m + "-" + d + " " + "23:59:59";
    //     } else {
    //       dd.setHours(dd.getHours() + flag);
    //     }
    //   } else {
    //     dd.setHours(dd.getHours() + flag);
    //   }
    //   var y = dd.getFullYear();
    //   var m =
    //     dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
    //   var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    //   var h = dd.getHours() < 10 ? "0" + dd.getHours() : dd.getHours();
    //   var min = dd.getMinutes() < 10 ? "0" + dd.getMinutes() : dd.getMinutes();
    //   var s = dd.getSeconds() < 10 ? "0" + dd.getSeconds() : dd.getSeconds();
    //   return y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
    // },
    getEventDate(date) {
    // getEventDate(date, userInfo) {
      // let obj = {
      //   start: date,
      //   end: this.getNextTime(date, 1, true),
      // };
      // if (userInfo) {
      //   this.openCommonDialog(obj, userInfo);
      // } else {
      //   this.openCommonDialog(obj);
      // }
      let yy = new Date().getFullYear();
      let mm1 = new Date().getMonth()+1;
      let mm = mm1<10?"0"+mm1:mm1;
      let dd = new Date().getDate()<10?"0"+new Date().getDate():new Date().getDate();
      let gettime = yy+'-'+mm+'-'+dd;
      if (date.substring(0,10) >= gettime) {
        this.$emit("newSocial");
      }
      
  },
    // getNewEvent() {
    //   let date = new Date();
    //   let y = date.getFullYear();
    //   let m =
    //     date.getMonth() + 1 < 10
    //       ? "0" + (date.getMonth() + 1)
    //       : date.getMonth() + 1;
    //   let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    //   let h =
    //     date.getHours() < 10
    //       ? "0" + (date.getHours() + 1)
    //       : date.getHours() + 1;
    //   let startTime = y + "-" + m + "-" + d + " " + h + ":00:00";
    //   let obj = {
    //     start: startTime,
    //     end: this.getNextTime(startTime, 1, false),
    //   };
    //   // this.openCommonDialog(obj, null, 1);
    // },

    // async getobject() {
    //   let object = await getRecType();
    // },
  },
  components: {
    daily,
    week,
    month,
    available,
  },
  mounted() {
    if (this.$route.query.id) {
      this.activeViewIndex = 0;
      this.routeData = this.$route.query;
    } else {
      this.routeData = {};
    }
    document.title = this.$setTitle(this.$i18n.t("label.calendar"));
    this.$store.commit(types.SET_TAB_NAME, this.$i18n.t("label.calendar"));
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  .weeksType {
    .module {
      margin-left: 10px;
    }
    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
  }
  .time-show {
    // margin-right: 20%;
    transform: translateX(-50%);
    font-family: SourceHanSansCN-Bold;
    font-size: 18px;
    color: #080707;
    letter-spacing: 0;
    font-weight: bold;
  }
}
.calendar-box {
  box-sizing: border-box;
}
.container {
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  //margin: 10px 0;
  position: fixed;
  padding-top: 10px;
  width: 98.2%;
  z-index: 100;
  background-color: #efefef;
}
.calendarmin {
  width: 300px;
  transition: width 0.3s ease;
}

.calendarmin1 {
  width: 0;
  transition: width 0.3s ease;
  display: none;
}
::v-deep .fc-toolbar-title {
  //  display: none;
}
.calendarbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px;
  background-color: #f6f6f6;
  .calendarleft {
    display: flex;
    align-items: center;
    .calen-icon {
      width: 37px;
      height: 37px;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      display: block;
      font-size: 12px;
      line-height: 20px;
      color: #333333;
      margin-left: 5px;
    }
    .header-date {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .calendarright {
    display: flex;
    align-items: center;
    .arrowleft {
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 12px;
        height: 12px;
      }
    }

    .arrowright {
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .today {
      font-size: 12px;
      height: 25px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .allday {
      margin: 10px 0 0 0;
      span:first-child {
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 1px solid #999999;
        margin-right: 5px;
      }
    }
    .Refresh {
      height: 25px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
    }

    .choice {
      height: 25px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      i {
        font-size: 12px;
      }
    }

    .newsing {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1b5297;
    }

    .morethings {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
    .dropdownchoice {
      margin: 0 10px;
    }
  }
  .taskmsg {
    display: flex;
    background-color: #f0f0f0;
    padding: 10px 0 10px 20px;
    color: #333333;
  }
  .taskmsgbox {
    display: flex;
    justify-content: space-between;
    .taskmsgleft {
      padding: 10px 0 20px 20px;
      .Be-assigned {
        span {
          font-size: 10px;
        }
        ::v-deep .el-select {
          display: block;
          width: 245px;
        }
        ::v-deep .userchioce {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .theme {
        margin: 10px 0 0 0;
        ::v-deep .el-select {
          display: block;
        }
        .themechioce {
          margin-top: 10px;
        }
      }
      .Related-persons {
        margin: 10px 0 0 0;
        ::v-deep .el-select {
          display: block;
        }
      }
      .Related-items {
        margin: 10px 0 0 0;
        ::v-deep .el-select {
          display: block;
        }
      }
    }
    .taskright {
      padding: 20px 0 20px 20px;
      .postion {
        margin-top: 10px;
        margin-bottom: 10px;
        ::v-deep .el-select {
          display: block;
        }
      }
      .timestart {
        display: flex;
        align-items: center;
      }
      .endtime {
        display: flex;
      }
    }
  }
  .remarks {
    display: flex;
    background-color: #f0f0f0;
    padding: 10px 0 10px 20px;
    color: #333333;
  }
  .content {
    padding: 0 20px;
    h5 {
      margin: 15px 0 10px 0;
    }
    textarea {
      resize: none;
      width: 100%;
      outline: none;
      border: 1px solid #dcdcdc;
    }
  }
}
.calendar-content {
  display: flex;
  //height: 565px;
  height: 100%;
  .calendar-view-box {
    transition: width 0.3s ease;
  }
  .calendar-view-aside {
    //width: 30%;
  }
}
.calendarmin-box {
  position: relative;
  .calendarmin {
  }
}
.current-index {
  background-color: #ecf5ff;
  color: #66b1ff;
  > span {
  }
  > img {
    width: 15px;
    height: 15px;
  }
}
.dropmenu-base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  > img {
    width: 15px;
    height: 15px;
  }
}
</style>
