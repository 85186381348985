<template>
  <div class="inboxstreams">
    <div class="inboxhead">
      <div class="inboxheadleft">
        <!-- 选择平台 -->
        <div class="select" ref="select">
          <div class="elselect">
            <p @click="ulshow = true" class="slectp">
              <svg class="icon" aria-hidden="true" v-if="selectvalue.img">
                <use :href="selectvalue.img"></use>
              </svg>
              <span
                >{{ $t(selectvalue.value) }} <i class="el-icon-arrow-down"></i
              ></span>
            </p>
            <ul v-show="ulshow">
              <div class="triangle"></div>
              <li @click="allClick">
                <svg class="icon" aria-hidden="true" :href="selectvalue.img">
                </svg>
                <span>
                  <!-- All Accounts -->
                  {{$t('c624')}}
                  </span>
                </li>
              <li
                v-for="(item, num) in selectoptions"
                :key="num"
                @click="liclick(item)"
                v-show="socialTypeSure(item.key)"
              >
                <svg class="icon" aria-hidden="true">
                  <use :href="item.img"></use>
                </svg>
                <span>{{ $t(item.value) }}</span>
              </li>
              <hr style="border: 0; background-color: #dedcda; height: 1px" />
              <p class="pli" @click="connectAc">
                <!-- Connect account -->
                 {{$t('c43')}}
                <svg class="icon" aria-hidden="true">
                  <use :href="shareimg"></use>
                </svg>
              </p>
            </ul>
          </div>
        </div>
        <!-- 平台 -->
        <!-- v-if="selectvalue.value !== 'All Accounts'" -->
        <div class="allink" v-if="selectvalue.value !== $i18n.t('c624')">
          <el-popover
            popper-class="marketAnalyzePopover"
            placement="bottom"
            trigger="click"
          >
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange">
              <svg
                class="icon"
                aria-hidden="true"
                style="width: 15px; height: 15px; margin: 0px 5px"
              >
                <use :href="selectvalue.img"></use>
              </svg>
              <span>{{ $t(selectvalue.value) }}</span></el-checkbox
            >
            <el-checkbox
              v-for="(city, index) in cities"
              :key="index"
              v-model="city.checked"
              @change="socialUserStr"
              style="
                display: block;
                padding-top: 10px;
                vertical-align: middle;
                margin-left: 10px;
              "
            >
              <img
                :src="`data:image/png;base64,${city.socialmediapicture}`"
                v-if="city.socialmediapicture"
                class="mainImg" />
              <span>{{ city.socialmedianame }}</span
              ><br
            /></el-checkbox>
            <p slot="reference">
              <span v-show="alllinked">{{ $t(selectvalue.value) }}</span>
              <span v-show="!alllinked">
                <svg class="icon" aria-hidden="true">
                  <use :href="allconten.img"></use>
                </svg>
                {{ allconten.value }}</span
              ><i class="el-icon-arrow-down"></i>
            </p>
          </el-popover>
        </div>
        <!-- 类型选择 -->
        <div class="Daterange">
          <el-dropdown @command="handCommand" placement="bottom-start">
            <span class="el-dropdown-link">
              <span>{{ $t(Daterange) }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(range, num) in Dateranges"
                :key="num"
                :command="range"
                >{{ $t(range) }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="inboxheadright" v-if="socialList.length !== 0">
        <button v-show="this.Daterange ==='c699'" @click="archiveAll">
          <!-- Archive all -->
          {{$t('c815')}}
        </button>
      </div>
    </div>
    <!-- 主体 -->
    <div v-loading="listLoading">
      <div class="inboxcont" id="inboxcont"  v-loadmore="loadMore">
      <!-- 数量为0 -->
      <div class="activeNumnull" v-if="socialList.length === 0">
        <h6>
          <!-- You 've read everything in your inbox -->
          {{$t('c973')}}
          </h6>
        <p>
          <!-- Publish a new post to start a new converstaion. -->
          {{$t('c974')}}
          </p>
            <!-- Create a post -->
        <button>{{$t('label.create_socialpost')}}</button>
      </div>
      <!-- 不为0 -->
      <div v-if="socialList.length !== 0">
        <!-- See comments 和like -->
        <div class="rmain" v-for="(item, index) in socialList" :key="index">

          <div class="raminCheckbox" @click="swicthLike(item)">
            <i
              class="el-icon-check"
              v-show="item.isarchive === 'true'"
              style="font-size: 22px; color: #9da8a9"
            ></i>
          </div>
          <!-- <svg class="checkImg" v-show="item.isarchive" aria-hidden="true">
              <use href="#icon-plus"></use></svg
          > -->
          <!-- </el-checkbox> -->
          <div class="somelike">
            <div class="likeup">
              <div class="lupleft">
                <svg
                  class="inimg"
                  aria-hidden="true"
                  style="width: 40px; height: 20px"
                  v-show="item.socialtype === 'facebook'"
                >
                  <use href="#icon-facebook_white"></use>
                </svg>
                <svg
                  class="inimg"
                  aria-hidden="true"
                  style="width: 40px; height: 20px"
                  v-show="item.socialtype === 'twitter'"
                >
                  <use href="#icon-twitter_white"></use>
                </svg>
                <svg
                  class="inimg"
                  aria-hidden="true"
                  style="width: 40px; height: 20px"
                  v-show="item.socialtype === 'linkedIn'"
                >
                  <use href="#icon-linkedIn_white"></use>
                </svg>
                <span>
                  {{ item.socialtype }}
                </span>
              </div>
              <div class="lupright">{{ item.timingdate }}</div>
            </div>
            <!-- 帖子内容展示 -->
            <div class="likecenter">
                <div class="accountImg">
                  <img
                    :src="`data:image/png;base64,${item.socialmediapicture}`"
                    v-if="item.socialmediapicture"
                    class="mainImg"
                  />
                </div>
              <div class="likeconent">
                <h6>{{ item.socialmedianame }}</h6>
                <div v-html="item.textcontent"></div>
                <span>{{ item.timingdate }}</span>
              </div>
              <div class="likecenteright" v-if="item.images">
                <el-image
                  class="editorImgPreview"
                  :src="imgSrc(item.images[0].filecontentid)"
                  :preview-src-list="imgSrc(item.images[0].filecontentid)"
                >
                </el-image>
              </div>
            </div>
            <!-- 帖子点赞转发数 -->
            <div class="likedown">
              <div class="ldownleft">
                <div class="like_button" v-if="item.likessum > 0">
                    <svg class="icon" aria-hidden="true">
                <use href="#icon-dianzan"></use>
              </svg>
                  {{ item.likessum }} 
                </div>
                <div class="comment_button" v-if="item.commentsnum > 0">
                  <svg class="icon" aria-hidden="true">
                <use href="#icon-pinglun"></use>
              </svg>
                  {{ item.commentsnum }} 
                </div>
              </div>
              <div class="ldownright" @click="viewPost(item)">
                <!-- View post -->
                {{$t('c816')}}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <Drawer
      :drawershow="seecommentshow"
      :drawertitle="drawertitle"
      @closedrawer="closedrawer"
    >
    </Drawer>
    <!-- 归档 -->
    <el-dialog
      :visible.sync="dialog"
      :title="$t('c443')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- 确定归档所有数据吗？ -->
      {{ $t("c869") }}
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="dialogSave" type="primary" size="mini">
          <!-- 确定 -->
          {{ $t("setup.layout.button.ok") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as marketChannel from "../api";
import Drawer from "@/views/social/monitor/components/mondrawer.vue";
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        el.addEventListener("scroll", (res) => {
          let height = res.target;
          let clientHeight = height.clientHeight;
          let scrollTop = height.scrollTop;
          let scrollHeight = height.scrollHeight;
          if (scrollHeight - scrollTop - clientHeight <= 1) {
              that.getPostsInfoLikesComment(true);
          }
        });
      },
    }},
  components: {
    Drawer,
  },
  props: {
    activeKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listLoading: false,
      socialListLength: 5,
      selectvalue: { img: "",  value: 'c624', key: "" },
      selectoptions: [
        { img: "",  value: 'c624', key: "" },
        {
          img: "#icon-facebook_white",
          // value: "Facebook accounts",
          value: 'c847',
          key: "facebook",
        },
        {
          img: "#icon-twitter_white",
          // value: "Twitter accounts",
            value: 'c849',
          key: "twitter",
        },
        { img: "#icon-in",  value: 'c848' , key: "linkedIn" },
      ],
      shareimg: "#icon-share", //分享图标
      ulshow: false,
      checkAll: true,
      cities: [],
      isIndeterminate: false,
      alllinked: true,
      allconten: {},
      Daterange: 'c699',
      Dateranges: ['c699', 'label.archived', 'label.category.1'],
      socialList: [],
      seecommentshow: false, //like和see comments弹框显示隐藏
      drawertitle: "", //弹框标题
      token: this.$CCDK.CCToken.getToken(), //获取token
      pageNum: 1, //列表页数
      socialmediaids: "", //设计媒体用户ids
      socialUserList: [],
      total:0,//列表数据总数量
      dialog: false,
    };
  },
  created() {
    this.getPostsInfoLikesComment(false);
    this.getUserInfo();
  },
  methods: {
    // 确认品台有账号绑定
    socialTypeSure(type) {
      let flag = false;
      this.socialUserList.forEach((el) => {
        if (type === el.socialtype) {
          flag = true;
        }
      });
      return flag;
    },
    viewPost(item) {
      this.$emit("detailSoical", item);
    },
    handleClose() {
      this.dialog = false;
    },
    dialogSave() {
      let ids = "";
      this.socialList.forEach((el) => {
        ids += el.id + ",";
      });
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      marketChannel
        .archiveNewOnly({
          orgid: this.$store.state.userInfoObj.orgId,
          postsids: ids,
          isarchive: "true",
        })
        .then((res) => {
          if (res.result) {
            this.getPostsInfoLikesComment(false);
            this.handleClose();
          }
        });
    },
    archiveAll() {
      this.dialog = true;
    },
    // 切换归档和未归档
    swicthLike(item) {
      marketChannel
        .archiveNewOnly({
          orgid: this.$store.state.userInfoObj.orgId,
          postsids: item.id,
          isarchive: item.isarchive === "false" ? "true" : "false",
        })
        .then((res) => {
          if (res.result) {
            this.getPostsInfoLikesComment(false);
          }
        });
    },
    // 清除长度
    
    clearLength() {
      this.socialListLength = 5;
      this.socialList = []
      this.pageNum = 1
      this.getPostsInfoLikesComment(false);
    },
    //节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    //获取社交媒体列表
    getUserInfo() {
      marketChannel
        .getScoialUserinfo({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            let array = [];
            res.data.list.forEach((element) => {
              element.checked = true;
              array.push(element);
            });
            this.socialUserList = array;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    // 获取列表信息
    getPostsInfoLikesComment(scrollType) {
       if (scrollType) {
          this.pageNum++;
        }
        this.listLoading = true;
        let obj = {
          orgid: this.$store.state.userInfoObj.orgId,
          pageNum: this.pageNum,
          pageSize: 5,
          socialtype: this.selectvalue.key,
          socialmediaid: this.socialmediaids,
          likesorcomment: this.activeKey === "all" ? "" : this.activeKey,
          isarchive:
            this.Daterange === "c699" ? "false" : this.Daterange === 'label.archived'
              ? "true"
              : "",
        };
        marketChannel.getPostsInfoLikesComment(obj).then((res) => {
          if (res.result) {
            this.listLoading = false;
            this.socialList = [...this.socialList,...res.data.sociallist];
            this.socialListLength = res.data.sociallist.length;
            this.total = res.data.total
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      // }
    },
    //获取图片
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/chatterfile.action?m=previewSocial&fileid=${id}&binding=${this.token}`;
    },
    // 平台全选。
    handleCheckAllChange(val) {
      this.cities.forEach((city) => {
        city.checked = val;
      });
      this.socialUserStr();
    },
    liclick(item) {
      this.selectvalue.img = item.img;
      this.selectvalue.value = item.value;
      this.selectvalue.key = item.key;
      this.socialList = []
      this.pageNum = 1
      this.$emit("getPostsInfoLikesCommentCount",this.selectvalue.key,  this.socialmediaids,this.Daterange)
      // 初始化状态
      this.cities = [];
      this.socialmediaids = "";
      this.socialUserList.forEach((element) => {
        // 筛选并选择平台用户
        if (element.socialtype === this.selectvalue.key) {
          this.cities.push(element);
        }
      });
      this.allnum = this.cities?this.cities.length:0
      this.socialUserStr();
      this.ulshow = false;
      this.isIndeterminate = false;
      this.checkAll = true;
    },
    //montior平台选择下拉全部选项
    allClick(){
    this.socialList = []
    this.pageNum = 1
    this.selectvalue.value = this.$i18n.t('c624'),//"All Accounts"
    // this.activeKey = "all"
    this.socialmediaids = ""
    this.selectvalue.key = ""
    this.selectvalue.img = ""
    this.getPostsInfoLikesComment(false);
    this.getUserInfo();
    this.$emit("getPostsInfoLikesCommentCount",this.selectvalue.key,  this.socialmediaids,this.Daterange)
    },
    socialUserStr() {
      // 社交账户ids
      this.socialList = []
      this.pageNum = 1
      this.socialmediaids = "";
      this.cities.forEach((el) => {
        if (el.checked) {
          this.socialmediaids += el.socialmediaid + ",";
        }
      });
      // 去掉逗号
      if (this.socialmediaids.length > 0) {
        this.socialmediaids = this.socialmediaids.substr(
          0,
          this.socialmediaids.length - 1
        );
      }
      this.$emit(
        "getPostsInfoLikesCommentCount",
        this.selectvalue.key,
        this.socialmediaids,
        this.Daterange
      );
      this.getPostsInfoLikesComment(false);
    },
    handCommand(command) {
      this.$emit(
        "getPostsInfoLikesCommentCount",
        this.selectvalue.key,
        this.socialmediaids,
        command
      );
      this.Daterange = command;
      this.socialList = []
      this.pageNum = 1
      this.getPostsInfoLikesComment(false);
    },
    //弹框关闭
    closedrawer(show) {
      this.seecommentshow = show;
    },
    
    newCreate() {
      this.$emit("Social");
    },
    //跳转设置页面
    connectAc() {
      this.$router.push({
        path: "/systemSettings/setuppage/marketSocialAccount",
      });
    },
    clickFn(e) {
      // 记得在.select-box那边加上ref="selectBox"
      let select = this.$refs.select;
      // 重点来了：selectBox里是否包含点击的元素，不包含点击的元素就隐藏面板
      if (select) {
        if (!select.contains(e.target)) {
          this.ulshow = false;
        }
      }
    },
    scrollFn() {
      let that = this;
      let dom = document.getElementById("inboxcont");
      if (dom.clientHeight + dom.scrollTop >= dom.scrollHeight) {
        that.fnThrottle(that.getPostsInfoLikesComment(true), 1000, 1000);
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.clickFn);
    document.getElementById("inboxcont").addEventListener("scroll", this.scrollFn);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
    document.getElementById("inboxcont").removeEventListener("scroll", this.scrollFn);
  }
};
</script>

<style lang="scss" scoped>
.mainImg {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  margin: 0px 5px;
}
.inboxstreams {
  width: 100%;
  letter-spacing: 0;
  .inboxhead {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-left: 35px;
    .inboxheadleft {
      display: flex;
      align-items: center;
    }
    .select {
      position: relative;
      float: left;
      .elselect {
        position: relative;
        height: 30px;
        .slectp {
          margin-left: 35px;
          height: 30px;
          background: #ffffff;
          line-height: 30px;
          font-size: 14px;
          color: #080707;
          vertical-align: middle;
          cursor: pointer;
          img {
            margin-left: 5px;
          }
          .icon {
            margin-left: 5px;
          }
          span {
            padding-left: 5px;
          }
          i {
            padding-right: 5px;
          }
        }
        ul {
          background: #ffffff;
          border: 1px solid #dddbda;
          box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
          position: absolute;
          left: 0;
          top: 40px;
          z-index: 9;
          .triangle {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 15px solid #f3f2f2;
            position: absolute;
            top: -7px;
            left: 15px;
            border-style: solid;
            border-width: 8px;
            border-color: #ffffff #ffffff transparent transparent;
            transform: rotate(-45deg);
            box-shadow: 2px -2px 2px #ccc;
          }
          li {
            width: 189px;
            padding-left: 17px;
            height: 34px;
            margin-top: 2px;
            line-height: 34px;
            vertical-align: middle;
            img {
              padding-right: 5px;
            }
            .icon {
              padding-right: 5px;
            }
          }
          li:hover {
            background: #ececec;
            color: #006dcc;
          }
          .pli {
            color: #006dcc;
            padding-left: 17px;
            cursor: pointer;
            line-height: 34px;
          }
        }
      }
    }
    ::v-deep .allink {
      margin-left: 15px;
      color: #006dcc;
      float: left;
      height: 20px;
      .anlypop {
        width: 160px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
      }
      .el-popover__reference {
        cursor: pointer;
        img {
          margin-right: 5px;
        }
        .icon {
          margin-right: 5px;
        }
        i {
          padding-right: 5px;
        }
      }
    }
    .inboxheadright {
      button {
        cursor: pointer;
        outline: none;
        background: #005fb2;
        border-radius: 3px;
        border: none;
        height: 31px;
        font-size: 14px;
        color: #ffffff;
        padding: 4px 9px;
      }
    }
  }
  .Daterange {
    float: left;
    margin-left: 16px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    letter-spacing: 0;
    color: #000000;
    outline: none;
    .el-dropdown-link {
      margin-left: 2px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #006dcc;
      letter-spacing: 0;
      outline: none;
    }
  }
  .inboxcont {
    width: 100%;
    height: 710px;
    overflow: auto;
    .activeNumnull {
      text-align: center;
      h6 {
        font-size: 16px;
        color: #080707;
        font-family: SourceHanSansCN-Bold;
        font-weight: bolder;
      }
      p {
        font-size: 12px;
        color: #080707;
        font-family: SourceHanSansCN-Regular;
        padding-top: 16px;
      }
      button {
        outline: none;
        background: #005fb2;
        border-radius: 3px;
        border: none;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #ffffff;
        width: 127px;
        height: 31px;
        margin: 21px;
        cursor: pointer;
      }
    }
    ::v-deep .el-checkbox__inner {
      border-radius: 14px;
      width: 30px;
      height: 30px;
      margin-top: 8px;
      margin-right: 10px;
    }
    ::v-deep .el-checkbox__inner::after {
      display: none;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #b3cde4;
      border-color: #a5cff3;
    }
    .rmain {
      display: flex;
      width: 100%;
      margin-top: 12px;
      position: relative;
      .raminCheckbox {
        // position: absolute;
        // left: -35px;
        // padding-left: 35px;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        border: 1px solid #a5a1a1;
        border-radius: 50%;
        padding: 3px;
        cursor: pointer;
        .checkImg {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 0px;
          top: 8px;
          z-index: 99;
        }
      }
      .somelike {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        .likeup {
          background: #0277b5;
          height: 40px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .lupleft {
            img {
              margin-left: 10px;
              width: 19.7px;
              height: 19.7px;
              padding-right: 7.3px;
            }
          }
          .lupright {
            margin-right: 10px;
            line-height: 40px;
          }
        }
        .likecenter {
          width: 99%;
          border: 1px solid #dddbda;
          border-radius: 3px;
          margin: 10px auto;
          display: flex;
          align-items: center;
          .accountImg {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80px;
            position: relative;
            .mainImg {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
            .inimg {
              width: 30px;
              height: 30px;
              position: absolute;
              left: 25px;
              bottom: 0px;
            }
          }
          .likecenterleft {
            width: 55px;
            height: 100%;
            text-align: center;
          }
          .likeconent {
            width: 900px;
            h6 {
              font-family: MicrosoftYaHei;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 24px;
              margin-top: 10px;
            }
            p {
              font-size: 14px;
              color: #3e3e3c;
              margin-bottom: 6px;
              line-height: 24px;
            }
            span {
              display: inline-block;
              font-size: 12px;
              color: #888888;
              line-height: 24px;
              padding-bottom: 8px;
            }
          }
          .likecenteright {
            right: 30px;
            top: 55px;
            width: 80px;
            height: 50px;
            img {
              width: 185px;
              height: 97px;
            }
            .icon {
              width: 185px;
              height: 97px;
            }
          }
        }
        .likedown {
          height: 44px;
          display: flex;
          justify-content: space-between;
          margin: 0 10px;
          //   line-height: 5px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          letter-spacing: 0;
          .ldownleft {
            display: flex;
            .like_button {
              height: 30px;
              line-height: 31px;
              margin-right: 20px;
              // cursor: pointer;
            }
            .comment_button {
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #080707;
              letter-spacing: 0;
              line-height: 30px;
              .el-button {
                width: 128px;
                height: 31px;
                background: #005fb2;
                border-radius: 3px;
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #ffffff;
                letter-spacing: 0;
                margin-left: 10px;
              }
            }
          }
          .ldownright {
            line-height: 30px;
            cursor: pointer;
            color: #006dcc;
          }
        }
      }
    }
    .rmaintop {
      width: 100%;
      display: flex;
      position: relative;
      .raminCheckbox {
        position: absolute;
        left: -30px;
      }
      .someliketop {
        width: 98%;
        background: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        .somelikehead {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #55adee;
          height: 40px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0;
          .somelikeleft {
            img {
              margin-left: 10px;
              margin-right: 7px;
            }
            .icon {
              margin-left: 10px;
              margin-right: 7px;
            }
          }
          .somelikeright {
            margin-right: 10px;
          }
        }
        .somelikeconent {
          width: 100%;
          .somelikecont {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #dddbda;
            .somelikeconentleft {
              h6 {
                font-family: SourceHanSansCN-Bold;
                font-size: 14px;
                color: #006dcc;
                letter-spacing: 0;
                font-weight: bolder;
                margin-left: 84px;
                margin-top: 30px;
              }
              p {
                font-family: SourceHanSansCN-Regular;
                font-size: 12px;
                color: #888888;
                letter-spacing: 0;
                margin-left: 84px;
                margin-top: 8px;
              }
              ul {
                margin-top: 30px;
                li {
                  line-height: 18px;
                  margin-bottom: 16px;
                  span {
                    display: inline-block;
                    width: 70px;
                    text-align: right;
                    margin-right: 14px;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 12px;
                    color: #888888;
                  }
                }
              }
            }
          }
          .somelikeconentright {
            margin-right: 1%;
            p {
              font-family: SourceHanSansCN-Regular;
              font-size: 12px;
              color: #888888;
              letter-spacing: 0;
              margin-top: 145px;
              text-align: right;
            }
            .follow {
              display: flex;
              align-items: center;
              margin-top: 14px;
              button {
                float: right;
                width: 89px;
                height: 31px;
                outline: none;
                border: none;
                background: #005fb2;
                border-radius: 3px;
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #ffffff;
                margin-right: 8px;
                cursor: pointer;
              }
              .Daterange {
                float: right;
              }
            }
          }
          .somelikefooter {
            width: 92.5%;
            margin-left: 7%;
            .crmcloud {
              font-family: SourceHanSansCN-Regular;
              font-size: 12px;
              color: #080707;
              margin-top: 20px;
              margin-bottom: 10px;
              span {
                font-size: 12px;
                color: #888888;
              }
            }
            .formula {
              width: 100%;
              margin-bottom: 16px;
              position: relative;
              background: #fcfcfc;
              border: 1px solid #dddbda;
              border-radius: 2px;
            }
            button {
              outline: none;
              border: none;
              background: #005fb2;
              border-radius: 3px;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #ffffff;
              width: 89px;
              height: 31px;
              margin-bottom: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
