<!--  -->
<template>
  <div class="likeseedrawer">
    <el-drawer
        :title="drawertitle"
        :visible.sync="drawershowProp"
        size="50%"
        :before-close="close">
        <div class="drawconent">
            <div class="likedrop">
                <el-dropdown trigger="click"  popper-class="socialmondrawer">
                    <span class="el-dropdown-link">
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in drowlist" :key="item">{{item}}
                              <svg class="icon" aria-hidden="true" v-show="item=='View on Linkedln'">
                                 <use :href="shareimg"></use>
                              </svg>
                             </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="introduce">
                <h6 class="imgcloud">
                     <svg class="icon" aria-hidden="true">
                       <use :href="inimg"></use>
                    </svg>
                    CloudCC </h6>
                <dl>
                    <dd>
                        <p>Over at the brand new Aquatics Centre, Britain's star diver Tom Daley is going to perform an official launch dive 
                            into the Olympic pool.ith this building, the organisers have attempted to give London a landmark to rival Beijing's
                             Water Cube from 2008.It was designed by the prestigious architect Zaha Hadid and has a wave-like roof that is 160 
                             metres long.
                        </p>
                    </dd>
                    <dt>
                         <svg class="icon" aria-hidden="true">
                          <use :href="inimg"></use>
                         </svg>
                    </dt>
                </dl>
                <p class="introducep">Campaign: Book a dom</p>
                <span>Link : https:/fo.cloudcc.com/allffrhytkjfdvb.fnhjtjyyt.dntdemjyrsndfn.fjyr.kj.yrwd.bes</span>
            </div>
            <div class="remark" v-for="item in remarkes" :key="item.name">
                <dl>
                    <dt>
                         <svg class="icon" aria-hidden="true">
                           <use :href="item.img"></use>
                        </svg>
                        </dt>
                    <dd>
                        <h5>{{item.name}}</h5>
                        <p>{{item.job}}</p>
                        <span v-if="drawertitle=='Comments'">{{item.like}}</span>
                    </dd>
                </dl>
                <span>{{item.timeago}}</span>
            </div>
            <div class="reamakfoot" v-if="drawertitle=='Comments'">
                <h6 class="imgcloud">
                     <svg class="icon" aria-hidden="true">
                         <use :href="inimg"></use>
                     </svg>
                    CloudCC </h6>
                <div class="formuladiv">   
                    <Formula-bar
                    :contents="contents"
                    @editorContent=" onContentChange(arguments[0])"
                    ></Formula-bar>
                </div>
                <button>Reply</button>
            </div>
        </div>
    </el-drawer>
  </div>
</template>

<script>
import FormulaBar from '@/views/social/monitor/components/formulabar.vue'
export default {
     components: {
        FormulaBar,
    },
    props: {
        //弹框显示隐藏
        drawershow: {
            type: Boolean,
            default: false
        },
        //弹框标题
        drawertitle: {
            type: String,
            default:''
        },
    },
    data () {
        return {
            drawershowProp: this.drawershow,
            drowlist:['Clone','Delete','View on Linkedln'],
            shareimg:'#icon-share',//分享图标
            inimg:'#icon-in',//f图标
            remarkes:[
                {
                    img:'#icon-in',
                    name:"索大",
                    job:"海贼王-三刀流",
                    like:"很喜欢",
                    timeago:"100 years ago",
                }
            ],
            contents:`<p>Post comment</p>`//富文本
        }
    },
    methods :{
        close() {
            this.drawershowProp = false;
            this.$emit('closedrawer', this.drawershowProp)
        },
        onContentChange(val){
            this.contents=val
            
        },
    }
}
</script>
<style lang='scss'>
.el-dropdown.socialmondrawer{
    background: #FFFFFF;
    border: 1px solid #DDDBDA;
    box-shadow: 1px 2px 8px 0 rgba(0,0,0,0.46); 
}
</style>
<style lang='scss' scoped >
.likeseedrawer{
    width: 100%;
     ::v-deep .el-drawer__header {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #3e3c3c;
        letter-spacing: 0;
        font-weight: bolder;
        border-bottom: 1px solid #DDDBDA;
         height: 60px;
    }
    .drawconent{
        width: 90%;
        margin-left: 5%;
    }
    .likedrop{
        width: 100%;
        height: 40px;
        ::v-deep .el-dropdown{
        border: 1px solid #DDDBDA; 
        outline: none;
        padding: 6px 28px;
        float: right;
        border-radius: 3px;
        }
    }
    .imgcloud{
        margin-left: 1%;
        margin-top: 30px;
        vertical-align:middle;
        .icon{
            margin-right: 5px;
            width: 40px;
            height: 40px;
        }
    }
    .introduce{
        width: 100%;
        margin-top: 10px;
        border: 1px solid #DDDBDA;
        border-radius: 3px;
        h6{
           margin-left: 4%;
        }
        dl{
            display: flex;
            justify-content: space-between;
            width: 92%;
           margin-left: 4%;
            margin-top: 20px;
            dt{
                .icon{
                    width: 150px;
                    height: 100px;
                }
            }
            dd{
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #080707;
                line-height: 20px;
                letter-spacing: 0;
            }
        }
        .introducep{
            margin-left: 4%;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #080707;
            margin-top: 20px;
            margin-bottom: 12px;
        }
        span{
            display: inline-block;
            margin-left: 4%;
            margin-bottom: 30px;
            color:#0277B5;
        }
    }
    .remark{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        margin-top: 20px;
        dl{
            display: flex;
            justify-content: space-between;
            align-items: center;
            dt{
                margin-right: 10px;
                .icon{
                    width: 40px;
                    height: 40px;
                }
            }
            dd{
                h5{
                    font-family: SourceHanSansCN-Bold;
                    font-size: 14px;
                    color: #080707;
                    font-weight: bolder;
                }
                p{
                    margin-top: 8px;
                    margin-bottom: 8px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #888888;
                }
                span{
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #080707;
                }
            }
            span{
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #888888;
            }
        }
    }
    .reamakfoot{
        width: 100%;
        border-top: 1px solid #DEDCDA;
        .formuladiv{
            background: #FCFCFC;
            border: 1px solid #DDDBDA;
            border-radius: 2px;
            margin-top: 15px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #888888;
            line-height: 24px;
            width: 100%;
            position: relative;
        }
        button{
            background: #005FB2;
            border-radius: 3px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #FFFFFF;
            width: 73px;
            height: 30px;
            border: none;
            outline: none;
            margin-top: 16px;
            cursor: pointer;
        }
    }
}
</style>

