<template>
  <div class="social" v-loading="loading">
    <div class="head" v-if="!guidepage">
      <div class="socialtitl">
        <span>{{$t('UG_Lead_003')}}</span>
        <button @click="newSocial" style="cursor: pointer">
         {{$t('label.create_socialpost')}}
        </button>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="ml-10" style="margin-left:0">
        <el-tab-pane
          v-for="item in socisltitle"
          :key="item"
          :label="item"
          :name="item"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <detailSoical
      ref="detailSoical"
      @openPost="openPost"
      @reList="refreshList"
    ></detailSoical>
    <newSocial
      ref="newSocial"
      @openPost="openPost"
      :selectoptions="selectoptions"
      @linkAcount="linkAcount"
    ></newSocial>
    <createSocial
      ref="createSocial"
      @refreshList="refreshList"
      @linkAcount="linkAcount"
    ></createSocial>
    <Manage
      ref="manage"
      v-if="manageshow"
      @changoptions="changoptions"
      @detailSoical="detailSoical"
      @newSocial="newSocial"
      @openPost="openPost"
      @changUser="changUser"
    ></Manage>
    <Monitor
      v-if="monitorshow"
      @detailSoical="detailSoical"
      @newSocial="newSocial"
    ></Monitor>
    <Analyze v-if="analyzeshow"></Analyze>
    <guidePage
      v-if="guidepage"
      ref="guidePage"
      @getScoialUserinfo="getScoialUserinfo"
      @newSocial="newSocial"
      @closeGuide="closeGuide"
    ></guidePage>
  </div>
</template>
<script>
import newSocial from "@/views/social/components/newSocial.vue";
import createSocial from "@/views/social/components/createSocial.vue";
import detailSoical from "@/views/social/components/detailSoical.vue";
import Manage from "@/views/social/manage/index.vue";
import Monitor from "@/views/social/monitor/index.vue";
import Analyze from "@/views/social/analyze/index.vue";
import guidePage from "@/views/social/components/guidepage.vue";
import * as marketChannel from "./api";
export default {
  components: {
    newSocial,
    createSocial,
    detailSoical,
    Manage,
    Monitor,
    Analyze,
    guidePage,
  },
  data() {
    return {
      selectoptions: [],
      activeName: this.$i18n.t('label.customsetting.manage'),
      socisltitle: [this.$i18n.t('label.customsetting.manage'), this.$i18n.t('c623'), this.$i18n.t('vue_label_systemSettings_analyse')],
      manageshow: false,
      monitorshow: false,
      analyzeshow: false,
      guidepage: false,
      loading: true,
    };
  },
  mounted() {
    this.getScoialUserinfo();
    this.getApp();
  },
  methods: {
    changUser() {
      this.$refs.createSocial.getUserinfo();
    },
    linkAcount(type) {
      this.$refs.manage.linkAcount(type);
    },
    changoptions(data) {
      this.selectoptions = data;
    },
    refreshList() {
      this.$refs.manage.refreshList();
    },
    // 新建添加
    openPost(pathType, row) {
      this.$refs.newSocial.handleClose();
      this.$refs.createSocial.openPost(pathType, row);
    },
    // 打开详细页
    detailSoical(id) {
      this.$refs.detailSoical.detailSoical(id);
    },
    newSocial() {
      this.$refs.newSocial.newSocial();
    },
    handleClick(tab) {
      if (tab.index === "0") {
        this.manageshow = true;
        this.monitorshow = false;
        this.analyzeshow = false;
      } else if (tab.index === "1") {
        this.manageshow = false;
        this.monitorshow = true;
        this.analyzeshow = false;
      } else {
        this.manageshow = false;
        this.monitorshow = false;
        this.analyzeshow = true;
      }
    },
    getScoialUserinfo() {
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
        socialtype: "",
      };
      marketChannel.getScoialUserinfo(obj).then((res) => {
        this.loading = false;
        if (res.data.list.length > 0) {
          this.manageshow = true;
        } else {
          this.guidepage = true;
        }
      });
    },
    // 获取第三方
    async getApp() {
      let obj = {
        orgid: this.$store.state.userInfoObj.orgId,
      };
      marketChannel.queryCommentsLikes(obj).then(() => {});
    },
    closeGuide() {
      this.guidepage = false;
      this.manageshow = true;
    },
  },
};
</script>
<style scoped lang="scss">
.social {
  width: 100%;
  font-family: MicrosoftYaHei;
  letter-spacing: 0;
  .head {
    width: 98.5%;
    margin-left: 0.7%;
    background: #ffffff;
    border: 1px solid #dddbda;
    border-bottom: 3px solid #dddbda;
    border-radius: 3px;
    height: 109px;
    margin-top: 10px;
    .socialtitl {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        color: #080707;
        padding-left: 10px;
        padding-top: 26px;
      }
      button {
        background: #006dcc;
        border-radius: 3px;
        outline: none;
        color: #ffffff;
        font-size: 12px;
        width: 155px;
        height: 35px;
        margin-right: 10px;
        margin-top: 20px;
        border: none;
      }
    }
  }
}
.el-tabs {
  margin-top: 15px;
  padding-left: 10px;
  ::v-deep .el-tabs__item {
    font-size: 16px;
    padding-left: 48px;
    outline: none;
  }
  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #080707;
  }
}
::v-deep .el-tabs__active-bar {
  bottom: 2px;
}
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}
</style>
