<template>
  <div class="guidepage">
    <div class="guidepage-head">Social</div>
    <div class="guidepage-img">
      <img src="./guidepage.png" alt="" />
    </div>
    <div class="guidepage-des">
      <div class="guidepage-des-list">
        <div class="guidepage-des-top">
          {{$t('c710')}}
        </div>
        <div class="guidepage-list-top">
          {{$t('c711')}}
        </div>
        <ul class="guidepage-list-item">
          <li>
            {{$t('c712')}}
          </li>
          <li>{{$t('c713')}}</li>
          <li>{{$t('c714')}}</li>
          <li>{{$t('c715')}}</li>
        </ul>
        <div class="guidepage-btn" @click="seletItem">
          {{$t('c43')}}
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="866px" showClose="false">
      <div slot="title" class="header-title">
        <div class="header-title-describe">{{$t('c709')}}</div>
      </div>
      <div class="select">
        <div class="select-item" @click="link('facebook')">
          <svg
            style="width: 40px; height: 40px; padding: 12px"
            class="icon"
            aria-hidden="true"
          >
            <use href="#icon-facebook_white"></use>
          </svg>
          <div class="select-item-name">Facebook</div>
        </div>
        <div class="select-item" @click="link('twitter')">
          <svg
            style="width: 40px; height: 40px; padding: 12px"
            class="icon"
            aria-hidden="true"
          >
            <use href="#icon-twitter_white"></use>
          </svg>
          <div class="select-item-name">Twitter</div>
        </div>
        <div class="select-item" @click="link('linkedIn')">
          <svg
            style="width: 40px; height: 40px; padding: 12px"
            class="icon"
            aria-hidden="true"
          >
            <use href="#icon-linkedIn_white"></use>
          </svg>
          <div class="select-item-name">LinkedIn</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="width: 638px;"
      center
      :before-close="centerClose"
    >
      <div slot="title" class="center-head">
        <div class="center-head-describe">{{$t('c722')}}</div>
      </div>
      <div class="center-img">
        <div class="center-img-content">
          <img src="./guidepage.png" alt="" />
        </div>
        <div class="center-bottom-content">
          {{$t('c716')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="Createpost"
          > {{$t('c717')}}</el-button
        >
        <el-button @click="Continueup">{{$t('c718')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as socialApi from "./api";
export default {
  data() {
    return {
      dialogVisible: false,
      centerDialogVisible: false,
      accountOpen: {},
    };
  },
  methods: {
    seletItem() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    settingVisible() {
      this.centerDialogVisible = true;
    },
    centerClose() {
      this.centerDialogVisible = false;
      this.$emit("closeGuide");
    },
    link(type) {
      socialApi
        .getAppISConfig({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            res.data.forEach((row) => {
              if (row.type === type && row.isconfig) {
                this.connectUrl(type);
              }
            });
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.$i18n.t("c729"),
            });
          }
        });
    },
    
    connectUrl(type) {
      socialApi
        .authorizationUrl({
          orgid: this.$store.state.userInfoObj.orgId,
          socialtype: type,
        })
        .then((res) => {
          if (res.result) {
            this.accountOpen = window.open(
              res.data,
              this.$i18n.t("label.emailsync.form.signin"),
              "status=no,toolbar=no,menubar=no,location=no,resizable=no,titlebar=no"
            );
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
        this.handleClose();
    },
    // 接收第三方数据授权
    receiveMessage(event) {
      if (this.accountOpen !== {}) {
        this.accountOpen.close();
        let socialDataList = [];
        event.data.accountList.forEach((event) => {
          if (event.issocialauth) {
            socialDataList.push(event);
          }
        });
        socialApi
          .storeCacheAccessToken({
            orgid: this.$store.state.userInfoObj.orgId,
            socialtype: event.data.socialName,
            socialdata: socialDataList,
          })
          .then((res) => {
            if (res.result) {
              this.centerDialogVisible = true;
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("c46"),
              });
              this.settingVisible();
              this.$emit("getScoialUserinfo");
              // this.dialogVisible = false;
              // this.initTableDota();
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
        // this.init()
        // this.$emit('changUser')
      }
    },
    Createpost() {
      this.centerDialogVisible = false;
      this.$emit("newSocial");
      this.$emit("closeGuide");
    },
    Continueup() {
      this.$emit("closeGuide");
      this.centerDialogVisible = false;
      this.$router.push({
        path: `/systemSettings/setuppage/marketSocialAccount`,
      });
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage, false);
  },
};
</script>
<style scoped lang="scss">
.guidepage {
  background: #fff;
  height: 100%;
  .guidepage-head {
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    padding: 32px 0 32px 60px;
    border-bottom: 1px solid #dedede;
  }
  .guidepage-img {
    // width: 300px;
    // height: 233px;
    margin: auto;
    text-align: center;
    padding: 118px 0 31px 0;
  }
  .guidepage-des {
    // text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 110px;
    .guidepage-des-list {
      //    text-align: left;
      .guidepage-list-top {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 32px;
      }
      .guidepage-list-item {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 32px;
        text-align: left;
        margin: auto;
      }
    }
    .guidepage-des-top {
      font-size: 22px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 32px;
      // padding-bottom: 129px;
    }
    .guidepage-btn {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 21px;
      width: 177px;
      height: 39px;
      background: #333333;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 31px;
      cursor: pointer;
    }
  }
  .header-title {
    background: linear-gradient(90deg, #2d6cfc 0%, #6c98fd 100%);

    .header-title-describe {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 26px;
      padding: 28px 0 28px 40px;
    }
  }
  .select {
    display: flex;
    cursor: pointer;
    .select-item {
      flex: 1;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(110, 134, 189, 0.5);
      border-radius: 2px;
      border: 1px solid #2d6cfc;
      margin: 30px;
      display: flex;
      align-items: center;
      .select-item-name {
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 26px;
      }
    }
  }
  .center-head {
    background: linear-gradient(90deg, #2d6cfc 0%, #6c98fd 100%);
    .center-head-describe {
      font-size: 42px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 55px;
      padding: 71px 0;
    }
  }
  .center-img {
    .center-img-content {
      margin: auto;
      text-align: center;
      img {
        width: 300px;
        height: 233px;
        // margin-top: -100px;
        background: #fff;
      }
    }
    .center-bottom-content {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 21px;
      text-align: center;
    }
  }
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__headerbtn i {
  font-size: 20px;
  color: #fff;
}
::v-deep .el-dialog__footer {
  border-top: none;
}
</style>
