/*
 * @Author: your name
 * @Date: 2022-03-30 17:38:45
 * @LastEditTime: 2022-04-07 13:50:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\social\monitor\api.js
 */
import axios from '@/config/httpConfig'
//查询Social帖子点赞数分享数和分页列表
export function archiveNewOnly(data) {
    return axios.post('/social/archiveNewOnly', data)
}
// 查询Social用户信息
export function getScoialUserinfo(data) {
    return axios.post('/social/querySocialUser', data)
}
//查询Social帖子点赞数分享数和分页列表
export function getPostsInfoLikesComment(data) {
    return axios.post('/social/getPostsInfoLikesComment', data)
}
export function getPostsInfoLikesCommentCount(data) {
    return axios.post('/social/getPostsInfoLikesCommentCount', data)
}