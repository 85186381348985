<!--  -->
<template>
  <div class="" @mouseleave="tableMouseLeave">
    <el-table
      ref="tablePanel"
      :data="dataList"
      :border="true"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @select="select"
      @select-all="selectAll"
      :style="{
        width: '100%',
        'min-height': '0px',
        'max-height': '1000px',
        overflow: 'auto',
      }"
    >
    <template slot="empty">
      <!-- 看起来并没有已发布的帖子。 -->
          <span style="font-size: 14px" v-if="type=='0'">{{$t('c854')}}</span>
          <!-- 看起来并没有预约的帖子。 -->
          <span style="font-size: 14px" v-if="type=='1'">{{$t('c839')}}</span>
          <!-- 有什么新想法？记录下来。 -->
          <span style="font-size: 14px" v-if="type=='2'"> {{$t('c841')}}</span>
          <!-- 暂时没有发布失败的帖子。 -->
          <span style="font-size: 14px" v-if="type=='3'">{{$t('c840')}}</span>
        </template>
      <!-- check -->
      <el-table-column v-if="checked" type="selection" width="51">
      </el-table-column>
      <!-- 表头&内容展示区 -->
      <template v-for="item in tableAttr">
        <slot :name="item.schemefieldName" :item="item">
          <!-- 普通文本类型数据 -->
          <el-table-column
          :resizable="false"
            :width="item.colwidth"
            :label="$i18n.t(item.nameLabel)"
                :min-width="item.schemefieldName=='textcontent'?'600px':'150px'"
            :prop="item.schemefieldName"
          >
            <template slot="header">
              <!-- 普通自定义表头 -->
              <div
                class="tableHeadField"
                v-show="!item.isfunctional || selectNumber === 0"
                @click="sortClick(item)"
              >
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="$i18n.t(item.nameLabel)"
                >
                  <span slot="reference">
                    {{ $t(item.nameLabel) }}
                  </span>
                </el-popover>
                <div class="sortBox" v-if="item.schemefieldName === 'timingdate' || item.schemefieldName ==='senddate' ||item.schemefieldName ==='createdate'">
                  <span v-show="sortDir ==='desc'">↓</span>
                  <span v-show="sortDir ==='asc'">↑</span>
                  </div>
              </div>
              <!-- 功能性自定义表头 -->
              <div
                class="tableHeadField"
                v-show="item.isfunctional && selectNumber !== 0"
              >
                <div class="functional-box">
                  <div class="statistical">
                    <!-- selected  {{ selectNumber }} --> 
                    {{$t('c969',[selectNumber])}}
                    </div>
                  <svg
                    class="editpen-box"
                    aria-hidden="true"
                    style="width: 20px; height: 15px"
                  >
                    <use href="#icon-editPens"></use>
                  </svg>
                  <div
                    class="assign-box"
                    @click="postAssgin"
                  >
                    <!-- Assgin campaign -->
                    {{$t('c701')}}
                  </div>
                  <svg
                    class="clone-img"
                    aria-hidden="true"
                    style="width: 20px; height: 15px"
                  >
                    <use href="#icon-copy"></use>
                  </svg>
                  <div class="clone-box" @click="postClone">
                    <!-- Clone -->
                    {{$t('pagecreator.page.button.copy')}}
                    </div>
                  <svg
                    class="delete-img"
                    aria-hidden="true"
                    v-show="powerType!=='Published'"
                    style="width: 20px; height: 15px"
                  >
                    <use href="#icon-system_delete_ic"></use>
                  </svg>
                  <div
                    class="clone-box"
                    @click="postDelete"
                    v-show="powerType!=='Published'"
                  >
                    {{$t('label.department.user.delete')}}
                  </div>
                  <!-- 预约发布批量转草稿 -->
                  <svg
                    class="delete-img"
                    aria-hidden="true"
                    v-show="powerType==='Scheduled'"
                    style="width: 20px; height: 15px"
                  >
                    <use href="#icon-a-bianzu92"></use>
                  </svg>
                  <div
                    class="clone-box"
                    @click="Makedraft"
                    v-show="powerType==='Scheduled'"
                  >
                    {{$t('c702')}}
                  </div>
                   <!-- 批量批准草稿 -->
                  <svg
                    class="delete-img"
                    aria-hidden="true"
                    v-show="powerType==='Draft'"
                    style="width: 20px; height: 15px"
                  >
                    <use href="#icon-a-bianzu92beifen"></use>
                  </svg>
                  <div
                    class="clone-box"
                    @click="DraftAll"
                    v-show="powerType==='Draft'"
                  >
                    {{$t('c645')}}
                  </div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <!-- 日期web复合类型 -->
              <span class="cannotEdit" v-if="item.schemefieldType === 'DE'">
                <div class="compositeDate">
                  {{
                    scope.row[item.schemefieldName]
                      ? strDateToDate(scope.row[item.schemefieldName])
                      : null
                  }}
                </div>
                <div class="composite">
                  {{
                    scope.row[item.schemefieldName]
                      ? strDateToWeek(scope.row[item.schemefieldName])
                      : null
                  }}
                </div>
                <div class="composite">
                  {{
                    scope.row[item.schemefieldName]
                      ? strDateToTime(scope.row[item.schemefieldName])
                      : null
                  }}
                </div>
              </span>
              <!-- 图片文字复合类型 -->


              
              <span
                class="cannotEdit"
                v-else-if="item.schemefieldType === 'PO'"
              >
                <div class="postImg">
                  <div class="imgBox" v-if="scope.row.filecontentids">
                    <img
                      :src="imgSrc(scope.row.filecontentids)"
                      style="width: 125px; height: 95px"
                    />
                  </div>
                  <div class="imgBox" v-if="!scope.row.filecontentids">
                    <div
                      style="width: 125px; height: 95px"
                    >  
                    </div>
                  </div>
                  <div class="logoMessage">
                <div class="accountImg">
                  <img
                    :src="`data:image/png;base64,${scope.row.socialmediapicture}`"
                    v-if="scope.row.socialmediapicture"
                    class="mainImg"
                  />
                  <svg aria-hidden="true" v-else class="mainImg">
                    <use href="#icon-contacts_def"></use>
                  </svg>
                  <!-- 平台图片 -->
                  <svg
                    class="inimg"
                    aria-hidden="true"
                    v-show="scope.row.socialtype === 'facebook'"
                  >
                    <use href="#icon-facebook_white"></use>
                  </svg>
                  <svg
                    class="inimg"
                    aria-hidden="true"
                    v-show="scope.row.socialtype === 'twitter'"
                  >
                    <use href="#icon-twitter_white"></use>
                  </svg>
                  <svg
                    class="inimg"
                    aria-hidden="true"
                    v-show="scope.row.socialtype === 'linkedIn'"
                  >
                    <use href="#icon-linkedIn_white"></use>
                  </svg>
                   <span class="ml-10">{{ scope.row.socialmedianame }}</span>
                </div>
                <div>
                  <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="scope.row[item.schemefieldName]"
                >
                  <div
                  slot="reference"
                      class="message"
                      style="width:400px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                    >{{scope.row[item.schemefieldName]}}</div>
                </el-popover>
                </div>
                 
                    <div class="poststypeBox">
                      <div
                        class="roundBox"
                        :style="postsColor(scope.row.poststype)"
                      ></div>
                      <div class="postsStr">
                        {{ poststypeToStr(scope.row.poststype) }}
                      </div>
                    </div>
                  </div>
                  <div class="btList-box" style="display: none">
                    <el-button
                      size="mini"
                      @click="
                        actionEmit({ emit: buttonlist[0].emit, row: scope })
                      "
                    >
                      {{ buttonlist[0].name }}
                    </el-button>
                    <el-button
                      size="mini"
                      style="margin-right: 10px"
                      @click="
                        actionEmit({ emit: buttonlist[1].emit, row: scope })
                      "
                    >
                      {{ buttonlist[1].name }}
                      
                    </el-button>
                    <el-dropdown
                      trigger="click"
                      placement="bottom"
                      @visible-change="visibleChange"
                      ref="messageDrop"
                      @command="actionEmit"
                      id="dropdownTitle"
                    >
                      <el-button size="mini">
                        {{ buttonlist[2].name }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item
                          v-for="(item, dropIndex) in selectvalue"
                          :divided="item.divided"
                          :key="dropIndex"
                          :command="{ emit: item.emit, row: scope }"
                          >
                          <span >{{
                             dorpName(scope.row.socialtype,item.name)
                          }}</span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </span>
              <!-- 普通文本 -->
              <span v-else>
                {{ scope.row[item.schemefieldName] }}
              </span>
              <!--事件及任务下的名称及相关项去除编辑/相关列表文件、类型attachtype去除编辑-->
            </template>
          </el-table-column>
        </slot>
      </template>
    </el-table>
    <Analydialog :formshow="formshow" @func="func"></Analydialog>
  <!-- 统一提示框 -->
  <el-dialog
      :width="dialogWidth"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      append-to-body>
      {{dialogContent}}
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelDialog">{{$t('label.emailsync.button.cancel')}}</el-button>
      <el-button type="primary" @click="okDialog">{{$t('label.tabpage.ok')}}</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import Analydialog from "@/views/marketForm/components/Analydialog.vue";
export default {
  name: "tablepanel",
  components: {
    Analydialog,
  },
  props: {
    // 父组件类型，默认是Published
    powerType:{
      type:String,
      default: 'Published'
    },
    // 排序
    sortDir:{
      type: String,
      default: 'desc',
    },
    //是否开启边框
    checked: {
      type: Boolean,
      default: false,
    },
    dataList: {
      type: Array,
      default: ()=>[],
    },
    tableAttr: {
      type: Array,
      default: ()=>[],
    },
    selectvalue: {
      //actions下拉
      type: Array,
      default() {
        return [];
      },
    },
    buttonlist: {
      // 表格行内button
      type: Array,
      default() {
        return [];
      },
    },
    type:{
      type: [String,Number],
      default: "0",
    }
  },
  data() {
    return {
      postId:'',
      dialogWidth:'40%', // dialog宽度
      dialogTitle:'', // dialog标题
      dialogVisible:false, // dialog标识
      dialogContent:'', // dialog提示内容
      emitName:'', // 弹窗确定后触发的方法名
      selectNumber: 0,
      formshow: false,
      token: this.$CCDK.CCToken.getToken(),
    };
  },
  //方法集合
  methods: {
    // 初始化弹窗数据
    initDialog(obj){
      /**
       * obj{
       * width:'',
       * title:'',
       * content:''
       * }
       *   */
      // obj：弹窗宽度，标题，内容
      if(obj){
        this.dialogWidth=obj.width?obj.width:'40%'
        this.dialogTitle=obj.title?obj.title:''
        this.dialogContent=obj.content?obj.content:''
      }else{
        this.dialogWidth='40%'
        this.dialogTitle=''
        this.dialogContent=''
        this.emitName=''
      }

    },
    dorpName(socialtype,name) {
      if(name === this.$i18n.t('c630')){
        if (socialtype === "linkedIn") {
          return this.$i18n.t('c630');
        } else if (socialtype === "facebook") {
          return this.$i18n.t('c632');
        } else if(socialtype === "twitter"){
          return this.$i18n.t('c631');
        }
      }else{
        return name
      }
    },
    postsColor(str) {
      if (str === "sending") {
        return "background-color: rgb(0, 189, 165);";
      } else if (str === "timing") {
        return "background-color: rgb(245, 194, 107);";
      } else if (str === "fail") {
        return "background-color: rgb(242, 84, 91);";
      } else {
        return "background-color: rgb(203, 214, 226);";
      }
    },
    poststypeToStr(str) {
      switch (str) {
        case "sending":
          return this.$i18n.t('c627');
          // break;
        case "timing":
          return this.$i18n.t('c628');
          // break;
        case "draft":
          return this.$i18n.t('label.knowledgebase.draft')//"Draft";
          // break;
        case "fail":
          return this.$i18n.t('c629');
          // break;
        default:
          break;
      }
    },
    strDateToDate(str) {
      let date = new Date(str.replace("/-/g", "/"));
      let dateStr = date.toString();
      let arr = dateStr.split(" ");
      return `${arr[1]}${arr[2]},${arr[3]}`;
    },
    strDateToWeek(str) {
      let date = new Date(str.replace("/-/g", "/"));
      let dateStr = date.toString();
      let arr = dateStr.split(" ");
      return `${arr[0]}`;
    },
    strDateToTime(str) {
      let d = new Date(str);
      let H = this.fillZero(d.getHours());
      let M = d.getMinutes();
      if (H - 12 <= 0) {
        H = H + ":" + this.fillZero(M) + " " + "AM";
      } else if (H - 12 > 0 && H - 24 < 0) {
        H = H - 12;
        H = this.fillZero(H) + ":" + this.fillZero(M)  + " " + "PM";
      } else if (H - 24 == 0) {
        H = "00" + ":" + this.fillZero(M) + " " + "PM";
      } 
      return H;
    },
    
    fillZero(v) {
      if (v - 10 < 0) {
        v = "0" + v;
      }
      return v;
    },

    //获取图片
    imgSrc(id) {
      let file =id.split(',')
      return `${this.$cookies.get(
        "domainName"
      )}/chatterfile.action?m=previewSocial&fileid=${file[0]}&binding=${
        this.token
      }`;
    },
    // 去除富文本表图片
    
    screen(editer) {
      return editer;
    },
    actionEmit(item) {
      if (item.emit === "viewLink") {
        window.open(item.row.row.postshomepage)
      } else {
        // 需要判断是否是删除，如果是删除，需要先提示，防止误操作
        if(item.emit==='delete'){

      let  content=   this.$i18n.t("message.delete")
let title=this.$i18n.t("label.chatter.delete")
          this.initDialog({title,content})
          this.emitName='delete'
          this.postId = item.row.row.id
          this.dialogVisible=true
        }else{
          this.$emit(item.emit, item.row.row);
        }
        
      }
    },
    // 弹窗确定事件
    okDialog(){
      this.$emit(this.emitName,{id :this.postId})
      this.cancelDialog()
      
    },
    // 弹窗取消事件
    cancelDialog(){
      this.dialogVisible=false
      this.initDialog()
    },
    // 批量预约发布转为草稿（需要有提示，防止误操作）
    Makedraft(){
      // TODO:后期改变为多语言后，需要替换选中数据条数
      this.initDialog({title:'Make drafts',content:`Are you sure you want to save ${this.selectNumber} posts as drafts? These posts won't publish unless you schedule them again.`})
      this.emitName='makeDraftAll'
      this.dialogVisible=true
      // this.$emit("makeDraftAll");
    },
    // 批量删除（需要有提示，防止误操作）
    postDelete() {
      // '批量删除'
      // '确定要批量删除？'
      this.initDialog({title:this.$i18n.t("label.batchDelete"),content:this.$i18n.t("c870")})
      this.emitName='deleteSelect'
      this.dialogVisible=true
      // this.$emit("deleteSelect");
    },
    // 批量批准草稿
    DraftAll(){
      this.$emit("ApproveDraftAll",this.selection);
    },
    postAssgin() {
        this.$emit("postAssgin", this.selection);   
    },
    postClone() {
      this.$emit("postClone", this.selection);
    },
    selectAll(selection) {
      this.selection = selection;
      this.selectNumber = selection.length;
      let ids = "";
      for (var i = 0; i < selection.length; i++) {
        ids += selection[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.$emit("checkMethod", ids);
    },
    select(selection) {
      this.selection = selection;
      this.selectNumber = selection.length;
      let ids = "";
      for (var i = 0; i < selection.length; i++) {
        ids += selection[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.$emit("checkMethod", ids);
    },
    
    rowoptionshow(even, item) {
      even.optionshow = false;
      if (item == "Details") {
        this.$router.push({
          name: "detail",
          params: {
            id: "3",
          },
        });
      }
    },
    visibleChange(row) {
      if(!row){
         Array.from(document.getElementsByClassName("btList-box")).forEach(element => {
          element.style.display = 'none'
        });
      }
    },
    //表格划入
    cellMouseEnter(row, column, cell) {
      Array.from(document.getElementsByClassName("btList-box")).forEach(element => {
          element.style.display = 'none'
      });
      if (cell.getElementsByClassName("btList-box").length !== 0) {
        cell.getElementsByClassName("btList-box")[0].style.display = "flex";
      }
      this.$refs.messageDrop.forEach((item) => {
        item.hide();
      });
    },
    //table 滑出
    tableMouseLeave(){
      if(document.getElementById("dropdownTitle").getElementsByTagName('ul')[0].style.display === 'none'){
        Array.from(document.getElementsByClassName("btList-box")).forEach(element => {
          element.style.display = 'none'
        });
      }
    },
    //表格滑出A
    cellMouseLeave() {},
    
    cloneformevent(key) {
      this.Cloneform = true;
      this.Cloneformvalue = key.name + "(" + key.Last + ")";
    },
    
    action(key) {
      key.optionshow = true;
      this.deletetitle = key.name + "(" + key.Last + ")";
    },
    //强制刷新
    
    changeTableAttr() {
      this.$nextTick(() => {
        this.$refs.tablePanel.doLayout();
        this.$forceUpdate();
        // 清空选中对象
      });
      this.selectAll([]);
    },

    func(show) {
      this.formshow = show;
    },
    sortClick(item){
      if(item.schemefieldName === 'timingdate' || item.schemefieldName ==='senddate' ||item.schemefieldName ==='createdate' ){
          this.$emit('sortMethod')
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .accountImg {
    display: flex;
    align-items: center;
    position: relative;
    .mainImg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .inimg {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 20px;
      bottom:0px;
    }
  }
.sortBox{
  display: inline-block;
}
::v-deep .el-table {
  overflow: auto;
}
::v-deep .el-table__fixed-right::before,
::v-deep .el-table__fixed::before,
::v-deep .el-table::before {
  height: 0px;
}

.functional-box {
  display: flex;
  align-items: center;
  .statistical {
    font-family: Helvetica;
    font-size: 14px;
    color: #3e3e3c;
    letter-spacing: 0;
  }
  .delete-img {
    margin-left: 15px;
    margin-top: 2px;
    width: 15px;
    height: 15px;
  }
  .clone-img {
    margin-left: 15px;
    margin-top: 2px;
  }
  .editpen-box {
    margin-top: 2px;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }
  .assign-box {
    margin-left: 10px;
    font-family: Helvetica;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
  }
  .clone-box {
    margin-left: 10px;
    font-family: Helvetica;
    font-size: 14px;
    color: #006dcc;
    letter-spacing: 0;
  }
}
.compositeDate {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #3e3e3c;
  letter-spacing: 0;
}
.composite {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #888888;
  letter-spacing: 0;
}
.tablenamesecond {
  button {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    margin-right: 10px;
    height: 30px;
    cursor: pointer;
  }
  .actionselect {
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    height: 30px;
    .borderli {
      width: 100%;
      height: 1px;
      background: #dedcda;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .actionoption {
      position: absolute;
      // right: -90px;
      // top: 50px;
      right: -30px;
      bottom: 5px;
      background: #fff;
      z-index: 9999;
      width: 190px;
      background: #ffffff;
      border: 1px solid #dddbda;
      box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
      li {
        line-height: 32px;
        text-align: left;
        padding-left: 8px;
      }
      li:hover {
        background: #ececec;
        color: #006dcc;
      }
    }
    .actiontop {
      position: absolute;
      // right: -50px;
      // bottom: 50px;
      right: -30px;
      bottom: 5px;
      background: #fff;
      z-index: 9999;
      width: 190px;
      background: #ffffff;
      border: 1px solid #dddbda;
      box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
      li {
        line-height: 32px;
        text-align: left;
        padding-left: 8px;
      }
      li:hover {
        background: #ececec;
        color: #006dcc;
      }
    }
  }
}
.btList-box{
  height: 100%;
  display: flex;
  align-items: center;
    position: absolute;
  right: 10px;
  // background-color: $--color-primary-light-06;
}
.postImg {
  height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  .logoMessage {
    width: 100%;
    .logoBox {
      // width: 40px;
      display: flex;
      align-items: center;
    }
    .message {
      width: 85%;
      max-height: 47px;
      overflow: hidden;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    .poststypeBox {
      display: flex;
      justify-content: space-around;
      width: 100px;
      align-items: center;
      .roundBox {
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }
      .postsStr {
        width: 70px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #888888;
      }
    }
  }

  .imgBox {
    margin-right: 5px;
    .pictureTips {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #3e3e3c;
      letter-spacing: 0;
    }
  }
  display: flex;
  align-items: center;
}
.content-main {
  position: relative;
  clear: both;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 9999;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 9999;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 14px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

::v-deep .el-table__body-wrapper {
  overflow: auto;
  overflow-x: hidden;
  max-height: 750px;
}
.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;
  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}

::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td .cell {
  padding-right: 20px;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin-top: -4px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  // padding-right: 20px;
}

// #table-panel .content-main .el-table {
//   tr th {
//     background: #006dcc;
//   }
// }

::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
</style>
<style scoped>
.textpal {
  cursor: pointer;
}
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.percentageNB {
  float: left;
  width: 15%;
}
.percentage {
  background-color: #fea58e;
  background: #fea58e;
  border-radius: 2px;
  border-radius: 2px;
  height: 20px;
  display: inline-block;
}
</style>
