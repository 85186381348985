<template>
    <div >
    <div
     ref='barechart'
    :style="echartStyle"
     class="barechart"
    >
    </div>
  </div>
</template>
<script >
let echarts = require('echarts')
// let echarts = require('echarts/lib/echarts')
// require('echarts/lib/chart/bar')
// // 引入提示框和title组件
// require('echarts/lib/component/tooltip')
// require('echarts/lib/component/title')
export default {
    name: 'bar',
    props: { 
        echartStyle: {  // 样式 - 1
            type: Object,
            default() {}     
        },
        opinion: {    //区域名称 - 1
            type: Array,
            default() {return []}  
        },
        opinionData: {   // 区域数据 - 1
            type: Array,
            default() {return []}
        },
        x: {  // x 轴
            type: Array,
            default() {return []}   
        },
    },
    data () {
        return {
        }
    },
    mounted(){
        this.$nextTick(()=>{
        this.drawLine();
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.windowResizeFn)
    },
    methods: {
        drawLine(){
            let bareCharts = echarts.init(this.$refs.barechart)
            bareCharts.setOption({
                    tooltip : {
                        trigger: 'axis'
                    },
                    legend: {
                        left:'5%',
                        icon:'circle',
                        data:this.opinion  ,
                    },
                    toolbox: {
                    },
                    calculable : true,
                    xAxis : [
                        {
                        type : 'category',
                        data : this.x,
                        axisTick: {
                                alignWithLabel: true
                            },
                            axisLabel: {
                                interval:0 
                            }
                        }
                    ],
                    yAxis : [
                        {
                        type : 'value'
                        }
                    ],
                    series : this.opinionData,
                    }),
                window.addEventListener('resize', this.windowResizeFn)
            }
        },
        windowResizeFn(bareCharts) {
            bareCharts.resize();
        },
    }

</script>
<style lang='scss' scoped>

</style>
