<template>
  <div class="monit">
    <div class="monitor" v-show="monitorshow">
      <div class="conleft">
        <div class="streams">{{$t('c646')}}</div>
        <ul>
          <li
            v-for="item in li_list"
            :key="item.name"
            @click="inboxclick(item)"
            :class="activeName === item.name ? 'curli' : ''"
          >
           <div class="inboxMessage"><span>{{ item.name }}</span> <span>{{ item.displayNum }}</span></div> 
            <!-- 数量 -->
            <!-- <span>{{ item.num }}</span> -->
          </li>
        </ul>
      </div>
      <div class="conright">
        <Inbox-streams
          ref="inbox"
          :activeKey="activeKey"
          @detailSoical="detailSoical"
          @getPostsInfoLikesCommentCount="getPostsInfoLikesCommentCount"
          @Social="Social"
        >
        </Inbox-streams>
      </div>
    </div>
    <Monitor-creat v-if="creatshow" @func="func"> </Monitor-creat>
    <Edit v-if="editshow" :twitterid="twitterName" @func="func"> </Edit>
  </div>
</template>

<script>
import MonitorCreat from "@/views/social/monitor/components/creatname.vue";
import InboxStreams from "@/views/social/monitor/components/inboxstreams.vue";
import Edit from "@/views/social/monitor/components/edit.vue";
import * as monitorApi from "./api";
export default {
  components: {
    MonitorCreat,
    InboxStreams,
    Edit,
  },
  data() {
    return {
      monitortype: "Inbox Streams", //流的类型
      activeKey: "all",
      activeName: "All Activity", //收箱流默认显示
      monitorshow: true, //monitor主页显示
      editshow: false, //edit页面显示隐藏
      creatshow: false, //creat name 页面
      twitterName: "",
      li_list: [
        {
          name: this.$i18n.t('c647'),
          num: 1,
          key: "all",
          displayNum: 0,
        },
        {
          name: this.$i18n.t('c648'),
          num: 5,
          key: "comment",
          displayNum: 0,
        },
        {
          name: this.$i18n.t('c106'),
          num: 2,
          key: "likes",
          displayNum: 0,
        },
      ],
    };
  },
  methods: {
    detailSoical(id) {
      this.$emit("detailSoical", id);
    },
    // creatclick() {
    //   this.monitorshow = false;
    //   this.creatshow = true;
    // },
    //Twitter Streams 点击
    // twitterclick(item) {
    //   this.twitterName = item;
    //   this.monitortype = "Twitter Streams";
    //   this.activeName = "";
    // },
    //Inbox Streams 点击
    inboxclick(item) {
      this.twitterName = "";
      this.activeName = item.name;
      this.activeKey = item.key;
      // 清除长度并请求
      this.$nextTick(()=>{
        this.$refs.inbox.clearLength(false);
      })
      this.monitortype = "Inbox Streams";
    },
    //关闭Create Stream
    func(show) {
      this.creatshow = show;
      this.monitorshow = true;
      this.editshow = show;
    },
    //编辑页面显示
    // edit(none) {
    //   this.creatshow = none;
    //   this.monitorshow = none;
    //   this.editshow = true;
    // },
    getPostsInfoLikesCommentCount(
      socialtype = "",
      socialmediaid = "",
      isarchive = "New only"
    ) {
      let params = {
        orgid: this.$store.state.userInfoObj.orgId,
        socialtype,
        socialmediaids:socialmediaid,
        isarchive:
          isarchive == "New only"
            ? "false"
            : isarchive == "Archived"
            ? "true"
            : "",
      };
      monitorApi.getPostsInfoLikesCommentCount(params).then((res) => {
        this.li_list[0].displayNum = res.data.total;
        this.li_list[1].displayNum = res.data.commentstotal;
        this.li_list[2].displayNum = res.data.likestotal;
      });
    },
    Social() {
      this.$emit("newSocial");
    },
  },
  mounted() {
    this.getPostsInfoLikesCommentCount();
  },
};
</script>

<style lang="scss" scoped>
.monit {
  input {
    outline: none;
    border: none;
    border: 1px solid #dddbda;
  }
}
.monitor {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  .conleft {
    width: 15.5%;
    .inboxMessage{
      display:flex;
      width:100%;
      justify-content: space-between;
    }
    ul {
      margin-top: 12px;
      li {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        width: 100%;
        height: 35px;
        cursor: pointer;
        padding-left: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1px;
        span {
          padding-right: 19px;
        }
      }
      li:hover {
        background-color: #dedede;
      }
      .curli {
        background-color: #dedede;
      }
    }
    .streams {
      font-family: SourceHanSansCN-Bold;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      margin-top: 22.5px;
    }
    .crtstream {
      font-family: SourceHanSansCN-Bold;
      font-weight: bold;
      margin-top: 20px;
      font-size: 16px;
      color: #006dcc;
      letter-spacing: 0;
      cursor: pointer;
    }
    .retweet,
    .Mentions,
    .demo {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      margin-top: 15px;
      cursor: pointer;
    }
  }
  .conright {
    width: 78.4%;
  }
}
</style>>
